import { BASE_URL } from '../../constants/defaultValues'
import Dictionary from '../dictionary'
import axios from 'axios'

const { Resources } = Dictionary

const Resources_Management = {
  add: async (data: {}) => {
    return axios.post(BASE_URL + Resources.add(), data)
  },
  view: async (paramsObj: {}) => {
    return axios.get(BASE_URL + Resources.view(), { params: paramsObj })
  },
  getById: async (id: string) => {
    return axios.get(BASE_URL + Resources.getById(id))
  },
  getDocById: async (paramsObj: {}) => {
    return axios.get(BASE_URL + Resources.getDocById(),{ params: paramsObj })
  },
  update: async (id: string, data: {}) => {
    return axios.put(BASE_URL + Resources.update(id), data)
  },
}
export default Resources_Management
