import { call, put, takeLatest } from 'redux-saga/effects';
import { ADD_ADMIN_ACCOUNT, VIEW_MODULES, USER_LISTING, CLINICIAN_LISTING, GET_USER_DETAIL, UPDATE_USER_ACCOUNT } from './actionType';
import { AxiosResponse } from 'axios';
import service from '../../api/services';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { userResponse, modulesResponse, getUserDetail } from "./action"

function* addAdminAccount({ questionData, navigate, param }: any): Generator<any, AxiosResponse<any>, any> {
    const urlMap: any = {
        "admin": `/user-management/admin`,
        "clinician": `/user-management/clinician`,
        "parent": `/user-management/parent`,
        "school": `/user-management/school`
    };
    const url: any = urlMap[param] || '';
    try {
        const response: AxiosResponse<any> = yield service.User_Management.add(questionData);
        if (response.status === 201 || response.status === 200) {
            toast.success(response?.data?.message);
            navigate(url)
        } else {
            toast.error(response?.data?.message);
        }
        return response;
    } catch (error: any) {
        toast.error(error?.response?.data?.message);
        return error;
    }
}
function* updateAdminAccount({ id, questionData, navigate, param }: any): Generator<any, AxiosResponse<any>, any> {
    const urlMap: any = {
        "admin": `/user-management/admin`,
        "clinician": `/user-management/clinician`,
        "parent": `/user-management/parent`,
        "school": `/user-management/school`
    };
    const url: any = urlMap[param] || '';
    try {
        const response: AxiosResponse<any> = yield service.User_Management.update(id, questionData);
        if (response.status === 200 || response.status === 201) {
            toast.success(response?.data?.message);
            navigate(url)

        } else {
            toast.error(response?.data?.message);
        }
        return response;
    } catch (error: any) {
        toast.error(error?.response?.data?.message);
        return error;
    }
}
function* getModule({ headers }: any): Generator<any, AxiosResponse<any>, any> {
    try {
        const response: any = yield service.User_Management.viewModule(headers);

        yield put(modulesResponse(response.data))
        if (response.status === 201) {
            // toast.success(response?.data?.message);
            // navigate("/referral");

        } else {
            // toast.error(response?.data?.message);
        }
        return response;
    } catch (error: any) {
        toast.error(error?.response?.data?.message);
        return error;
    }
}

function* getUserList({ headers }: any): Generator<any, AxiosResponse<any>, any> {
    try {
        const response: any = yield service.User_Management.viewUser(headers);
     
        yield put(userResponse(response.data))
        if (response.status === 201) {
            // toast.success(response?.data?.message);
            // navigate("/referral");

        } else {
            // toast.error(response?.data?.message);
        }
        return response;
    } catch (error: any) {
        toast.error(error?.response?.data?.message);
        return error;
    }
}

function* getDetailById({ id }: any): Generator<any, AxiosResponse<any>, any> {
    try {
        const response: any = yield service.User_Management.getUserById(id);

        yield put(getUserDetail(response.data))
        if (response.status === 201) {
            // toast.success(response?.data?.message);
            // navigate("/referral");

        } else {
            // toast.error(response?.data?.message);
        }
        return response;
    } catch (error: any) {
        toast.error(error?.response?.data?.message);
        return error;
    }
}

function* referralSaga() {
    yield takeLatest(ADD_ADMIN_ACCOUNT, addAdminAccount);
    yield takeLatest(VIEW_MODULES, getModule);
    yield takeLatest(USER_LISTING, getUserList);
    yield takeLatest(GET_USER_DETAIL, getDetailById);
    yield takeLatest(UPDATE_USER_ACCOUNT, updateAdminAccount);
}

export default referralSaga;
