import React, { Suspense, memo, useEffect, useLayoutEffect, useMemo, useState } from 'react'
import generatePDF, { Margin, usePDF } from 'react-to-pdf';
import Button from '../Button';
import './pdf.scss';
import DownloadIcon from '@mui/icons-material/Download';
import nhsLogo from '../../../assets/Images/nhsLogo.png'
import p1 from '../../../assets/Images/p1.png'
import loaderGif from '../../../assets/Images/loader.svg';
import p2 from '../../../assets/Images/p2.png'
import p3 from '../../../assets/Images/p3.png'
import logoLd from '../../../assets/Images/logso.png'
import constant from '../../../constants/constant';
import { Box } from '@mui/material';
import ReferralView from '../../organism/ReferralView';
import { toast } from 'react-toastify';
import {useOrgnizationLogo} from '../../atoms/OrgnizationLogo'

// const ReferralView = React.lazy(() => import('../../organism/ReferralView'));
interface PdfProps {
    data: any,
    title: string,
    fname:any
}

const Pdf: React.FC<PdfProps> = ({
    data,
    title,
    fname
}) => {
    const { imageSrc } = useOrgnizationLogo();
    const currentDate = new Date().toLocaleDateString();
    const currentTime = new Date().toLocaleTimeString();
    const [loading, setLoading] = useState(false);
    const { toPDF, targetRef } = usePDF({
        method: 'save', filename: `${title}-${currentDate} ${currentTime}`,
        page: {
            // margin is in MM, default is Margin.NONE = 0
            margin: 4,
            orientation: 'portrait',
         },
        overrides: {
            pdf: {
               compress: true
            },
            canvas: {
               useCORS: true
            }
         },
    });

    // useLayoutEffect(() => {
    //   if(fname === "DOWNLOAD" || fname === "AUTOMATIC"){
    //     targetRef.current.style.display = "block"
    //     if(fname === "DOWNLOAD"){
    //         targetRef.current.style.display = "none"
    //     }
    //     toPDF()
    //     toast.success("Pdf Downloaded Successfully")
    //   }else{
    //       targetRef.current.style.display = "none";
    //   }
    // },[fname,toPDF])



    const handleDownloadClick = async () => {
        setLoading(true);
        const targetRefValue = targetRef.current;
        if (targetRefValue) {
            targetRefValue.style.display = "block";
            await toPDF();
            targetRefValue?.style.setProperty("display", "none");
        } else {
            console.error("targetRef.current is null");
        }
        setLoading(false);
    };
    


    const getColorByKey = useMemo(() => {
        return (key:any) => {
  
        switch (key) {
            case 'reason':
                return 'col1';
            case 'referrer_detail':
                return 'col2';
            case 'consent':
                return 'col3';
            // Add more cases as needed
            default:
                return 'pdfSection'; // Default color
            }
        };
    }, [data]);

    const getColorParentByKey = useMemo(() => {
        return (key:any) => {
        switch (key) {
            case 'personalDetails':
            case 'familyHistory':
                return 'col_parent1';
            case 'playImagination':
            case 'sensory':
            case 'birthDetails':
            case 'earlyDevelopement':
            case 'informationSharing':
            case 'previousAssessments':
            case 'attentionActivity':
            case 'mental':
                return 'col_parent2';
                case 'personalDetail':  
                // case 'attention_activity_levels':
                return 'col_School1';      
            // Add more cases as needed
            default:
                return 'pdfParentSection'; // Default color
            }
        };
    }, [data]);


    const getValue = useMemo(() => {
    return (val:any) => {
        for (const element of val) {
            if (Array.isArray(element.answer)) {
        
                for (const element1 of element.answer) {
                  
                    if (element1.name === 'More Details' || element1.name === 'Details') {
                        return 'DetailsSection';
                    } else if (element1.name === 'X') {
                        return 'hiddenx'
                    }
                }
            }
        }
    };
}, [data]);

function addSpaceBeforeCapitalLetters(str:any) {
    return str.replace(/([A-Z])/g, ' $1').trim();
  }


    return (
        <>
            {loading ? (
       <Box className='loadergif' component={'div'}><img src={loaderGif} /> </Box>
      ) : (
        <>
         {(fname !== 'REMOVEBTN' && fname !== 'DOWNLOAD' && fname !== 'AUTOMATIC') && <Button variant={"outlined"} onClick={handleDownloadClick} sx={{ float: 'right',  right: 0 }} startIcon={<DownloadIcon />}>  Download PDF</Button> }
            <div ref={targetRef} 
             style={{display:!loading ? 'none': 'block'}}
            >
                {title !== constant.PATHWAY_STATUS.CASELOAD_CREATED && <div style={{ margin: 'auto', display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%',height:'100%' }}><img className='img-responsive' src={logoLd} alt="" width={'400'} /></div>}
                {title === constant.PATHWAY_STATUS.CASELOAD_CREATED && <table style={{ width: '100%', marginBottom: '1rem' }}>
                    <tr style={{ width: '100%' }}>
                        <td>
                            <img className='img-responsive' src={nhsLogo} alt="" />
                        </td>
                        <td style={{ textAlign: 'right' }}>
                            <h2 style={{ margin: 0 }}>Essex BBT Children's Community Service <br /> Online Referral Form</h2>
                        </td>
                    </tr>
                </table>}

                {title !== constant.PATHWAY_STATUS.CASELOAD_CREATED && <table style={{ width: '100%', padding: '0px 20px' }}>
                <tr style={{ width: "100%" }}>
              {/* <td>
                <img
                  src={p1}
                  alt="p1"
                  width={"200px"}
                />
              </td> */}
              <td style={{ textAlign: "center" }}>
                <img
                  src={imageSrc}
                  width={200}
                  alt="Organization Logo"
                />
              </td>
              {/* <td style={{ textAlign: "right" }}>
                <img src={p3} alt="p3" />
              </td> */}
            </tr>
                    {title === constant.PATHWAY_STATUS.PARENT_REPORT_RECEIVED && <tr >
                        <td align='center' colSpan={3} style={{ padding: '1rem', fontWeight: '600' }} >
                            Screening Questionnaire - Neurodevelopmental Assessment, Autism & ADHD
                            Parents/Carers or Young Person
                        </td>
                    </tr>}
                    {title === constant.PATHWAY_STATUS.SCHOOL_REPORT_RECEIVED && <tr >
                        <td align='center' colSpan={3} style={{ padding: '1rem', fontWeight: '600' }} >
                            Screening Questionnaire - Neurodevelopmental Assessment, Autism & ADHD
                            Education Setting
                        </td>
                    </tr>}
                  {title === constant.PATHWAY_STATUS.PARENT_REPORT_RECEIVED &&  <tr>
                        <td align='left' colSpan={3} style={{ padding: '20px', border: '1px solid #000', fontWeight: '600' }}>
                            If you would like help filling out this form, please start by asking {fname !== 'AUTOMATIC' && fname?.[0]?.answer[0]?.answer}  school or social worker. If this is not appropriate, please contact our team to support you.
                        </td>
                    </tr>}
                </table>}
  
                    {Object.entries(data || {}).map(([key, value], index) => (
                      
                           <div key={key} className='pdf-page'
                           >
                            <ReferralView
                                tab={index}
                                question={value}
                                type={addSpaceBeforeCapitalLetters(key)}
                                isParentTab={true}
                                isPadding={{ padding: 0, borderRadius: 0 }}
                                // setnesting={getValue(value)}
                                setStylePdf={
                                    (title === constant.PATHWAY_STATUS.CASELOAD_CREATED && getColorByKey(key))
                                        ? getColorByKey(key)
                                        : (title === constant.PATHWAY_STATUS.PARENT_REPORT_RECEIVED || title === constant.PATHWAY_STATUS.SCHOOL_REPORT_RECEIVED && getColorParentByKey(key))
                                            ? getColorParentByKey(key)
                                            : null
                                }

                            />
                        </div>
                    ))}
      
                {title === constant.PATHWAY_STATUS.CASELOAD_CREATED && <table style={{ width: '100%', padding: '2rem 1rem ' }}>
                    <tr style={{ width: '100%' }}>
                        <td>
                            <small>What happens to your information?</small> <br />
                            <small>If you would liketo find out what happens to personal information held aboutyou, pleaseread our privacy policy for moreinformation:</small>
                            <small> <a href='https://www.nelft.nhs.uk/download.cfm?ver=1014' target='_blank'> https://www.nelft.nhs.uk/download.cfm?ver=1014 </a></small>
                        </td>

                    </tr>
                </table>}

            </div>
        </>
      )}
            
        </>
    );
};

export default memo(Pdf);

