import React, { useEffect, useState } from 'react'
import { Container, Grid, Box, Typography, Input, InputLabel, FormControl, OutlinedInput, InputAdornment, IconButton, Card, FormHelperText, useTheme } from '@mui/material';
import Button from '../../../components/atoms/Button'
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { SetPasswordFormValues } from '../interface';
import { useFormik } from 'formik';
import * as Yup from "yup";
import css from './index.module.scss'
import Logo from "../../../assets/Images/logo.svg"
import { useNavigate } from 'react-router-dom';
import linkexpired from '../../../assets/Images/linkexpired.webp';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from "reselect";
import { Register, VerifyLink } from '../../../redux/action';
import atoms from '../../../components/atoms';
import { useResponsive } from '../../../components/atoms/Responsive';

const authReducer = (state: any) => state?.Login_Response
const { TextField } = atoms
const Index = () => {
    const theme = useTheme();
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const param = new URLSearchParams(window.location.search)
    const otp = param.get('otp')
    const email = param.get('email')
    const [dataLoaded, setDataLoaded] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [confirmPassword, setConfirmPassword] = useState('');
    const {
        isExSmallScreen,
        isSmallScreen,
        isMediumScreen,
        isLargeScreen,
        isExLargeScreen,
    }  = useResponsive()
    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const authData = createSelector(
        authReducer,
        (state) => ({
            linkResponse: state.linkResponse,
        })
    );

    const { linkResponse } = useSelector(authData);
    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };
    const validation = useFormik<SetPasswordFormValues>({
        enableReinitialize: true,
        initialValues: {
            password: '',
            otp: otp || '',
            email: email || ''
        },
        validationSchema: Yup.object({
            password: Yup.string()
                .required("Please Enter Password")
                .min(8, "Password must be at least 8 characters")
                .matches(/[A-Z]/, "Password must contain at least one uppercase letter")
                .matches(/[a-z]/, "Password must contain at least one lowercase letter")
                .matches(/[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/, "Password must contain at least one special character")
                .matches(/[0-9]/, "Password must contain at least one number"),
        }),
        onSubmit: (values: SetPasswordFormValues) => {
            dispatch(Register(values, navigate))
        }
    });

    useEffect(() => {
        dispatch(VerifyLink({ email: email, otp: otp }))
    }, [])


    useEffect(() => {
        // Simulating data loading with a timeout
        const timeout = setTimeout(() => {
            setDataLoaded(true);
        }, 1000); // Adjust the timeout as needed

        return () => clearTimeout(timeout);
    }, []);


 const {validate_otp} = linkResponse


    return (
        < Grid container className={!isSmallScreen ? css.forBGMobile : css.forBgRegister} >
            {validate_otp && <>
                <Grid item lg={3}></Grid>
                <Grid item lg={6} className={!isSmallScreen ? css.responsiveCard : css.formCard } display={'flex'} justifyContent={"center"} alignItems={"center"} >
                    <form onSubmit={validation.handleSubmit} style={{ width: "100%" }}>

                        <Card sx={{ padding:!isSmallScreen ? '20px' : '40px', boxShadow: !isSmallScreen ? 'none' : null }}>
                            <Grid textAlign={"center"} marginBottom={'10px'}>
                                <img src={Logo} width={ !isSmallScreen ? "150" : "300"} height={"auto"} />
                            </Grid>
                            <Typography variant="h4" sx={{ fontSize: !isSmallScreen ? '20px' : '30px', fontWeight: '600', marginY: '2rem', textAlign: 'center', }}>Set New Password</Typography>
                            <Box className='extraDisabled'>
                                <InputLabel className={css.text_start} htmlFor="outlined-adornment-password">Email</InputLabel>
                                <TextField
                                    value={email}
                                    disabled={true}
                                    placeholder={"Enter your email address"}
                                    fullWidth={true}
                                />
                            </Box>
                            <Box className={css.mt_25} >
                                <InputLabel className={css.text_start} htmlFor="outlined-adornment-password">Password</InputLabel>
                                <FormControl sx={{ width: '100%', }} variant="outlined">
                                    <OutlinedInput
                                        id="outlined-adornment-password"
                                        type={showPassword ? 'text' : 'password'}
                                        placeholder="Enter password"
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowPassword}
                                                    onMouseDown={handleMouseDownPassword}
                                                    edge="end"
                                                    className={css.eye_icon_bg_color}
                                                >
                                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                        {...validation.getFieldProps('password')}
                                        error={validation.touched.password && Boolean(validation.errors.password)}
                                    />
                                </FormControl>
                                {validation.touched.password && validation.errors.password && (
                                    <FormHelperText error>{validation.errors.password}</FormHelperText>
                                )}
                            </Box>
                            <Box className={ !isSmallScreen ? 'error_MobileDropDown' : 'error-dropdown'} style={{display:validation.values.password === confirmPassword ? 'none' : 'block'}}>
                                <Typography variant="body2" component="small" fontFamily={'poppins'}>
                                    Password must contain the following:
                                </Typography>
                                <ul >
                                    <li style={{ textDecoration: validation.values.password && validation.values.password.length >= 8 ? 'line-through' : '' }}>
                                        <Typography variant="body2" component="small">
                                            Minimum 8 characters
                                        </Typography>
                                    </li>
                                    <li style={{ textDecoration: /[A-Z]/.test(validation.values.password) ? 'line-through' : '' }}>
                                        <Typography variant="body2" component="small">
                                            At least one uppercase
                                        </Typography>
                                    </li>
                                    <li style={{ textDecoration: /[a-z]/.test(validation.values.password) ? 'line-through' : '' }}>
                                        <Typography variant="body2" component="small">
                                            At least one lowercase
                                        </Typography>
                                    </li>
                                    <li style={{ textDecoration: /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(validation.values.password) ? 'line-through' : '' }}>
                                        <Typography variant="body2" component="small">
                                            At least one special character
                                        </Typography>
                                    </li>
                                    <li style={{ textDecoration: /[0-9]/.test(validation.values.password) ? 'line-through' : '' }}>
                                        <Typography variant="body2" component="small">
                                            At least one number
                                        </Typography>
                                    </li>
                                    <li style={{ textDecoration: validation.values.password.length >= 8 && validation.values.password === confirmPassword ? 'line-through' : '' }}>
                                        <Typography variant="body2" component="small">
                                            Password and Confirm password must be the same
                                        </Typography>
                                    </li>
                                </ul>
                            </Box>

                            <Box className={css.mt_25}>
                                <InputLabel className={css.text_start} htmlFor="outlined-adornment-password">Confirm Password</InputLabel>
                                <FormControl sx={{ width: '100%', }} variant="outlined">
                                    <OutlinedInput
                                        id="outlined-adornment-password"
                                        type={showConfirmPassword ? 'text' : 'password'}
                                        placeholder="Enter Confirm Password"
                                        onChange={(e: any) => setConfirmPassword(e.target.value)}
                                        value={confirmPassword}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                                                    onMouseDown={handleMouseDownPassword}
                                                    edge="end"
                                                    className={css.eye_icon_bg_color}
                                                >
                                                    {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                        error={validation.values.password !== confirmPassword && Boolean("Password Not Match")}
                                    />
                                    {/* {validation.values.password && validation.values.password !== confirmPassword &&
                                  <FormHelperText error>Password Not Match</FormHelperText>} */}
                                </FormControl>
                            </Box>

                            <Grid display={"flex"} justifyContent={"center"} marginTop={'45px'} >
                                <Button disabled={validation.values.password !== confirmPassword} type={"submit"} variant={"contained"} sx={{ width: '80%', height: '55px' }} className={[css.mt_25].join(' ')}>
                                    Submit
                                </Button>
                            </Grid>
                        </Card>
                    </form>
                </Grid>
                <Grid item lg={3}></Grid>
            </> }
               {!validate_otp && dataLoaded && <Grid >
                    <Box className={css.loadergif} component={'div'}><img  src={linkexpired} alt='loader'/> </Box>
                </Grid>}
        </Grid >
    )
}

export default Index