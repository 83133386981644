const Auth = {
    login: () => 'auth/login',
    forgotPasssword: () => 'auth/forgot-password',
    setPassword: () => 'auth/set-password',
    logout: () => 'auth/logout',
    otp: () => 'auth/validate-otp',
    verifyLink: () => 'auth/verify-link',
    changePassword: () => 'auth/change-password',
}
export default Auth
