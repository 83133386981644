import { RESPONSE_CHATMSG_CLEAR } from '../caseload/actionType';
import { CASELOAD_CURRENT_TAB, CHANGE_TAB, REFFERAL_CLOSED, SET_CONVERSATION_ID, SET_DOCUMENT_TAB, SET_SELECTED_TAB, SET_SELECTED_USER, SET_TASK_TAB, SET_USER_LIST_ACTIVE} from './actionType'

let initialValues = {
    currentTab: '',
    currentTabCaseload:0
  
}



  const initialChatUser = {
    selectedTab: 0,
    selectedDocTab: 0,
    selectedTaskTab: 0,
    selectedUser: null as number | null,
    conversationId: null as number | null,  
    userListActive: {},
    isRefClosed : false as boolean
  };

export const LayoutReducer = (state = initialValues, action: any) => {

    switch (action.type) {
        case CHANGE_TAB:
            state = { ...state, currentTab: action?.tab };
            break;
            case CASELOAD_CURRENT_TAB:
              state = { ...state, currentTabCaseload: action.payload };
              break;
        default:
            state = { ...state };
            break;
    }
    return state;
}

export  const selectedTabReducer = (state = initialChatUser.selectedTab, action: any) => {
    switch (action.type) {
      case SET_SELECTED_TAB:
        return action.payload;
      default:
        return state;
    }
  };

  export  const selectedDocTabReducer = (state = initialChatUser.selectedDocTab, action: any) => {
    switch (action.type) {
      case SET_DOCUMENT_TAB:
        return action.payload;
      default:
        return state;
    }
  };


  export  const selectedTaskTabReducer = (state = initialChatUser.selectedTaskTab, action: any) => {
    switch (action.type) {
      case SET_TASK_TAB:
        return action.payload;
      default:
        return state;
    }
  };



 export const selectedUserReducer = (state = initialChatUser.selectedUser, action: any) => {
    switch (action.type) {
      case SET_SELECTED_USER:
        return action.payload;
      default:
        return state;
    }
  };

 export const conversationIdReducer = (state = initialChatUser.conversationId, action: any) => {
    switch (action.type) {
      case SET_CONVERSATION_ID:
        return action.payload;
      default:
        return state;
    }
  };

  export const userListActiveReducer = (state = initialChatUser.userListActive, action: any) => {
    switch (action.type) {
      case SET_USER_LIST_ACTIVE:
        return action.payload;
        case RESPONSE_CHATMSG_CLEAR: 
        return [];
      default:
        return state;
    }
  };
  

  export const refClosedReducer = (state = initialChatUser.isRefClosed, action: any) => {
    switch (action.type) {
      case REFFERAL_CLOSED:
        return action.payload;
      default:
        return state;
    }
  };
  


  
