import { call, put, takeLatest } from 'redux-saga/effects';
import {
    CHANGE_TAB,
    CURRENT_TAB
} from './actionType';
import { AxiosResponse } from 'axios';
import service from '../../api/services';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import {  } from "./action"

function* layoutSaga() {
}

export default layoutSaga;
