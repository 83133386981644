import { REFERRAL_RESPONSE, ADD_REFERRAL, GP_LIST_RESPONSE,REFERRAL_CASELOAD_RESPONSE, GET_REFERRAL_ALL_RESPONSE } from './actionType'


let initialValues = {
    referralList: [],
    referralCaseload: {},
    referralAllDetail: {},
    gpList:[]
}
export const referralReducer = (state = initialValues, action: any) => {

    switch (action.type) {
        case REFERRAL_RESPONSE:
            state = { ...state, referralList: action?.payload.data };
            break
        case REFERRAL_CASELOAD_RESPONSE:
            state = { ...state, referralCaseload: action?.payload.data };
            break
        case GET_REFERRAL_ALL_RESPONSE:
            state = { ...state, referralAllDetail: action?.payload.data };
            break
        case GP_LIST_RESPONSE:
            state = { ...state, gpList: action?.payload.data };
            break

        default:
            state = { ...state };
            break;
    }
    return state;
}
