import AddParentReport from './Report/parentReport/addForm'
import ParentReport from './Report/parentReport'
import ParentReportCaseload from './Report/parentReport/ReportCaseLoad'
import ParentReportView from './Report/parentReport/ReportView'

import AddEducationReport from './Report/educationReport/addForm'
import EducationReport from './Report/educationReport'
import EducationReportCaseload from './Report/educationReport/ReportCaseLoad'
import EducationReportView from './Report/educationReport/ReportView'

import Dashboard from './Dashboard'
import AdminAcounts from './UserManagement/adminAcounts'
import Listing from './UserManagement/listing'
import Caseload from './ManageCaseLoad'
import CreateCaseload from './ManageCaseLoad/CreateCaseload'
import Resources from './Resources'
const Pages = {
  Dashboard,
  ParentReport,
  AddParentReport,
  ParentReportCaseload,
  ParentReportView,
  EducationReport,
  AddEducationReport,
  EducationReportCaseload,
  EducationReportView,
  Resources,
  AdminAcounts,
  Listing,
  Caseload,
  CreateCaseload,
}
export default Pages
