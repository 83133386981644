import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  Container,
  Grid,
  Box,
  InputAdornment,
  IconButton,
  Avatar,
  FormHelperText,
  Typography,
  Input,
  TextField,
  Button,
} from "@mui/material";
import css from "./index.module.scss";
import Logo from "../../../assets/Images/logo.svg";
import googlePlay from "../../../assets/Images/googleplay.png";
import appStore from "../../../assets/Images/appstore.png";
import Provide from "../../../assets/Images/provide_logo.png";
import { Field, Form, Formik, useFormik } from "formik";
import * as Yup from "yup";
import { ForgotPasswordFormValues, LoginInterface } from "../interface";
import { forgotPassword, login } from "../../../redux/action";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { style } from "./style";
import constant from "../../../constants/constant";
import { useMobile } from "./Hooks/useMobile";
import Modal from "../../../components/atoms/Modal";
const Login = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [forgotPsdState, SetForgotPsdState] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    windowWidth,
    isMobile,
    handleOpenApp,
    isSubmitAfter,
    // setIsSubmitAfter,
    deviceType,
  } = useMobile();

  const validation = useFormik<LoginInterface>({
    enableReinitialize: true,
    initialValues: {
      username: "",
      password: "",
      grant_type: "password",
      scope: constant.SCOPE.PARENT,
    },
    validationSchema: Yup.object({
      username: Yup.string().required("Please Enter Email Address"),
      password: Yup.string().required("Please Enter Password"),
    }),
    onSubmit: (values: LoginInterface) => {
      const transformedValues = {
        ...values,
        username: values.username.toLowerCase(),
      };
      dispatch(login(transformedValues, navigate));
    },
  });

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const handleSubmit = async (values: ForgotPasswordFormValues) => {
    dispatch(forgotPassword(values, navigate));
    SetForgotPsdState(false);
  };


  return (
    <div className={css.loginLayout}>
      <Box
        className={css.wrapperLayout}
        sx={{
          background: "#6A238210",
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box className={css.loginSection}>
          <Grid
            container
            columns={12}
            spacing={4}
            sx={{ alignItems: "center", margin: "auto" }}
          >
            <Grid
              item
              xs={12}
              sm={6}
              className={css.outerPadding}
              sx={{ margin: "auto" }}
            >
              <Grid className={css.login_card_text}>
                <Grid item xs={12}>
                  <Box
                    component="img"
                    src={Logo}
                    alt="Logo"
                    className={css.logo}
                  />
                </Grid>
                {/* <Typography variant="h4" sx={{ fontSize: '30px', fontWeight: '600', textAlign: 'center', }}>Welcome back, Admin! Login to access the portal.</Typography> */}
                {!forgotPsdState ? (
                  <>
                    <Typography
                      variant="h4"
                      sx={{
                        fontSize: "20px",
                        fontWeight: "600",
                        marginY: "1rem",
                        textAlign: "center",
                      }}
                    >
                      Parent Login
                    </Typography>
                    <form onSubmit={validation.handleSubmit}>
                      <Grid sx={{}}>
                        <Grid item md={12} xs={12}>
                          <Box>
                            {/* <TextField sx={{ width: '100%', }} label="User ID" id="fullWidth" /> */}
                            <InputLabel
                              className={css.text_start}
                              htmlFor="outlined-adornment-password"
                            >
                              Email
                            </InputLabel>

                            <TextField
                              fullWidth
                              placeholder="Enter email"
                              sx={{ width: "100%" }}
                              // InputProps={{
                              //   style: {
                              //     borderRadius: '25px',
                              //     backgroundColor: '#f2f2f2',
                              //   },
                              // }}
                              {...validation.getFieldProps("username")}
                              error={
                                validation.touched.username &&
                                Boolean(validation.errors.username)
                              }
                              helperText={
                                validation.touched.username &&
                                validation.errors.username
                              }
                            />
                          </Box>
                        </Grid>

                        <Grid item xs={12} sm={12}>
                          <Box className={css.mt_25}>
                            <InputLabel
                              className={css.text_start}
                              htmlFor="outlined-adornment-password"
                            >
                              Password
                            </InputLabel>
                            <FormControl
                              sx={{ width: "100%" }}
                              variant="outlined"
                            >
                              <OutlinedInput
                                id="outlined-adornment-password"
                                type={showPassword ? "text" : "password"}
                                placeholder="Enter password"
                                endAdornment={
                                  <InputAdornment position="end">
                                    <IconButton
                                      aria-label="toggle password visibility"
                                      onClick={handleClickShowPassword}
                                      onMouseDown={handleMouseDownPassword}
                                      edge="end"
                                      className={css.eye_icon_bg_color}
                                    >
                                      {showPassword ? (
                                        <VisibilityOff />
                                      ) : (
                                        <Visibility />
                                      )}
                                    </IconButton>
                                  </InputAdornment>
                                }
                                {...validation.getFieldProps("password")}
                                error={
                                  validation.touched.password &&
                                  Boolean(validation.errors.password)
                                }
                              />
                              <Typography sx={style.error_text}>
                                {validation.touched.password &&
                                  validation.errors.password &&
                                  validation.errors.password}
                              </Typography>
                            </FormControl>
                          </Box>
                        </Grid>
                        <Typography
                          textAlign="end"
                          style={{ cursor: "pointer" }}
                          mt={2}
                          onClick={() => SetForgotPsdState(true)}
                        >
                          Forgot Password ?
                        </Typography>
                        <Button
                          type="submit"
                          variant="contained"
                          sx={{ width: "100%", height: "55px" }}
                          className={css.mt_25}
                        >
                          Login
                        </Button>
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          justifyContent="center"
                          display="flex"
                        >
                          <Avatar
                            src={Provide}
                            alt="Logo"
                            className={css.bottomLogo}
                          />
                        </Grid>
                      </Grid>
                    </form>
                  </>
                ) : (
                  <>
                    <Typography
                      variant="h4"
                      sx={{
                        fontSize: "30px",
                        fontWeight: "600",
                        marginY: "1rem",
                        textAlign: "center",
                      }}
                    >
                      Forgot Password
                    </Typography>
                    <Formik
                      initialValues={{ email: "" }}
                      onSubmit={handleSubmit}
                    >
                      <Form>
                        <InputLabel
                          className={css.text_start}
                          htmlFor="outlined-adornment-password"
                        >
                          Email
                        </InputLabel>
                        <Field as={TextField} name="email" fullWidth required />
                        <Grid textAlign={"center"} marginTop={"10px"}>
                          <Typography
                            textAlign="end"
                            style={{ cursor: "pointer" }}
                            mt={2}
                            onClick={() => SetForgotPsdState(false)}
                          >
                            {" "}
                            Back to Login
                          </Typography>

                          <Button
                            type="submit"
                            variant="contained"
                            sx={{ width: "100%", height: "55px" }}
                            className={css.mt_25}
                          >
                            Reset Password
                          </Button>
                          <Avatar
                            src={Provide}
                            alt="Logo"
                            className={css.bottomLogo}
                          />
                        </Grid>
                      </Form>
                    </Formik>
                  </>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Box>
        {/* 
        {isMobile && windowWidth < 600 && (
          <>
            <Typography sx={{ my: "1rem" }}>
              We have app <Button onClick={handleOpenApp}>Open App</Button>
            </Typography>
          </>
        )} */}
        <Box sx={{ background: "#6A238220", width: "100%" }}>
          <Box className={css.footerSection}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                gap: "5px",
              }}
            >
              <Typography>
                <Link
                  to={"/"}
                  className={css.condition}
                  style={{
                    color: "#222",
                    fontWeight: "500",
                    cursor: "pointer",
                  }}
                >
                  Terms & Condition
                </Link>
              </Typography>{" "}
              ||
              <Typography>
                <Link
                  target="_blank"
                  rel="noopener noreferrer"
                  to={"https://mycarebridge.co.uk/privacy-notice"}
                  className={css.condition}
                  style={{
                    color: "#222",
                    fontWeight: "500",
                    cursor: "pointer",
                  }}
                >
                  privacy-notice
                </Link>
              </Typography>
            </Box>
            <Typography sx={{ color: "#222", fontWeight: "500" }}>
              2024 Provide Digital LTD all rights reserved
            </Typography>
          </Box>
        </Box>
      </Box>

      {windowWidth < 600 && (
        <Modal
          open={isSubmitAfter}
          title={"Notice"}
          sx={{
            "&.MuiDialogContent-root": {
              overflow: "hidden",
            },
          }}
        >
          <Grid sx={{ width: "100%", textAlign: "center", py: 3 }}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                padding: "2rem",
                flexDirection: "column",
              }}
            >
              {deviceType === "iOS" ? (
                <img
                  src={appStore}
                  style={{ maxWidth: "100%", width: "100px" }}
                />
              ) : (
                <img
                  src={googlePlay}
                  style={{ maxWidth: "100%", width: "200px" }}
                />
              )}
            </div>

            <Typography
              variant="body1"
              sx={{
                height: "100vh",
                fontSize: "1rem!important",
                lineHeight: "2rem",
              }}
            >
              Welcome! We have a dedicated app for the Parent Portal. To
              download it, please click the 'App' button. If you have already
              installed the app, you will be redirected to it automatically.
            </Typography>
          </Grid>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              gap: 2,
              position: "sticky",
              bottom: 0,
              bgcolor: "background.paper",
              p: 2,
            }}
          >
            <Button
              sx={{ width: "50%" }}
              variant={"contained"}
              onClick={() => handleOpenApp()}
            >
              Open App
            </Button>
          </Box>
        </Modal>
      )}
    </div>
  );
};
export default Login;
