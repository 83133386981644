import { VIEW_MODULES, ADD_ADMIN_ACCOUNT, MODULES_RESPONSE, USER_RESPONSE,
     CLINICIAN_LISTING, CLINICIAN_RESPONSE, USER_LISTING, USER_DETAIL_RESPONSE, GET_USER_DETAIL,UPDATE_USER_ACCOUNT } from './actionType'
export const addAdminAccount = (questionData: any, navigate: any, param: string) => {
    return {
        type: ADD_ADMIN_ACCOUNT,
        questionData,
        navigate,
        param
    }
}
export const updateUserAccount = (id:string,questionData: any, navigate: any, param: string) => {
    return {
        type: UPDATE_USER_ACCOUNT,
        questionData,
        navigate,
        param,
        id
    }
}
// export const getUserList = (headers: any) => {
//     return {
//         type: USER_LISTING,
//         headers
//     }
// }

export const getUserByID = (id: string) => {

    return {
        type: GET_USER_DETAIL,
        id
    }
}
export const getClinicianList = (headers: any) => {
    return {
        type: CLINICIAN_LISTING,
        headers
    }
}
export const getModules = (headers: any) => {
    return {
        type: VIEW_MODULES,
        headers
    }
}
// export const clinicianResponse = (headers: any) => {
//     return {
//         type: CLINICIAN_RESPONSE,
//         headers
//     }
// }
export const userResponse = (Response: any) => {
    return {
        type: USER_RESPONSE,
        payload: Response,
    }
}

export const modulesResponse = (Response: any) => {
    return {
        type: MODULES_RESPONSE,
        payload: Response,
    }
}
export const getUserDetail = (Response: any) => {
    return {
        type: USER_DETAIL_RESPONSE,
        payload: Response,
    }
}
