import React, { useEffect, useRef, useState } from "react";
import Button from "../../../../components/atoms/Button";
import Grid from "@mui/material/Grid";
import { Box, IconButton, Typography } from "@mui/material";
import SideStepper from "./sideStepper";
import PersonalDetail from "./PersonalDetail";
import Behavior from "./Behavior";
import CreativityImagination from "./CreativityImagination";
import AttentionLevels from "./AttentionLevels";
import PersonDetail from "./social_interaction";
import Communication from "./Communication";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import Sensory from "./Sensory";
import AdditionalParentDetail from "./OtherInformation";
import { question } from "./question";
import {
  EditReport,
  addReport,
  getCaseloadById,
  getSchoolReport,
  setCaseloadCurentTab,
} from "../../../../redux/action";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";
import classes from "./index.module.scss";
import atoms from "../../../../components/atoms";
import moment from "moment";
import ConfirmationModal from "../../../../components/atoms/ConfirmationModal";

const { Modal } = atoms;
const CaseloadReducer = (state: any) => state?.CaseloadReducer;
const Index = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const params: any = useParams();
  const pathParts = location.pathname.split("/").filter(Boolean);
  const param = pathParts[1];
  const id = pathParts[2];
  const educationReportID = pathParts[3];
  const [activeStep, setActiveStep] = useState(0);
  const [total, setTotal] = useState(0);
  const [isVisible, setIsVisible] = useState(false);
  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const [enableStep, setEnableStep] = useState(0);
  const [isNewSchool, setIsNewSchool] = useState();
  const [completeSteps, setCompleteSteps] = useState<any>();
  const [selectedOption, setSelectedOption] = useState("");
  const [questionData, setQuestionData] = useState<any>({});
  const [isSaveReport, setIsSaveReport] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isCancelReport, setIsCancelReport] = useState(false);
  const [isStartPopup, setIsStartPopup] = useState(false);
  const [isSubmitReport, setIsSubmitReport] = useState(false);
  const [isDisableContinue, setIsDisableContinue] = useState(false);
  const SchoolReducer = (state: any) => state?.schoolReducer;
  const deepCopyQuestion = () => JSON.parse(JSON.stringify(question));
  const userData =
    localStorage.getItem("loginResponse") !== null
      ? JSON.parse(localStorage.getItem("loginResponse")!)
      : {};
  const currentDate = moment().format("YYYY-MM-DD");
  const getDropdown = (response: any, name: string, last: string) => {
    let dropdown: any = [];
    response &&
      response?.map((value: any) => {
        dropdown.push({
          value: value.id,
          label: last ? value[name] + " " + value[last] : value[name],
          email: value.email,
          address: value.address,
          mobile: value.mobile,
        });
      });
    return dropdown;
  };

  const caseloadData = createSelector(CaseloadReducer, (state) => ({
    caseloadByIdData: state.caseloadByIdData,
  }));

  const SchoolData = createSelector(SchoolReducer, (state) => ({
    schoolReport: state?.report,
  }));

  const { schoolReport } = useSelector(SchoolData);

  const { caseloadByIdData } = useSelector(caseloadData);


  const checkAnswers = (arr: any) => {
    let hasNullAnswer = false;
    arr?.forEach((item: any) => {
      if (
        item.answer === null ||
        item.answer === undefined ||
        (item.answer === "" && item.isRequired)
      ) {
        setIsError(true);
        hasNullAnswer = true;
      }
    });
    return hasNullAnswer;
  };

  const checkAndReturn = (param: string, data: any, schoolReport: any) =>
    param === "edit" ? schoolReport : data;

  const handleNext = () => {
    switch (activeStep) {
      case 0:
        if (
          checkAnswers(
            checkAndReturn(
              param,
              questionData?.personalDetail,
              schoolReport?.personalDetail
            )
          )
        ) {
          return;
        }
        break;
      case 1:
        if (
          checkAnswers(
            checkAndReturn(
              param,
              questionData?.communication,
              schoolReport?.communication
            )
          )
        ) {
          return;
        }
        break;
      case 2:
        if (
          checkAnswers(
            checkAndReturn(
              param,
              questionData?.socialInteraction,
              schoolReport?.socialInteraction
            )
          )
        ) {
          return;
        }
        break;
      case 3:
        if (
          checkAnswers(
            checkAndReturn(
              param,
              questionData?.creativityImagination,
              schoolReport?.creativityImagination
            )
          )
        ) {
          return;
        }
        break;
      case 4:
        if (
          checkAnswers(
            checkAndReturn(
              param,
              questionData?.behaviour,
              schoolReport?.behaviour
            )
          )
        ) {
          return;
        }
        break;
      case 5:
        if (
          checkAnswers(
            checkAndReturn(param, questionData?.sensory, schoolReport?.sensory)
          )
        ) {
          return;
        }
        break;
      case 6:
        if (
          checkAnswers(
            checkAndReturn(
              param,
              questionData?.attentionActivityLevels,
              schoolReport?.attentionActivityLevels
            )
          )
        ) {
          return;
        }
        break;
      case 7:
        if (
          checkAnswers(
            checkAndReturn(
              param,
              questionData?.otherRelevantInformation,
              schoolReport?.otherRelevantInformation
            )
          )
        ) {
          return;
        } else {
          setIsSubmitReport(true);

          return;
        }
        break;
      default:
        break;
    }

    setCompleteSteps(activeStep);

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setEnableStep((prevActiveStep) => prevActiveStep + 1);
    scrollToTop();
  };

  useEffect(() => {
    dispatch(getCaseloadById(id));
    if (educationReportID) {
      setQuestionData(schoolReport.data);
    } else {
      setQuestionData(deepCopyQuestion());
    }
  }, [question]);

  const handleSubmit = (type: string) => {
    let payload = {
      total_count: 7,
      actual_count: activeStep,
      data: questionData,
      caseload_id: id,
      isFinalSubmit: type !== "SAVE" ? true : false,
    };
    if (educationReportID) {
      dispatch(
        EditReport(educationReportID, payload, payload.caseload_id, navigate)
      );
    } else {
      dispatch(addReport(payload, navigate));
    }
    if (type !== "SAVE") {
      setTimeout(() => {}, 1000);
    } else {
      // setIsSaveReport(false)
    }
    setIsSubmitReport(false);
    dispatch(setCaseloadCurentTab(1));
  };

  useEffect(() => {
    dispatch(getSchoolReport({ caseload_id: id }));
    const mainCategories = Object.keys(questionData);
    setTotal(mainCategories.length);
  }, []);

  useEffect(() => {
    try {
      let tempQuestions =
        param === "edit" ? schoolReport.data : { ...deepCopyQuestion() };
      let tempPersonalDetail = [...tempQuestions?.personalDetail];
      let tempOtherInfo = [...tempQuestions?.otherRelevantInformation];

      let patientFirstName = caseloadByIdData?.patient_name?.split(" ")?.[0];
      let patientLastName = caseloadByIdData?.patient_name?.split(" ")?.[1];

      tempPersonalDetail[0].answer = `${patientFirstName} ${patientLastName}`;
      tempPersonalDetail[1].answer = caseloadByIdData?.patient_dob;
      tempPersonalDetail[2].answer = userData?.school_name;
      tempPersonalDetail[5].answer = userData?.email;
      tempPersonalDetail[7].answer = currentDate;
      tempOtherInfo[6].answer = currentDate;
      setQuestionData({ ...tempQuestions, personalDetail: tempPersonalDetail });
      setQuestionData({
        ...tempQuestions,
        otherRelevantInformation: tempOtherInfo,
      });
    } catch (error) {
      console.error("An error occurred while setting question data:", error);
    }
  }, [caseloadByIdData, param]);

  useEffect(() => {
    const toggleVisibility = () => {
      if (scrollContainerRef.current) {
        setIsVisible(scrollContainerRef.current.scrollTop > 200);
      }
    };

    if (scrollContainerRef.current) {
      scrollContainerRef.current.addEventListener("scroll", toggleVisibility);
    }

    return () => {
      if (scrollContainerRef.current) {
        scrollContainerRef.current.removeEventListener(
          "scroll",
          toggleVisibility
        );
      }
    };
  }, []);

  const scrollToTop = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollTo({ top: 0, behavior: "smooth" });
    }
  };

  useEffect(() => {
    if (!educationReportID) {
      setIsStartPopup(true);
    } else {
      setActiveStep(schoolReport?.actual_count);
    }
  }, [educationReportID, schoolReport]);

  const questionsToPass = param === "edit" ? schoolReport.data : questionData;

  return (
    <Grid
      container
      spacing={4}
      className={classes.refContainer}
      marginTop={"10px"}
    >
      <Grid item lg={3} md={4} sm={4} xs={12} className={classes.pt0}>
        <SideStepper
          activeStep={activeStep}
          setActiveStep={setActiveStep}
          completeSteps={completeSteps}
          enableStep={enableStep}
        />
      </Grid>
      <Grid ref={scrollContainerRef} item lg={9} md={8} sm={8} xs={12}>
        <Grid>
          {activeStep === 0 && (
            <PersonalDetail
              caseloadDetail={caseloadByIdData}
              patientName={caseloadByIdData.patient_name}
              questions={questionsToPass}
              setQuestionData={(e: any) => setQuestionData(e)}
              isError={isError}
              setIsError={setIsError}
              setIsDisableContinue={setIsDisableContinue}
            />
          )}
          {activeStep === 1 && (
            <Communication
              patientName={caseloadByIdData.patient_name}
              questions={questionsToPass}
              setQuestionData={(e: any) => setQuestionData(e)}
              isError={isError}
              setIsError={setIsError}
              setIsDisableContinue={setIsDisableContinue}
            />
          )}
          {activeStep === 2 && (
            <PersonDetail
              patientName={caseloadByIdData.patient_name}
              questions={questionsToPass}
              setQuestionData={(e: any) => setQuestionData(e)}
              isError={isError}
              setIsError={setIsError}
              setIsDisableContinue={setIsDisableContinue}
              setIsNewSchool={setIsNewSchool}
            />
          )}
          {activeStep === 3 && (
            <CreativityImagination
              patientName={caseloadByIdData.patient_name}
              questions={questionsToPass}
              setQuestionData={(e: any) => setQuestionData(e)}
              isError={isError}
              setIsError={setIsError}
              setIsDisableContinue={setIsDisableContinue}
            />
          )}
          {activeStep === 4 && (
            <Behavior
              patientName={caseloadByIdData.patient_name}
              questions={questionsToPass}
              setQuestionData={(e: any) => setQuestionData(e)}
              isError={isError}
              setIsError={setIsError}
              setIsDisableContinue={setIsDisableContinue}
            />
          )}
          {activeStep === 5 && (
            <Sensory
              patientName={caseloadByIdData.patient_name}
              questions={questionsToPass}
              setQuestionData={(e: any) => setQuestionData(e)}
              isError={isError}
              setIsError={setIsError}
              setIsDisableContinue={setIsDisableContinue}
            />
          )}
          {activeStep === 6 && (
            <AttentionLevels
              patientName={caseloadByIdData.patient_name}
              questions={questionsToPass}
              setQuestionData={(e: any) => setQuestionData(e)}
              isError={isError}
              setIsError={setIsError}
              setIsDisableContinue={setIsDisableContinue}
            />
          )}
          {activeStep === 7 && (
            <AdditionalParentDetail
              patientName={caseloadByIdData.patient_name}
              questions={questionsToPass}
              setQuestionData={(e: any) => setQuestionData(e)}
              isError={isError}
              setIsError={setIsError}
              setIsDisableContinue={setIsDisableContinue}
            />
          )}
        </Grid>
        <Grid
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "1rem",
            maxWidth: "500px",
            margin: "2rem auto",
          }}
        >
          <Button
            variant="outlined"
            sx={{ minWidth: "200px" }}
            fullWidth={true}
            onClick={() => setIsSaveReport(true)}
          >
            Save & Draft
          </Button>
          <Grid>
            <Button
              variant="outlined"
              onClick={() =>
                setActiveStep((prevActiveStep) => prevActiveStep - 1)
              }
              sx={{ minWidth: "200px" }}
              fullWidth={true}
              disabled={activeStep === 0}
            >
              Previous
            </Button>
          </Grid>

          <Grid>
            <Button
              variant="contained"
              onClick={() => handleNext()}
              sx={{ minWidth: "200px" }}
              fullWidth={true}
              // disabled={isDisableContinue}
            >
              {activeStep === 7 ? "Submit" : "Continue"}
            </Button>
          </Grid>
          {isVisible && (
            <Grid className="posFixed">
              <Button variant="outlined" onClick={scrollToTop}>
                <ArrowUpwardIcon />
              </Button>
            </Grid>
          )}
        </Grid>
      </Grid>

      <>


        <ConfirmationModal
          open={isSubmitReport}
          handleClose={() => setIsSubmitReport(false)}
          title="Confirmation"
          message="Are you sure you want to Submit report ?"
          onConfirm={() => handleSubmit("SUBMIT")}
          onCancel={() => setIsSubmitReport(false)}
        />
      </>
      <>
        <Modal
          open={isStartPopup}
          title={"Confirmation"}
        >
          <Box className={classes.clinicModal}>
            <Grid sx={{ width: "100%", py: 2 }}>
              <Typography
                sx={{ textAlign: "center" }}
                variant="h4"
                fontFamily={"poppins"}
              >
                Important Notice:
              </Typography>
              <Typography variant="body1" fontFamily={"poppins"}>
                <p>
                  Before you begin filling out this form, please be aware of the
                  following:
                </p>
                <p>
                  1. Time Required: The form will take approximately 40-50
                  minutes to complete.
                </p>
                <p>
                  2. No Save Option: Once you start, you will not be able to
                  save the form and return to it later. Please ensure you have
                  enough uninterrupted time to complete it in one sitting.
                </p>
                <p>
                  {" "}
                  To avoid any inconvenience, we recommend having all necessary
                  information and documents ready before you start.
                </p>
                Thank you for your understanding and cooperation.
              </Typography>
            </Grid>
            <Grid
              className={classes.btnWrapper}
              sx={{
                display: "flex",
                justifyContent: "center",
                gap: "10px",
                alignItem: "center",
                mt: "15px",
              }}
            >
              {/* <Button sx={{ width: '30%' }} variant={"outlined"} onClick={() => setIsWarningFlag(false)} >No</Button> */}
              <Button
                sx={{ width: "30%" }}
                variant={"contained"}
                onClick={() => setIsStartPopup(false)}
              >
                Okay
              </Button>
            </Grid>
          </Box>
        </Modal>
      </>
      <>

        <ConfirmationModal
          open={isCancelReport}
          handleClose={() => setIsCancelReport(false)}
          title="Confirmation"
          message=" Are you sure you want to Cancel report"
          onConfirm={() => navigate(-1)}
          onCancel={() => setIsCancelReport(false)}
        />
      </>


      <ConfirmationModal
        open={isSaveReport}
        handleClose={() => setIsSaveReport(false)}
        title="Confirmation"
        message=" Are you sure you want to Save as Draft report"
        onConfirm={() => handleSubmit("SAVE")}
        onCancel={() => setIsSaveReport(false)}
      />
    </Grid>
  );
};

export default Index;
