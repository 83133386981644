import { all, fork } from "redux-saga/effects";

import login from "./auth/saga";
import referral from "./refferal/saga";
import report from "./report/saga";
import userManagement from "./userManagement/saga";
import caseload from "./caseload/saga";
import Layout from "./Layout/saga";
import Resources from './resources/saga'

export default function* rootSaga() {

  yield fork(login)
  yield fork(referral)
  yield fork(userManagement)
  yield fork(caseload)
  yield fork(Layout)
  yield fork(Resources)
  yield fork(report)


}
