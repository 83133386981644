import React, { useEffect, useState } from 'react';
import {
    Grid,
} from '@mui/material';
import classes from './index.module.scss';
import CommonField from './CommonField';
import {CurrentUserData} from '../../ManageCaseLoad/interface'
import { formatNHSNumber } from '../../../../constants/utils';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { getCaseloadById } from '../../../../redux/action';
import moment from 'moment';

const Personal_Details = ({editCase, questions, setQuestionData,patientName,prefiled, isError, setIsError ,setIsDisableContinue}: any) => {
    
   let QuestionNumber = 0
   const dispatch = useDispatch();
   const location = useLocation();
   const pathParts = location.pathname.split("/").filter(Boolean);
   const id = pathParts[2];
   const setAnswerIfNotSet = (x:any, idCondition:any, nameCondition:any, value:any) => {
    if (x.id === idCondition && x.name === nameCondition && !x.answer) {
      x.answer = value;
      x.disabled = true;
      if (x.name === 'Sex') {
        x.value = prefiled?.patient_gender;
        x.options.forEach((option: any) => {
            if (option?.name == x?.value) {
                option.checked = true;
                option.disabled = false
            } else {
                option.checked = false;
                option.disabled = true
            }
        });
    }

    }
    return x;
  };


  questions?.personalDetails &&
questions?.personalDetails[0].nestingObj.onetype.map((x: any) => {
  setAnswerIfNotSet(x, 1, 'Name', prefiled?.patient_name);
  setAnswerIfNotSet(x, 2, 'Date of Birth', moment(prefiled?.patient_dob).format("DD/MM/YYYY"));
  setAnswerIfNotSet(x, 3, 'Address', prefiled?.patient_address);
  setAnswerIfNotSet(x, 4, 'NHS Number', prefiled?.nhs_number);
  setAnswerIfNotSet(x, 5, 'GP Details', `${prefiled?.gp_name},${prefiled?.gp_address}`);
  setAnswerIfNotSet(x, 6, 'Sex', prefiled?.patient_gender);
  return x;
});

const userData: CurrentUserData | null = JSON.parse(
  localStorage.getItem("loginResponse") || "null"
);

questions?.personalDetails &&
questions?.personalDetails[1].nestingObj.onetype.map((x: any) => {
  setAnswerIfNotSet(x, 1, 'Name', `${userData?.first_name} ${userData?.last_name}`);
  setAnswerIfNotSet(x, 3, 'Email Address', userData?.email);
  setAnswerIfNotSet(x, 2, 'Contact Number', userData?.phone_no);
  setAnswerIfNotSet(x, 4, 'Address', userData?.address);


  return x;
});

questions?.personalDetails &&
questions?.personalDetails?.map((x: any) => {
    x.question = x.question.replace('[forename]', patientName);
    return x;
});

// questions?.personalDetails?.map((x:any)=> x?.question?.replace('[forename]', patientName))



   


   const handleAnswerChange = (index: number, value: any, key: string) => {
    let tempQuestions = { ...questions };
    let temppersonalDetails = [...tempQuestions.personalDetails];
    
    // Replace '[forename]' with 'ddddddd' in the question at the specified index
    temppersonalDetails[index].question = temppersonalDetails[index].question.replace('[forename]', patientName);

    // Update the answer value

    temppersonalDetails[index].answer = value;

    // Update the state with the modified personalDetails array
    setQuestionData({ ...tempQuestions, personalDetails: temppersonalDetails });
};

    // const handleRenderComponent = (type: string, index: number, question: string, answer: string, options: any, key: string, required: boolean, question_number: number) => {
    //     return (
    //         <Card className={classes.card} key={index}>
    //             <Typography className={classes.questionTitle}>Question {question_number} to {questions[key][questions[key]?.length - 1].question_number}</Typography>
    //             <Grid display={'flex'} sx={{ gap: '5px' }}>
    //                 <Typography className={`${classes.questionText} ${!required && classes.isDisabled}`}>

    //                     Q.{question_number}
    //                 </Typography><Typography className={`${classes.questionText} ${!required && classes.isDisabled}`}>

    //                     {question}
    //                 </Typography>
    //             </Grid>
    //             {type === 'radio' && (
    //                 <RadioGroup
    //                     value={answer}
    //                     onChange={(e) => handleAnswerChange(index, e.target.value, key)}
    //                     className={classes.radioGroup}
    //                 >
    //                     {options && options.map((option: string, optionIndex: number) => (
    //                         <FormControlLabel disabled={!required} key={optionIndex} value={option} control={<Radio className={classes.formControlLabel} />} label={option} />
    //                     ))}
    //                 </RadioGroup>
    //             )}
    //             {(type === "text" || type === "number" || type === "email" || type === "date" || type === "textArea") && (
    //                 <TextField
    //                     fullWidth
    //                     value={answer}
    //                     disabled={!required}
    //                     type={type === "textArea" ? 'text' : type}
    //                     onChange={(e) => handleAnswerChange(index, e.target.value, key)}
    //                     placeholder={type === "date" ? "DD/MM/YYYY" : type === "email" ? "Please Enter Email" : type === "textArea" ? "Please 500 Characters" : "Please Enter"}
    //                     variant="outlined"
    //                     multiline={type === "textArea"}
    //                     rows={type === "textArea" ? 4 : 1}
    //                     inputProps={{ maxLength: 500, min: "1995-01-01" }} />
    //             )}
    //             {type === "dropdown" &&
    //                 <FormControl fullWidth>
    //                     <Select
    //                         className={classes.select}
    //                         name="gender"
    //                         value={answer}
    //                         disabled={!required}
    //                         placeholder={"select"}
    //                         onChange={(e) => handleAnswerChange(index, e.target.value, key)}
    //                         displayEmpty
    //                     >
    //                         <MenuItem value="" disabled>
    //                             Select
    //                         </MenuItem>
    //                         {options && options.map((option: string, optionIndex: number) => (
    //                             <MenuItem key={optionIndex} value={option}  >{option}</MenuItem>
    //                         ))}
    //                     </Select>
    //                 </FormControl>
    //             }
    //             {isError && answer === "" && required && <p className={classes.errorColor}>This field is required</p>}
    //         </Card>
    //     );
    // };

    useEffect(() => {
        setIsError(false)
    }, [])
    return (
        <Grid container spacing={3} className={classes.Referral}>

            {questions?.personalDetails && questions?.personalDetails?.map((ques: any, index: number) => (
                <>
                 {/* {handleRenderComponent(ques.type, index, ques.question, ques.answer, ques.option, "gp_detail", ques.isRequired,ques.question_number)}  */}
                    { 
                    <CommonField
                        type={ques.type}
                        index={index}
                        question={ques.question.replace('[forename]',patientName)}
                        answer={ques.answer}
                        options={ques.option}
                        key={"personalDetails"}
                        required={ques.isRequired}
                        question_number={QuestionNumber}
                        questions={questions?.personalDetails}
                        isError={isError}
                        handleAnswerChange={handleAnswerChange}
                        ques={ques}
                        setIsDisableContinue={setIsDisableContinue}
                        totalQuestion={questions?.personalDetails?.length}
                        
                    /> }


                </>

            ))}
        </Grid>
    );
};

export default Personal_Details;
