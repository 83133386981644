
import { CREATE_RESOURCES, RESOURCES_LISTING, RES_RESOURCES_DOCUMENT, RES_RESOURCES_LISTING} from './actionType'



const initialDocState = {
  data: null,
  loading: false,
  error: null,
  docData:null,
};





export const ResourceDocumentReducer = (state = initialDocState, action: any) => {
  switch (action.type) {
    case RESOURCES_LISTING:
      return { ...state, loading: true, error: null };
    case RES_RESOURCES_LISTING:
      return { ...state, loading: false, data: action.payload, error: null };
    case RES_RESOURCES_DOCUMENT:
      return { ...state, loading: false, docData: action.payload, error: null };
    default:
      return state;
  }
};





