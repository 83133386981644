import Login from './Login'
import ForgotPassword from './ForgotPassword'
import OTP from './OTP'
// import Verification from './Verification'
import Register from './Register'
import Profile from './Profile'
import PrivacyNotice from './Privacy'
const Auth = {

    Login,
    ForgotPassword,
    OTP,
    Register,
    Profile,
    PrivacyNotice,
    // Verification,
    // Register
}
export default Auth