import React, { useEffect, useRef, useState } from "react";
import Button from "../../../../components/atoms/Button";
import Grid from "@mui/material/Grid";
import { Box, Typography } from "@mui/material";
import SideStepper from "./sideStepper";
import Personal_Details from "./Personal_Details";
import Social_Interaction from "./Social_Interaction";
import Sensory from "./Sensory";
import Communication from "./Communication";
import FamilyHIstory from "./FamilyHIstory";
import PlayImagination from "./PlayImagination";
import BirthDetails from "./BirthDetails";
import AttentionActivity from "./AttentionActivity";
import MotorMannerisms from "./MotorMannerisms";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import { question } from "./question";
import {
  EditReferral,
  addReferral,
  changeTab,
  getCaseloadById,
  getParentReport,
  setCaseloadCurentTab,
} from "../../../../redux/action";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";
import classes from "./index.module.scss";
import Mental from "./Mental";
import Previous_assessments from "./Previous_assessments";
import Education from "./Education";
import Modal from "../../../../components/atoms/Modal";
import moment from "moment";
import constant from "../../../../constants/constant";
import ConfirmationModal from "../../../../components/atoms/ConfirmationModal";

const Index = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [isToggled, setIsToggled] = useState(false);
  const pathParts = location.pathname.split("/").filter(Boolean);
  const param = pathParts[1];
  const id = pathParts[2];
  const parentReportId = pathParts[3];
  const currentDate = moment().format("YYYY-MM-DD");
  const [isVisible, setIsVisible] = useState(false);
  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const queryParams = new URLSearchParams(window.location.search);
  const patientName: any = queryParams.get("name");
  const [activeStep, setActiveStep] = useState(0);
  const [enableStep, setEnableStep] = useState(0);
  const [total, setTotal] = useState(0);
  // const [questionsToPass, setQuestionsToPass] = useState(null);
  const [isNewSchool, setIsNewSchool] = useState();
  const [completeSteps, setCompleteSteps] = useState<any>();
  const [isStartPopup, setIsStartPopup] = useState(false);
  const [isSubmitafter, setIsSubmitAfter] = useState(false);
  const [islastReferral, setIslastReferral] = useState(false);
  const [isSubmitReport, setIsSubmitReport] = useState(false);
  const [isSaveReport, setIsSaveReport] = useState(false);
  const [questionData, setQuestionData] = useState<any>({});
  const [isError, setIsError] = useState(false);
  const CaseloadReducer = (state: any) => state?.CaseloadReducer;
  const caseloadData = createSelector(CaseloadReducer, (state) => ({
    caseloadByIdData: state.caseloadByIdData,
  }));

  const { caseloadByIdData } = useSelector(caseloadData);

  const ParentReducer = (state: any) => state?.parentReducer;
  const ParentData = createSelector(ParentReducer, (state) => ({
    parentReport: state?.report,
  }));

  const { parentReport } = useSelector(ParentData);

  const [isDisableContinue, setIsDisableContinue] = useState(false);
  const deepCopyQuestion = () => JSON.parse(JSON.stringify(question));
  const userReducer = (state: any) => state?.userManagementReducer;
  const getDropdown = (response: any, name: string, last: string) => {
    let dropdown: any = [];
    response &&
      response?.map((value: any) => {
        dropdown.push({
          value: value.id,
          label: last ? value[name] + " " + value[last] : value[name],
          email: value.email,
          address: value.address,
          mobile: value.mobile,
        });
      });
    return dropdown;
  };
  const userData = createSelector(userReducer, (state) => ({
    userList: state.userData.user,
  }));
  const { userList } = useSelector(userData);
  const checkAnswers = (arr: any) => {
    let hasNullAnswer = false;
    arr?.forEach((item: any) => {
      if (
        item.answer === null ||
        item.answer === undefined ||
        (item.answer === "" && item.isRequired) 
      ) {
        setIsError(true);
        hasNullAnswer = true;
      }
    });
    return hasNullAnswer;
  };

  const checkAndReturn = (param: string, data: any, parentData: any) =>
    param === "edit" ? parentData : data;

  const handleNext = () => {
    switch (activeStep) {
      case 0:
        if (
          checkAnswers(
            checkAndReturn(
              param,
              questionData?.personalDetails,
              parentReport?.data?.personalDetails
            )
          )
        ) {
          return;
        }
        break;
      case 1:
        if (
          checkAnswers(
            checkAndReturn(
              param,
              questionData?.familyHistory,
              parentReport?.data?.familyHistory
            )
          )
        ) {
          return;
        }
        break;
      case 2:
        if (
          checkAnswers(
            checkAndReturn(
              param,
              questionData?.communication,
              parentReport?.data?.communication
            )
          )
        ) {
          return;
        }
        break;
      case 3:
        if (
          checkAnswers(
            checkAndReturn(
              param,
              questionData?.socialInteraction,
              parentReport?.data?.socialInteraction
            )
          )
        ) {
          return;
        }
        break;
      case 4:
        if (
          checkAnswers(
            checkAndReturn(
              param,
              questionData?.playImagination,
              parentReport?.data?.playImagination
            )
          )
        ) {
          return;
        }
        break;
      case 5:
        if (
          checkAnswers(
            checkAndReturn(
              param,
              questionData?.sensory,
              parentReport?.data?.sensory
            )
          )
        ) {
          return;
        }
        break;
      case 6:
        if (
          checkAnswers(
            checkAndReturn(
              param,
              questionData?.motorMannerisms,
              parentReport?.data?.motorMannerisms
            )
          )
        ) {
          return;
        }
        break;
      case 7:
        if (
          checkAnswers(
            checkAndReturn(
              param,
              questionData?.attentionActivity,
              parentReport?.data?.attentionActivity
            )
          )
        ) {
          return;
        }
        break;
      case 8:
        if (
          checkAnswers(
            checkAndReturn(
              param,
              questionData?.birthDetails,
              parentReport?.data?.birthDetails
            )
          )
        ) {
          return;
        }
        break;
      case 9:
        if (
          checkAnswers(
            checkAndReturn(
              param,
              questionData?.education,
              parentReport?.data?.education
            )
          )
        ) {
          return;
        }
        break;
      case 10:
        if (
          checkAnswers(
            checkAndReturn(
              param,
              questionData?.mental,
              parentReport?.data?.mental
            )
          )
        ) {
          return;
        }
        break;
      case 11:
        if (
          checkAnswers(
            checkAndReturn(
              param,
              questionData?.previousAssessments,
              parentReport?.data?.previousAssessments
            )
          )
        ) {
          return;
        } else {
          setIsSubmitReport(true);
          return;
        }
      default:
        break;
    }
    setCompleteSteps(activeStep);

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setEnableStep((prevActiveStep) => prevActiveStep + 1);
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollTo({ top: 0, behavior: "smooth" });
    }
  };

  useEffect(() => {
    if (id) {
      dispatch(getCaseloadById(id));
    } else {
      console.error("ID is undefined.");
    }
    // dispatch(getUserList({ scope: constant.SCOPE.SCHOOL }));
    dispatch(changeTab("Parent / Carer Report Form"));
    setQuestionData(deepCopyQuestion());
  }, [question]);

  const handleSubmit = (type: string) => {
    let payload = {
      total_count: 11,
      actual_count: activeStep,
      data: questionData,
      caseload_id: id,
      isFinalSubmit: type !== "SAVE" ? true : false,
    };
    if (parentReportId) {
      dispatch(
        EditReferral(parentReportId, payload, payload.caseload_id, navigate)
      );
    } else {
      dispatch(addReferral(payload, navigate));
    }
    if (type !== "SAVE") {
      setIsSubmitAfter(true);
    } else {
      // setIsSaveReport(false)
    }
    setIsSubmitReport(false);
    dispatch(setCaseloadCurentTab(2));
  };

  useEffect(() => {
    handleAddSchool();
    if (!parentReportId) {
      setIsStartPopup(true);
      setActiveStep(0);
    } else {
      setActiveStep(parentReport?.actual_count);
    }
  }, [parentReport]);


  const handleAddSchool = () => {
    let tempQuestions: any = parentReportId
      ? parentReport.data
      : deepCopyQuestion();

    if (tempQuestions) {
      let tempPersonDetail = [...tempQuestions?.communication];
      setQuestionData({ ...tempQuestions, communication: tempPersonDetail });
      setQuestionData({
        ...tempQuestions,
      });
    }
  };

  useEffect(() => {
    const toggleVisibility = () => {
      if (scrollContainerRef.current) {
        setIsVisible(scrollContainerRef.current.scrollTop > 200);
      }
    };

    if (scrollContainerRef.current) {
      scrollContainerRef.current.addEventListener("scroll", toggleVisibility);
    }

    return () => {
      if (scrollContainerRef.current) {
        scrollContainerRef.current.removeEventListener(
          "scroll",
          toggleVisibility
        );
      }
    };
  }, []);

  const scrollToTop = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollTo({ top: 0, behavior: "smooth" });
    }
  };

  useEffect(() => {
    const mainCategories = Object.keys(questionData);
    setTotal(mainCategories.length - 1);
  }, [questionData]);

  useEffect(() => {
    dispatch(getParentReport({ caseload_id: id }));
  }, [id]);
;

  const questionsToPass = param === "edit" ? parentReport.data : questionData;
  return (
    <>
      <Grid container spacing={4} className={classes.refContainer}>
        <Grid item lg={3} md={3} sm={3} xs={12} className={classes.pt0}>
          <SideStepper
            activeStep={activeStep}
            setActiveStep={setActiveStep}
            completeSteps={completeSteps}
            enableStep={enableStep}
          />
        </Grid>
        <Grid ref={scrollContainerRef} item lg={9} md={9} sm={9} xs={12}>
          <Grid>
            {activeStep === 0 && (
              <Personal_Details
                prefiled={caseloadByIdData}
                editCase={param === "edit"}
                patientName={patientName?.toUpperCase()}
                questions={questionsToPass}
                setQuestionData={(e: any) => setQuestionData(e)}
                isError={isError}
                setIsError={setIsError}
                setIsDisableContinue={setIsDisableContinue}
              />
            )}
            {activeStep === 1 && (
              <FamilyHIstory
                patientName={patientName.toUpperCase()}
                questions={questionsToPass}
                setQuestionData={(e: any) => setQuestionData(e)}
                isError={isError}
                setIsError={setIsError}
                setIsDisableContinue={setIsDisableContinue}
              />
            )}
            {activeStep === 2 && (
              <Communication
                patientName={patientName.toUpperCase()}
                questions={questionsToPass}
                setQuestionData={(e: any) => setQuestionData(e)}
                isError={isError}
                setIsError={setIsError}
                setIsDisableContinue={setIsDisableContinue}
                setIsNewSchool={setIsNewSchool}
              />
            )}
            {activeStep === 3 && (
              <Social_Interaction
                patientName={patientName.toUpperCase()}
                questions={questionsToPass}
                setQuestionData={(e: any) => setQuestionData(e)}
                isError={isError}
                setIsError={setIsError}
                setIsDisableContinue={setIsDisableContinue}
              />
            )}
            {activeStep === 4 && (
              <PlayImagination
                patientName={patientName.toUpperCase()}
                questions={questionsToPass}
                setQuestionData={(e: any) => setQuestionData(e)}
                isError={isError}
                setIsError={setIsError}
                setIsDisableContinue={setIsDisableContinue}
              />
            )}
            {activeStep === 5 && (
              <Sensory
                patientName={patientName.toUpperCase()}
                questions={questionsToPass}
                setQuestionData={(e: any) => setQuestionData(e)}
                isError={isError}
                setIsError={setIsError}
                setIsDisableContinue={setIsDisableContinue}
              />
            )}
            {activeStep === 6 && (
              <MotorMannerisms
                patientName={patientName.toUpperCase()}
                questions={questionsToPass}
                setQuestionData={(e: any) => setQuestionData(e)}
                isError={isError}
                setIsError={setIsError}
                setIsDisableContinue={setIsDisableContinue}
              />
            )}
            {activeStep === 7 && (
              <AttentionActivity
                patientName={patientName.toUpperCase()}
                questions={questionsToPass}
                setQuestionData={(e: any) => setQuestionData(e)}
                isError={isError}
                setIsError={setIsError}
                setIsDisableContinue={setIsDisableContinue}
              />
            )}
            {activeStep === 8 && (
              <BirthDetails
                patientName={patientName.toUpperCase()}
                questions={questionsToPass}
                setQuestionData={(e: any) => setQuestionData(e)}
                isError={isError}
                setIsError={setIsError}
                setIsDisableContinue={setIsDisableContinue}
              />
            )}
            {/* {activeStep === 9 && <EarlyDevelopement  patientName={patientName.toUpperCase()} questions={questionsToPass} setQuestionData={(e: any) => setQuestionData(e)} isError={isError} setIsError={setIsError} setIsDisableContinue={setIsDisableContinue} />} */}
            {activeStep === 9 && (
              <Education
                patientName={patientName.toUpperCase()}
                questions={questionsToPass}
                setQuestionData={(e: any) => setQuestionData(e)}
                isError={isError}
                setIsError={setIsError}
                setIsDisableContinue={setIsDisableContinue}
              />
            )}
            {activeStep === 10 && (
              <Mental
                patientName={patientName.toUpperCase()}
                questions={questionsToPass}
                setQuestionData={(e: any) => setQuestionData(e)}
                isError={isError}
                setIsError={setIsError}
                setIsDisableContinue={setIsDisableContinue}
              />
            )}
            {activeStep === 11 && (
              <Previous_assessments
                patientName={patientName.toUpperCase()}
                questions={questionsToPass}
                setQuestionData={(e: any) => setQuestionData(e)}
                isError={isError}
                setIsError={setIsError}
                setIsDisableContinue={setIsDisableContinue}
              />
            )}
            {/* {activeStep === 12 && <Information_sharing patientName={patientName.toUpperCase()} questions={questionData} setQuestionData={(e: any) => setQuestionData(e)} isError={isError} setIsError={setIsError} setIsDisableContinue={setIsDisableContinue} />} */}
          </Grid>
          <Grid
            sx={{
              display: "flex",
              justifyContent: "center",
              gap: "10px",
              mt: "15px",
            }}
          >
            <Grid sx={{ display: "flex", alignItems: "center", gap: "1rem" }}>
              <Button
                variant="outlined"
                sx={{ minWidth: "200px" }}
                onClick={() => setIsSaveReport(true)}
              >
                Save & Draft
              </Button>
              <Button
                variant="outlined"
                onClick={() =>
                  setActiveStep((prevActiveStep) => prevActiveStep - 1)
                }
                fullWidth={true}
                disabled={activeStep === 0}
              >
                Previous
              </Button>
              <Button
                variant="contained"
                onClick={handleNext}
                fullWidth={true}
                // disabled={isDisableContinue}
              >
                {activeStep === 11 ? "Submit" : "Continue"}
              </Button>
            </Grid>

            <Grid></Grid>
            {isVisible && (
              <Grid className="posFixed">
                <Button variant="outlined" onClick={scrollToTop}>
                  <ArrowUpwardIcon />
                </Button>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>

      <>
        <Modal
          open={isStartPopup}
          // handleClose={() => {
          //   setIsWarningFlag(false)
          //   // setIsSeletedClinician('')
          // }}
          title={"Important Notice"}
        >
          <Box className={classes.clinicModal}>
            <Grid sx={{ width: "100%", py: 2 }}>
              <Typography variant="body1" fontFamily={"poppins"}>
                <p>
                  Before you begin filling out this form, please be aware of the
                  following:
                </p>
                <p>
                  1. Time Required: The form will take approximately 40-50
                  minutes to complete.
                </p>
                <p>
                  2. Save function: You are able to save your information as you
                  go by pressing the 'save' button at the bottom of the form.
                  Using the save function means that you can exit this form and
                  return to continue it later on if you need more time or to
                  gather more information.
                </p>
                <p>
                  {" "}
                  To avoid any inconvenience, we recommend having all necessary
                  information and documents ready before you start.
                </p>
                Thank you for your understanding and cooperation.
              </Typography>
            </Grid>
            <Grid
              className={classes.btnWrapper}
              sx={{
                display: "flex",
                justifyContent: "center",
                gap: "10px",
                alignItem: "center",
                mt: "15px",
              }}
            >
              <Button
                sx={{ width: "30%" }}
                variant={"contained"}
                onClick={() => setIsStartPopup(false)}
              >
                Okay
              </Button>
            </Grid>
          </Box>
        </Modal>
      </>

      {/* <Modal
        open={isSubmitReport}
        handleClose={() => {
          setIsSubmitReport(false);
        }}
        title={"Confirmation"}
      >
        <Box className={classes.clinicModal}>
          <Grid sx={{ width: "100%", textAlign: "center", py: 2 }}>
            <Typography variant="body1" fontFamily={"poppins"}>
              Are you sure you want to <br /> submit report ?
            </Typography>
          </Grid>
          <Grid
            className={classes.btnWrapper}
            sx={{
              display: "flex",
              justifyContent: "center",
              gap: "10px",
              alignItem: "center",
              mt: "15px",
            }}
          >
            <Button
              sx={{ width: "30%", borderColor: "gray" }}
              variant={"outlined"}
              onClick={() => setIsSubmitReport(false)}
            >
              No
            </Button>
            {!isToggled && (
              <Button
                sx={{ width: "30%" }}
                variant={"contained"}
                onClick={() => handleSubmit("SUBMIT")}
              >
                Yes
              </Button>
            )}
          </Grid>
        </Box>
      </Modal> */}

      <ConfirmationModal
        open={isSubmitReport}
        handleClose={()=> setIsSubmitReport(false)}
        title="Confirmation"
        message=" Are you sure you want to Submit report"
        onConfirm={() => handleSubmit("SUBMIT")}
        onCancel={()=> setIsSubmitReport(false)}
      />

      {/* <Modal
        open={isSaveReport}
        handleClose={() => {
          setIsSaveReport(false);
        }}
        title={"Confirmation"}
      >
        <Box className={classes.clinicModal}>
          <Grid sx={{ width: "100%", textAlign: "center", py: 2 }}>
            <Typography variant="body1" fontFamily={"poppins"}>
              Are you sure you want to <br /> Save as Draft report ?
            </Typography>
          </Grid>
          <Grid
            className={classes.btnWrapper}
            sx={{
              display: "flex",
              justifyContent: "center",
              gap: "10px",
              alignItem: "center",
              mt: "15px",
            }}
          >
            <Button
              sx={{ width: "30%", borderColor: "gray" }}
              variant={"outlined"}
              onClick={() => setIsSaveReport(false)}
            >
              No
            </Button>
            {!isToggled && (
              <Button
                sx={{ width: "30%" }}
                variant={"contained"}
                onClick={() => handleSubmit("SAVE")}
              >
                Yes
              </Button>
            )}
          </Grid>
        </Box>
      </Modal> */}


      <ConfirmationModal
        open={isSaveReport}
        handleClose={()=> setIsSaveReport(false)}
        title="Confirmation"
        message=" Are you sure you want to Save as Draft report"
        onConfirm={() => handleSubmit("SAVE")}
        onCancel={()=> setIsSaveReport(false)}
      />

      <Modal
        open={isSubmitafter}
        title={"Important Notice"}
      >
        <Box className={classes.clinicModal}>
          <Grid sx={{ width: "100%", textAlign: "center", py: 2 }}>
            <Typography variant="body1" fontFamily={"poppins"}>
              Thank you for submitting this information. Once we have both your
              report and your child’s / young person’s education setting report
              (if your child is in school, nursery, college, or another
              education setting), your information will be sent to our clinical
              team for review. It may take up to six weeks from completion of
              your report to hear the outcome of this review, and you will
              receive an email when there are any updates for you to view on the
              portal. While you are waiting, you can check the Resources section
              of the portal at any time in order to see what support you can
              access for you and your child.
            </Typography>
          </Grid>
          <Grid
            className={classes.btnWrapper}
            sx={{
              display: "flex",
              justifyContent: "center",
              gap: "10px",
              alignItem: "center",
              mt: "15px",
            }}
          >
            <Button
              sx={{ width: "30%" }}
              variant={"contained"}
              onClick={() => navigate(`/manage-caseload/create?id=${id}`)}
            >
              Okay
            </Button>
          </Grid>
        </Box>
      </Modal>

      {/* <Modal
        open={islastReferral}
        handleClose={() => {
          setIslastReferral(false);

        }}
        title={"Confirmation"}
      >
        <Box className={classes.clinicModal}>
          <Grid sx={{ width: "100%", textAlign: "center", py: 2 }}>
            <Typography variant="body1" fontFamily={"poppins"}>
              Thank you for submitting this information. Once we have both your
              report and your child’s / young person’s education setting report
              (if your child is in school, nursery, college, or another
              education setting), your information will be sent to our clinical
              team for review. It may take up to six weeks from completion of
              your report to hear the outcome of this review, and you will
              receive an email when there are any updates for you to view on the
              portal. While you are waiting, you can check the Resources section
              of the portal at any time in order to see what support you can
              access for you and your child.
            </Typography>
          </Grid>
          <Grid
            className={classes.btnWrapper}
            sx={{
              display: "flex",
              justifyContent: "center",
              gap: "10px",
              alignItem: "center",
              mt: "15px",
            }}
          >
            <Button
              sx={{ width: "30%" }}
              variant={"outlined"}
              onClick={() => setIslastReferral(false)}
            >
              No
            </Button>
            <Button
              sx={{ width: "30%" }}
              variant={"contained"}
              onClick={() => navigate(`/manage-caseload/create?id=${id}`)}
            >
              Yes
            </Button>
          </Grid>
        </Box>
      </Modal> */}

      <ConfirmationModal
        open={islastReferral}
        handleClose={()=> setIslastReferral(false)}
        title="Confirmation"
        message=" Thank you for submitting this information. Once we have both your
        report and your child’s / young person’s education setting report
        (if your child is in school, nursery, college, or another
        education setting), your information will be sent to our clinical
        team for review. It may take up to six weeks from completion of
        your report to hear the outcome of this review, and you will
        receive an email when there are any updates for you to view on the
        portal. While you are waiting, you can check the Resources section
        of the portal at any time in order to see what support you can
        access for you and your child."
        onConfirm={() => navigate(`/manage-caseload/create?id=${id}`)}
        onCancel={()=> setIslastReferral(false)}
      />
    </>
  );
};

export default Index;
