import auth from './auth'
import dashboard from './dashboard'
import report from './report'
import referral from './referral'
import User_Management from './user-management'
import Upload_Documents from './upload-documents'
import Caseload from './caseload'
import Task_Management from './task'
import Chat_Management from './chat'
import Resources_Management from './resources'

const service = {
  auth,
  dashboard,
  referral,
  report,
  User_Management,
  Task_Management,
  Upload_Documents,
  Chat_Management,
  Resources_Management,
  Caseload
}
export default service
