// export const BASE_URL = 'http://192.168.1.42:5001/' //kushagra
//  export const BASE_URL = 'http://3.8.97.180:8001/' //staging
//  export const BASE_URL = 'http://192.168.1.72:5001/' //pankaj sir
// export const BASE_URL = 'http://192.168.1.58:5001/' //mahendra sir
// export const BASE_URL = 'https://api.stg.mycarebridge.co.uk/' //stg
export const BASE_URL = 'https://api.mycarebridge.co.uk/' //production


export const HEADER_TOKEN = {
    'Authorization': 'Basic YXNkY2xpZW50OmFzZHNlY3JldA==',
    "Content-Type": "application/x-www-form-urlencoded"
}

export const postcodeRegex = /[A-Z]{1,2}[0-9]{1,2}[A-Z]{0,1} ?[0-9][A-Z]{2}/i;
export const nhsRegex = new RegExp('^(?:\\d{3}\\s\\d{3}\\s\\d{4}|\\d{10}|\\d{3}-\\d{3}-\\d{4})$');
export const mobileRegex = /^\s*((\+44\s?\d{4}|\(?0\d{4}\)?)\s?\d{3}\s?\d{3}|(\+44\s?\d{3}|\(?0\d{3}\)?)\s?\d{3}\s?\d{4}|(\+44\s?\d{2}|\(?0\d{2}\)?)\s?\d{4}\s?\d{4}|(\+44\s?7\d{3}|\(?07\d{3}\)?)\s?\d{3}\s?\d{3})\s*$/;


export const limitOptions = [10, 20, 50,100];