import { ADD_REFERRAL,VIEW_REFERRAL,GET_GP_LIST,GET_REFERRAL_CASELOAD_DETAIL,GET_REFERRAL_ALL_DETAIL, EDIT_REFERRAL} from './actionType'

export const addReferral = (payload: any, navigate: any) => {
    return {
        type: ADD_REFERRAL,
        payload,
        navigate
    }
}

export const EditReferral = (id:string, payload: any,caseloadID:string, navigate: any) => {
    return {
        type: EDIT_REFERRAL,
        payload,
        id,
        caseloadID,
        navigate
    }
}

export const viewReferral = (params:any) => {
    return {
        type: VIEW_REFERRAL,
        params
        
    }
}
export const viewAllReferralDetail = (params:any) => {
    return {
        type: GET_REFERRAL_ALL_DETAIL,
        params
        
    }
}

export const getAllGPList = (params:any) => {
    return {
        type: GET_GP_LIST,
        params
        
    }
}

export const getReferalCaseloadByID = (paramsObj: any) => {

    return {
        type: GET_REFERRAL_CASELOAD_DETAIL,
        paramsObj
    }
}