export const RESPONSE_CASELOAD = "RESPONSE_CASELOAD"
export const CASELOAD_LISTING = "CASELOAD_LISTING"
export const CREATE_CASELOAD = "CREATE_CASELOAD"


export const TASK_DOC = "TASK_DOC"
export const RESPONSE_TASKDOC = "RESPONSE_TASKDOC"




// For upload documents
export const UPLOAD_FILE = 'UPLOAD_FILE';
export const UPLOAD_FILE_SUCCESS = 'UPLOAD_FILE_SUCCESS';
export const UPLOAD_FILE_FAILURE = 'UPLOAD_FILE_FAILURE';



export const CASE_DOCUMENTS_BY_ID_REQUEST = 'CASE_DOCUMENTS_BY_ID_REQUEST';
export const CASE_DOCUMENTS_BY_ID_SUCCESS = 'CASE_DOCUMENTS_BY_ID_SUCCESS';
export const CASE_DOCUMENTS_BY_ID_FAILURE = 'CASE_DOCUMENTS_BY_ID_FAILURE';



export const ADD_TASK = "ADD_TASK"
export const UPDATE_TASK = "UPDATE_TASK"
export const ADD_TASK_SUCCESS = "ADD_TASK_SUCCESS"
export const ADD_TASK_FAILURE = "ADD_TASK_FAILURE"

export const SUBMIT_TASK = "SUBMIT_TASK"
export const SUBMIT_TASK_SUCCESS = "SUBMIT_TASK_SUCCESS"
export const SUBMIT_TASK_FAILURE = "SUBMIT_TASK_FAILURE"


export const TASK_LISTING = "TASK_LISTING"
export const RESPONSE_TASK = "RESPONSE_TASK"





export const CASEUSER_LISTING = "CASEUSER_LISTING"
export const RESPONSE_CASHUSER = "RESPONSE_CASHUSER"

export const GET_CASELOAD_BY_ID = "GET_CASELOAD_BY_ID"
export const RESPONSE_CASELOAD_BY_ID = "RESPONSE_CASELOAD_BY_ID"

export const GET_SCHOOL_REPORT = "GET_SCHOOL_REPORT"
export const RESPONSE_SCHOOL_REPORT = "RESPONSE_SCHOOL_REPORT"

export const GET_PARENT_REPORT = "GET_PARENT_REPORT"
export const RESPONSE_PARENT_REPORT = "RESPONSE_PARENT_REPORT"


// for chat Action Type
export const CREATE_CONVERSATIONS = "CREATE_CONVERSATIONS"
export const SENT_MESSAGE = "SENT_MESSAGE"
export const CREATE_CONVERSATIONS_SUCCESS = "CREATE_CONVERSATIONS_SUCCESS"
export const CREATE_CONVERSATIONS_FAILURE = "CREATE_CONVERSATIONS_FAILURE"


export const CHATMSG_LISTING = "CHATMSG_LISTING"
export const RESPONSE_CHATMSG = "RESPONSE_CHATMSG"
export const RESPONSE_CHATMSG_CLEAR = "RESPONSE_CHATMSG_CLEAR"

export const CHATCONVERTSATION_LISTING = "CHATCONVERTSATION_LISTING"
export const RESPONSE_CHATCONVERTSATION = "RESPONSE_CHATCONVERTSATION"

export const SET_LOADING = 'SET_LOADING';
