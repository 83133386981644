import React from "react";
import Select from "react-select";
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import classes from "./index.module.scss"
import { Grid, InputLabel } from '@mui/material'
import Checkbox from '@mui/material/Checkbox';
import {style } from './style'
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
const SelectComponent = ({
    className,
    placeholder,
    options,
    value,
    onChange,
    isMulti,
    menuIsOpen,
    isDisabled,
    defaultValue,
    label,
    htmlFor,
    errors,
    disableCloseOnSelect,
    autoFocus,
    ref,
}: any) => {
    const handleChange = (option: any) => {
        onChange(option);
    };

    return (
        <>
            <Grid className="">
                {label && (

                    <InputLabel sx={style.label_color} htmlFor={htmlFor}>
                        {label}
                    </InputLabel>
                )}
                <Autocomplete
                    disabled={isDisabled}
                    // menuIsOpen={menuIsOpen}
                    ref={ref}
                   
                    renderInput={(params) => <TextField {...params} placeholder={placeholder} autoFocus={autoFocus} />}
                    className={`${classes.multi_select}  cursor-pointer  ${className} `}
                    renderOption={(props, option, { selected }) => (
                        <li {...props}>
                            {isMulti && <Checkbox
                                icon={icon}
                                checkedIcon={checkedIcon}
                                style={{ marginRight: 8 }}
                                checked={selected}
                            />}
                            {option.label}
                        </li>
                    )}
                    //   style={{ width: 500 }}
                    defaultValue={defaultValue}
                    value={value}
                    onChange={(e, value) => handleChange(value)}
                    //
                    options={options}
                    multiple={isMulti}
                    disableCloseOnSelect={disableCloseOnSelect}
                // isSearchable={true}
                />
            </Grid>
            {errors && <span className="error-text">{errors}</span>}
        </>
    );
};

export default SelectComponent;
