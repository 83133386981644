import React, { useEffect, useState } from 'react';
import {
    Grid,
} from '@mui/material';
import classes from './index.module.scss';
import CommonField from './CommonField';

const Consent = ({ patientName, questions, setQuestionData,caseloadByIdData, isError, setIsError, setIsDisableContinue }: any) => {
    let QuestionNumber = 0
    const handleAnswerChange = (index: number, value: any, key: string,updatedQuestion:string, updatedAdditionalInfo:string, updatedAdditionalQuestion:string, updatedIsHeading:string) => {
        let tempQuestions = { ...questions };
        let tempPersonalDetail = [...tempQuestions.personalDetail];
        tempPersonalDetail[index].answer = value;
     

        if (tempPersonalDetail[index]) {
            if (tempPersonalDetail[index].question) {
                tempPersonalDetail[index].question = updatedQuestion
            }
            if (tempPersonalDetail[index].additional_info) {
                tempPersonalDetail[index].additional_info = updatedAdditionalInfo
            }
            if (tempPersonalDetail[index].isAdditionalStructure && tempPersonalDetail[index].isAdditionalStructure.question) {
                tempPersonalDetail[index].isAdditionalStructure.question = updatedAdditionalQuestion
            }
        }
        setQuestionData({ ...tempQuestions, personalDetail: tempPersonalDetail });
    };


    const handleAdditionalChange = (index: number, value: any) => {
        let tempQuestions = { ...questions };
        let tempOtherInformation = [...tempQuestions.personalDetail];
        tempOtherInformation[index].isAdditionalStructure.answer = value;
        setQuestionData({ ...tempQuestions, personalDetail: tempOtherInformation });
    }




    useEffect(() => {
        setIsError(false)
    }, [])
    return (
        <Grid container spacing={3} className={classes.Report} sx={{ background: '#fff', borderRadius: '0.7rem' }}>

            {questions?.personalDetail && questions?.personalDetail?.map((ques: any, index: number) => (
                <>
                    {/* {handleRenderComponent(ques.type, index, ques.question, ques.answer, ques.option, "gp_detail", ques.isRequired,ques.question_number)}  */}

                    {<CommonField
                        type={ques.type}
                        index={index}
                        question={ques.question}
                        answer={ques.answer}
                        options={ques.option}
                        fileType={"personalDetail"}
                        required={ques.isRequired}
                        question_number={QuestionNumber}
                        questions={questions?.personalDetail}
                        isError={isError}
                        handleAnswerChange={handleAnswerChange}
                        ques={ques}
                        setIsDisableContinue={setIsDisableContinue}
                        totalQuestion={questions?.personalDetail?.length}
                        GridSize={6 > index ? 6 : 12}
                        isAdditional={ques?.isAdditional}
                        isAdditionalQuestion={ques?.isAdditionalStructure}
                        handleAdditionalChange={handleAdditionalChange}
                        patientName={patientName}
                    />}


                </>

            ))}
        </Grid>
    );
};

export default Consent;
