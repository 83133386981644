import React from 'react';
import {Box, Grid, Typography, Button } from '@mui/material';
import Modal from '../Modal';

const index = ({ open, handleClose, title, message, onConfirm, onCancel }:any) => {
  return (
    <Modal open={open} handleClose={handleClose} title={title}>
        <Grid sx={{ width: '100%', textAlign: 'center', py:3 }}>
          <Typography variant="body1">{message}</Typography>
        </Grid>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            gap: 2,
            position: 'sticky',
            bottom: 0,
            bgcolor: 'background.paper',
            p: 2
          }}
        >
 
 <Button sx={{ width: '20%',borderRadius:'2rem',minHeight:'35px' }} variant={"contained"} onClick={onConfirm}>Yes</Button>
          <Button sx={{ width: '20%',borderRadius:'2rem',minHeight:'35px' }} variant={"outlined"} onClick={onCancel}>No</Button>
        </Box>
    </Modal>
  );
};

export default index;
