import classes from "./index.module.scss";

const StatusStepper = ({ label, isComplete, checkMarkSrc, incompleteImageSrc }:any) => (
  <div  className={classes.statusLabel}>
    {label}
    <img
      src={isComplete ? checkMarkSrc : incompleteImageSrc}
      alt={isComplete ? 'CheckMark' : 'Incomplete'}
      className={classes.statusIcon}
    />
  </div>
);

export default StatusStepper;