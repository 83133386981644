export const VIEW_REPORT = "VIEW_REPORT"
export const EDIT_REPORT = "EDIT_REPORT"
export const ADD_EDU_REPORT = "ADD_EDU_REPORT"
export const REPORT_RESPONSE = "REPORT_RESPONSE"
export const GET_REPORT_CASELOAD_DETAIL = "GET_REPORT_CASELOAD_DETAIL"
export const REPORT_CASELOAD_RESPONSE = "REPORT_CASELOAD_RESPONSE"
export const GET_REPORT_ALL_DETAIL = "GET_REPORT_ALL_DETAIL"
export const GET_REPORT_ALL_RESPONSE = "GET_REPORT_ALL_RESPONSE"

export const GET_PATIENT_NAME = "GET_PATIENT_NAME"
