import React, { useEffect, useState } from 'react'
import { Grid } from '@mui/material'
import QuestionView from './questionView'
import SideStepper from "../sideStepper";
import classes from '../index.module.scss'
import { question } from '../question'
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from "reselect";
import { useParams } from 'react-router-dom';
import { viewAllReferralDetail } from '../../../../../redux/action';
import Pdf from '../../../../../components/atoms/Pdf';
import constant from '../../../../../constants/constant';
const ReportView = () => {
  const dispatch = useDispatch()
  const params = useParams()
  const [activeStep, setActiveStep] = useState(0);
  const [enableStep, setEnableStep] = useState(10);
  const [completeSteps, setCompleteSteps] = useState<any>(10);
  const referralReducer = (state: any) => state?.referralReducer

  const referralReducerData = createSelector(
    referralReducer,
    (state) => ({
      referralAllDetail: state?.referralAllDetail.data,
    })
  );
  const { referralAllDetail } = useSelector(referralReducerData);

  useEffect(() => {
    dispatch(viewAllReferralDetail({ ReferralId: params.id }))
  }, [])


  return (
    <Grid container spacing={4} className={classes.refContainer}>
      <Grid item md={3} sm={3} xs={12} className={classes.pt0}>
        <SideStepper activeStep={activeStep} setActiveStep={setActiveStep} completeSteps={completeSteps} enableStep={enableStep} />
      </Grid>
      <Grid item md={9} sm={9} xs={12} sx={{
        overflowY: 'auto',
        maxHeight: { xs: '', sm: '', md: '', lg: '', xl: '54rem' },
        '&::-webkit-scrollbar': { display: 'none' },
        scrollbarWidth: 'none', // Firefox
        msOverflowStyle: 'none', // IE and Edge

      }} className={classes.pt0}>

        <Grid >
        <Pdf data={referralAllDetail} title={constant.PATHWAY_STATUS.CASELOAD_CREATED} fname={referralAllDetail?.consent}></Pdf>
          {activeStep === 0 && <QuestionView question={referralAllDetail?.consent} type={"Consent"} tab={activeStep} />}
          {activeStep === 1 && <QuestionView question={referralAllDetail?.reason} type={"Reason"} tab={activeStep} />}
          {activeStep === 2 && <QuestionView question={referralAllDetail?.person_Detail} type={"Person Detail"} tab={activeStep} />}
          {activeStep === 3 && <QuestionView question={referralAllDetail?.gp_detail} type={"GP Detail"} tab={activeStep} />}
          {activeStep === 4 && <QuestionView question={referralAllDetail?.safe_guarding_detail} type={"Safe Guarding Detail"} tab={activeStep} />}
          {activeStep === 5 && <QuestionView question={referralAllDetail?.parent_carers_detail} type={"Parent Carers Detail"} tab={activeStep} />}
          {activeStep === 6 && <QuestionView question={referralAllDetail?.additional_parent_carers_details} type={"Additional parent carers detail"}
            tab={activeStep} />}
          {activeStep === 7 && <QuestionView question={referralAllDetail?.referrer_detail} type={"Referrer Detail"} tab={activeStep} />}
          {activeStep === 8 && <QuestionView question={referralAllDetail?.support_so_far} type={"Support So Far"} tab={activeStep} />}
          {activeStep === 9 && <QuestionView question={referralAllDetail?.digital_literacy} type={"Digital Literacy"} tab={activeStep} />}

        </Grid>
      </Grid>
    </Grid>
  )
}

export default ReportView