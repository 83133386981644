import { put, takeLatest } from 'redux-saga/effects';
import { ADD_EDU_REPORT, VIEW_REPORT, REPORT_RESPONSE, GET_REPORT_CASELOAD_DETAIL, REPORT_CASELOAD_RESPONSE,GET_REPORT_ALL_DETAIL, GET_REPORT_ALL_RESPONSE, EDIT_REPORT } from './actionType';
import { AxiosResponse } from 'axios';
import service from '../../api/services';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function* addReport({ payload, navigate }: any): Generator<any, AxiosResponse<any>, any> {
    try {
        const response: AxiosResponse<any> = yield service.report.add(payload);
        if (response.status === 201) {
            toast.success(response?.data?.message);
            navigate(`/manage-caseload/create?id=${payload.caseload_id}`);

        } else {
            toast.error(response?.data?.message);
        }
        return response;
    } catch (error: any) {
        toast.error(error?.response?.data?.message);
        return error;
    }
}


function* editReport({ id , payload,caseloadID, navigate }: any): Generator<any, AxiosResponse<any>, any> {
    const { caseload_id, ...updatedParentDetail } = payload;
    try {
        const response: AxiosResponse<any> = yield service.report.edit(id, updatedParentDetail);
        if (response.status === 201 || response.status === 200) {
            toast.success(response?.data?.message);
            navigate(`/manage-caseload/create?id=${caseloadID}`);

        } else {
            toast.error(response?.data?.message);
        }
        return response;
    } catch (error: any) {
        toast.error(error?.response?.data?.message);
        return error;
    }
}


function* getReport({ params }: any): Generator<any, AxiosResponse<any>, any> {
    try {
        const response: any = yield service.report.view(params);

        yield put({ type: REPORT_RESPONSE, payload: response?.data })
        if (response.status === 201) {
            // toast.success(response?.data?.message);
            // navigate("/report");

        } else {
            // toast.error(response?.data?.message);
        }
        return response;
    } catch (error: any) {
        toast.error(error?.response?.data?.message);
        return error;
    }
}
function* getAllReportDetail({ params }: any): Generator<any, AxiosResponse<any>, any> {
    try {
        const response: any = yield service.report.viewAllDetail(params);

        yield put({ type: GET_REPORT_ALL_RESPONSE, payload: response?.data })
        if (response.status === 201 || response.status === 200) {
            // toast.success(response?.data?.message);
            // navigate("/report");

        } else {
            toast.error(response?.data?.message);
        }
        return response;
    } catch (error: any) {
        toast.error(error?.response?.data?.message);
        return error;
    }
}

function* getDetailById({ paramsObj }: any): Generator<any, AxiosResponse<any>, any> {
    try {
        const response: any = yield service.report.getReportById(paramsObj);

        yield put({ type: REPORT_CASELOAD_RESPONSE, payload: response.data })
        if (response.status === 201 || response.status === 200) {
            // toast.success(response?.data?.message);
            // navigate("/report");

        } else {
            // toast.error(response?.data?.message);
        }
        return response;
    } catch (error: any) {
        // toast.error(error?.response?.data?.message);
        return error;
    }
}


function* reportSaga() {
    yield takeLatest(ADD_EDU_REPORT, addReport);
    yield takeLatest(EDIT_REPORT, editReport);
    yield takeLatest(VIEW_REPORT, getReport);
    yield takeLatest(GET_REPORT_CASELOAD_DETAIL, getDetailById);
    yield takeLatest(GET_REPORT_ALL_DETAIL, getAllReportDetail);
}

export default reportSaga;
