import { useEffect, useState } from 'react';

export const useMobile = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [isMobile, setIsMobile] = useState(false);
  const [deviceType, setDeviceType] = useState('');
  const [isSubmitAfter, setIsSubmitAfter] = useState(false);
  const [platform, setPlatform] = useState('Unknown');

  // Device detection logic
  const getPlatform = () => {
    const userAgent = navigator.userAgent || navigator.vendor ;
    
    if (/iPhone|iPad|iPod/.test(userAgent)) {
      return 'iOS';
    }
    if (/Macintosh/.test(userAgent) && /AppleWebKit/.test(userAgent) && !/Chrome/.test(userAgent) && !/CriOS/.test(userAgent)) {
      return 'iOS';
    }
    
    if (/Android/.test(userAgent)) {
      return 'Android';
    }
    
    return 'Unknown';
  };

  // UseEffect for setting up platform and mobile state
  useEffect(() => {
    setIsSubmitAfter(true);
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    // Check if the device is mobile and set device type
    const userAgent = navigator.userAgent;
    const isMobileDevice = /android|iphone/i.test(userAgent);
    setIsMobile(isMobileDevice);
    setPlatform(getPlatform());
    setDeviceType(getPlatform());

    // Add event listeners
    window.addEventListener('resize', handleResize);
    window.addEventListener('focus', () => {
      setIsSubmitAfter(true);
      setDeviceType(getPlatform()); // Ensure device type is updated on focus
    });

    // Cleanup event listeners on unmount
    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('focus', () => {
        setIsSubmitAfter(true);
        setDeviceType(getPlatform()); // Ensure device type is updated on focus
      });
    };
  }, []); // Empty dependency array to only run on mount/unmount


  const handleOpenApp = () => {
    if (platform === "iOS") {
      handleOpenAppApplePlay();
    } else if (platform === "Android") {
      handleOpenAppGooglePlay();
    } else {
      console.warn('Unsupported platform');
    }
  };

  const handleOpenAppGooglePlay = () => {
    const appDeepLink = "mycarebridge://";
    const playStoreUrl = "https://play.google.com/store/apps/details?id=com.providedigital.mycarebridge";
    const iframe = document.createElement("iframe");
    iframe.style.display = "none";
    iframe.src = appDeepLink;
    document.body.appendChild(iframe);
    let isAppOpened = false;

    setTimeout(() => {
      if (!isAppOpened) {
        window.location.href = playStoreUrl;
      }
    }, 2000);

    window.addEventListener("blur", () => {
      isAppOpened = true;
      iframe.remove();
    });
  };

  const handleOpenAppApplePlay = () => {
    const appDeepLink = "mycarebridge://";
    const appStoreUrl = "itms-appss://apps.apple.com/gb/app/mycarebridge/id6503237611";
    const link = document.createElement('a');
    link.href = appDeepLink;
    document.body.appendChild(link);
    let appOpened = false;
    link.click();
    document.body.removeChild(link);
    
    let timeoutDuration = 8000;
    const timer = setTimeout(() => {
      if (!appOpened) {
        window.location.href = appStoreUrl;
      }
    }, timeoutDuration);
    
    const handleVisibilityChange = () => {
      if (document.hidden) {
        appOpened = true;
        clearTimeout(timer);
        document.removeEventListener('visibilitychange', handleVisibilityChange);
      }
    };
    document.addEventListener('visibilitychange', handleVisibilityChange);
  
    const handleError = (event:any) => {
      if (event.message && event.message.includes('Safari cannot open the page because the address is invalid')) {
        clearTimeout(timer);
        window.location.href = appStoreUrl;
        window.removeEventListener('error', handleError);
      }
    };

    window.addEventListener('error', handleError);
  };


  return {
    windowWidth,
    isMobile,
    handleOpenApp,
    deviceType,
    isSubmitAfter
  };
};
