import { boolean } from 'yup'
import {
  ADD_TASK, ADD_TASK_FAILURE, RESPONSE_CASELOAD_BY_ID, ADD_TASK_SUCCESS, RESPONSE_PARENT_REPORT,RESPONSE_TASKDOC,
  RESPONSE_SCHOOL_REPORT, CASE_DOCUMENTS_BY_ID_FAILURE, CASE_DOCUMENTS_BY_ID_REQUEST, CASE_DOCUMENTS_BY_ID_SUCCESS, RESPONSE_CASELOAD, RESPONSE_CASHUSER, RESPONSE_TASK, UPLOAD_FILE, UPLOAD_FILE_FAILURE, UPLOAD_FILE_SUCCESS, CREATE_CONVERSATIONS_SUCCESS, CREATE_CONVERSATIONS, CREATE_CONVERSATIONS_FAILURE, RESPONSE_CHATMSG, RESPONSE_CHATCONVERTSATION, SUBMIT_TASK, SUBMIT_TASK_SUCCESS, SUBMIT_TASK_FAILURE, SET_LOADING, RESPONSE_CHATMSG_CLEAR
} from './actionType'

let initialValues:any = {
  caseloadData: [],
  caseloadByIdData: ''
}
let initialTaskList = {
  taskListdata: []
}

let initialaddTask = {
  initialaddTask: []
}


let initialCaseLoadUserList = {
  caseLoadUserListdata: []
}


let initialMsgList = {
  initialMsgList: [],
      loading: false
}

let initialConversationList = {
  initialConversationList: []
}

let initialParentReport = {
  report: []
}
let initialSchoolReport = {
  report: []
}

const initialState = {
  loading: false,
  error: null,
};


const initialDocState = {
  data: null,
  loading: false,
  error: null,
};



export const CaseloadReducer = (state = initialValues, action: any) => {

  switch (action.type) {

    case RESPONSE_CASELOAD:
      state = { ...state, caseloadData: action?.payload?.data?.ActiveReferrals};
      break;
    case RESPONSE_CASELOAD_BY_ID:
      state = { ...state, caseloadByIdData: action?.payload?.data?.data };
      break;

    default:
      state = { ...state };
      break;
  }
  return state;
}


export const TaskListReducer = (state = initialTaskList, action: any) => {

  switch (action.type) {

    case RESPONSE_TASK:
      state = { ...state, taskListdata: action?.payload?.data };
      break;

    default:
      state = { ...state };
      break;
  }
  return state;
}



//  Case Load User Reducer
export const CaseLoadUserReducer = (state = initialCaseLoadUserList, action: any) => {

  switch (action.type) {

    case RESPONSE_CASHUSER:
      state = { ...state, caseLoadUserListdata: action?.payload };
      break;

    default:
      state = { ...state };
      break;
  }
  return state;
}

export const taskDocReducer = (state = initialCaseLoadUserList, action: any) => {
 
  switch (action.type) {
     
      case RESPONSE_TASKDOC:
          state = { ...state, caseLoadUserListdata: action?.payload };
          break;
   
      default:
          state = { ...state };
          break;
  }
  return state;
}

export const chatMsgReducer = (state = initialMsgList, action: any) => {
   switch (action.type) {
    case RESPONSE_CHATMSG:
        return {
            ...state,
            initialMsgList: action.payload,
            loading: action.loading
        };
    case SET_LOADING:
        return {
            ...state,
            loading: action.loading
        };
        case RESPONSE_CHATMSG_CLEAR:
          return {
            ...state,
            initialMsgList: [],
            loading: action.loading
          } 
    default:
        return state;
}
}

export const chatConversationReducer = (state = initialConversationList, action: any) => {
 
  switch (action.type) {
     
      case RESPONSE_CHATCONVERTSATION:
          state = { ...state, initialConversationList: action?.payload };
          break;
          case RESPONSE_CHATMSG_CLEAR:
            return {
              ...state,
              initialConversationList: [],
            } 
      default:
          state = { ...state };
          break;
  }
  return state;
}




export const UploadDocuments = (state = initialState, action: any) => {
  switch (action.type) {
    case UPLOAD_FILE:
      return { ...state, loading: true };
    case UPLOAD_FILE_SUCCESS:
      return { ...state, loading: false };
    case UPLOAD_FILE_FAILURE:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};



export const caseDocumentReducer = (state = initialDocState, action: any) => {
  switch (action.type) {
    case CASE_DOCUMENTS_BY_ID_REQUEST:
      return { ...state, loading: true, error: null };
    case CASE_DOCUMENTS_BY_ID_SUCCESS:
      return { ...state, loading: false, data: action.payload, error: null };
    case CASE_DOCUMENTS_BY_ID_FAILURE:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};




export const createConversationsReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case CREATE_CONVERSATIONS:
      return { ...state, loading: true, error: null };
    case CREATE_CONVERSATIONS_SUCCESS:
      return { ...state, loading: false, data: action.payload, error: null };
    case CREATE_CONVERSATIONS_FAILURE:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

export const parentReducer = (state = initialParentReport, action: any) => {
  switch (action.type) {
    case RESPONSE_PARENT_REPORT:
      return { ...state, report: action.payload && action.payload[0] };
    default:
      return state;
  }
};

export const schoolReducer = (state = initialSchoolReport, action: any) => {
  switch (action.type) {
    case RESPONSE_SCHOOL_REPORT:
      return { ...state, report: action.payload && action.payload[0] };
    default:
      return state;
  }
}

