import { VIEW_REPORT,GET_REPORT_CASELOAD_DETAIL,GET_REPORT_ALL_DETAIL, GET_PATIENT_NAME, ADD_EDU_REPORT, EDIT_REPORT} from './actionType'
export const addReport = (payload: any, navigate: any) => {
    return {
        type: ADD_EDU_REPORT,
        payload,
        navigate
    }
}


export const EditReport = (id:string, payload: any,caseloadID:string, navigate: any) => {
    return {
        type: EDIT_REPORT,
        payload,
        id,
        caseloadID,
        navigate
    }
}

export const viewReport = (params:any) => {
    return {
        type: VIEW_REPORT,
        params
        
    }
}
export const currentPatient = (name:any) => {
    return {
        type: GET_PATIENT_NAME,
        payload:name,
        
    }
}
export const viewAllReportDetail = (params:any) => {
    return {
        type: GET_REPORT_ALL_DETAIL,
        params
        
    }
}
export const getReportCaseloadByID = (paramsObj: any) => {

    return {
        type: GET_REPORT_CASELOAD_DETAIL,
        paramsObj
    }
}