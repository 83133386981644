import React, { memo, useEffect, useState } from 'react';
import withPdf from '../../../components/atoms/WithPdf';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from "reselect";
import classes from "./index.module.scss";
import constant from '../../../constants/constant';
import { getParentReport, getSchoolReport } from '../../../redux/action';
import nodocument from '../../../assets/Images/nodocument.png'
import { useLocation, useParams } from 'react-router-dom';
import Pdf from '../../../components/atoms/Pdf';
import { Grid, Typography } from '@mui/material';
const ParentReducer = (state: any) => state?.parentReducer
const SchoolReducer = (state: any) => state?.schoolReducer



const Openpdf = () => {
    const [component, setComponent] = useState<any>(null);
    const dispatch = useDispatch()
    const location = useLocation()
    const pathParts = location.pathname.split('/').filter(Boolean);
    const param = pathParts[0]
    const refId = pathParts[1]
  

    useEffect(() => {
        if (refId) {
            if (param === 'open-parent-pdf') {
                dispatch(getParentReport({ caseload_id: refId }));
            } else if (param === 'open-education-pdf') {
                dispatch(getSchoolReport({ caseload_id: refId }));
            }
        }
    }, [refId, param]); 
    

    const ParentData = createSelector(
        ParentReducer,
        (state) => ({
            parentReport: state?.report?.data,
        })
    );

    const SchoolData = createSelector(
        SchoolReducer,
        (state) => ({
            schoolReport: state?.report?.data,
        })
    );

    const { parentReport } = useSelector(ParentData);
    const { schoolReport } = useSelector(SchoolData);


    useEffect(() => {
        if (param === 'open-parent-pdf') {
          setComponent(Pdf);
        } else if (param === 'open-education-pdf') {
          setComponent(Pdf);
        }
      }, [param]);
    
      if (!component) return null;

      const EnhancedComponent = withPdf(component);

    return (
        <div style={{display:"flex",alignItems:'center',justifyContent:'center',height:'100%'}}>
          {param === 'open-parent-pdf' && (
            (parentReport ? <EnhancedComponent
              data={parentReport}
              title={constant.PATHWAY_STATUS.PARENT_REPORT_RECEIVED}
              fname={'AUTOMATIC'}
              mode={'AUTOMATIC'}
            /> : <Grid className={'nodocumentImg'} style={{height:'100vh'}}>
            <Grid item xs={12} textAlign={'center'} className='innerheader'>
              <img src={nodocument} alt="No-Document" />
                <Typography variant='h5' >Parent Report Not Submitted</Typography>
            </Grid>
        </Grid>)
          )}
          {param === 'open-education-pdf' && (
            (schoolReport ? <EnhancedComponent
              data={schoolReport}
              title={constant.PATHWAY_STATUS.SCHOOL_REPORT_RECEIVED}
              fname={'AUTOMATIC'}
              mode={'AUTOMATIC'}
            /> : <Grid className={'nodocumentImg'} style={{height:'100vh'}}>
            <Grid item xs={12} textAlign={'center'} className='innerheader'>
              <img src={nodocument} alt="No-Document" />
                <Typography variant='h5' >Education Report Not Submitted</Typography>
            </Grid>
        </Grid>)
          )}
        </div>
      );
};

export default memo(Openpdf);