import { call, put, takeLatest } from 'redux-saga/effects';
import { ADD_TASK, ADD_TASK_FAILURE, ADD_TASK_SUCCESS, GET_SCHOOL_REPORT, GET_PARENT_REPORT, RESPONSE_PARENT_REPORT, RESPONSE_SCHOOL_REPORT, RESPONSE_CASHUSER, GET_CASELOAD_BY_ID, CASELOAD_LISTING, CASE_DOCUMENTS_BY_ID_FAILURE, CASE_DOCUMENTS_BY_ID_REQUEST, CASE_DOCUMENTS_BY_ID_SUCCESS, CREATE_CASELOAD, RESPONSE_TASK, TASK_LISTING, UPLOAD_FILE, CASEUSER_LISTING, RESPONSE_CASELOAD_BY_ID, RESPONSE_TASKDOC, SUBMIT_TASK, TASK_DOC, CREATE_CONVERSATIONS, CREATE_CONVERSATIONS_SUCCESS, CREATE_CONVERSATIONS_FAILURE, SENT_MESSAGE, CHATMSG_LISTING, RESPONSE_CHATMSG, RESPONSE_CHATCONVERTSATION, CHATCONVERTSATION_LISTING, UPDATE_TASK } from './actionType';
import { AxiosResponse } from 'axios';
import service from '../../api/services';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { setLoading, uploadFileFailure, uploadFileSuccess } from './action';
import { RESOURCES_DOCUMENT, RES_RESOURCES_DOCUMENT } from '../resources/actionType';


// import {  } from "./action"

function* createCaseload({ data, navigate }: any): Generator<any, AxiosResponse<any>, any> {
    try {
        const response: AxiosResponse<any> = yield service.Caseload.add(data);
        if (response.status === 201 || response.status === 200) {
            toast.success(response?.data?.message);
            // if (param === "admin") {
            navigate("/manage-caseload/active")
            // } else {
            //     navigate("/user-management/clinician")
            // }
            // ATBB8uG76GR3wAg4UG3hEfteEgRJ2AE3DA98
        } else {
            toast.error(response?.data?.message);
        }
        return response;
    } catch (error: any) {
        toast.error(error?.response?.data?.message);
        return error;
    }
}
function* getCaseloadDetailById({ id }: any): Generator<any, AxiosResponse<any>, any> {
    try {
        const response: AxiosResponse<any> = yield service.Caseload.getDetailByID(id);
        if (response.status === 201 || response.status === 200) {
            yield put({ type: RESPONSE_CASELOAD_BY_ID, payload: response })

        } else {
            toast.error(response?.data?.message);
        }
        return response;
    } catch (error: any) {
        toast.error(error?.response?.data?.message);
        return error;
    }
}

function* getParentReportById({ params }: any): Generator<any, AxiosResponse<any>, any> {
    try {
        const response: AxiosResponse<any> = yield service.Caseload.getParentReport(params);
        if (response.status === 201 || response.status === 200) {
            yield put({ type: RESPONSE_PARENT_REPORT, payload: response?.data?.data?.parentReport })

        } else {
            toast.error(response?.data?.message);
        }
        return response;
    } catch (error: any) {
        toast.error(error?.response?.data?.message);
        return error;
    }
}
function* getSchoolReportById({ params }: any): Generator<any, AxiosResponse<any>, any> {
    try {
        const response: AxiosResponse<any> = yield service.Caseload.getSchoolReport(params);
        if (response.status === 201 || response.status === 200) {

            yield put({ type: RESPONSE_SCHOOL_REPORT, payload: response?.data?.data?.schoolReport })

        } else {
            toast.error(response?.data?.message);
        }
        return response;
    } catch (error: any) {
        toast.error(error?.response?.data?.message);
        return error;
    }
}


// function* updateAdminAccount({ id,questionData, navigate, param }: any): Generator<any, AxiosResponse<any>, any> {
//     try {
//         const response: AxiosResponse<any> = yield service.User_Management.update(id,questionData);
//         if (response.status === 200 || response.status === 201) {
//             toast.success(response?.data?.message);
//             if (param === "admin") {
//                 navigate("/user-management/admin")
//             } else {
//                 navigate("/user-management/clinician")
//             }

//         } else {
//             toast.error(response?.data?.message);
//         }
//         return response;
//     } catch (error: any) {
//         toast.error(error?.response?.data?.message);
//         return error;
//     }
// }
function* getCaseloadList({ headers }: any): Generator<any, AxiosResponse<any>, any> {
    try {
        const response: any = yield service.Caseload.view(headers);

        if (response.status === 201 || response.status === 200) {
            yield put({ type: "RESPONSE_CASELOAD", payload: response.data })
            // toast.success(response?.data?.message);
            // navigate("/referral");

        } else {
            toast.error(response?.data?.message);
        }
        return response;
    } catch (error: any) {
        toast.error(error?.response?.data?.message);
        return error;
    }
}




function* createTask({ id, data }: any): Generator<any, AxiosResponse<any>, any> {
    try {
        const response: AxiosResponse<any> = yield service.Task_Management.add(data);
        if (response.status === 201 || response.status === 200) {
            toast.success(response?.data?.message);
            yield put({ type: ADD_TASK_SUCCESS, payload: response.data })
            let params = {
                page: 1,
                search: '',
                limit: 10,
                caseload_id: id // Assuming caseload_id is a variable that holds the desired value
            };
            // yield new Promise(resolve => setTimeout(resolve, 2000));
            yield call(getTaskList, { params });
            yield call(getCaseloadDetailById, { id });
        } else {
            toast.error(response?.data?.message);
        }
        return response;
    } catch (error: any) {
        toast.error(error?.response?.data?.message);
        yield put({ type: ADD_TASK_FAILURE, payload: error })
        return error;
    }
}


function* updateTask({ id, data }: any): Generator<any, AxiosResponse<any>, any> {
    try {
        const response: AxiosResponse<any> = yield service.Task_Management.update(id, data);
        if (response.status === 201 || response.status === 200) {
            toast.success(response?.data?.message);
            yield put({ type: ADD_TASK_SUCCESS, payload: response.data })
            let params = {
                page: 1,
                search: '',
                limit: 10,
                caseload_id: data.caseload_id // Assuming caseload_id is a variable that holds the desired value
            };
            // yield new Promise(resolve => setTimeout(resolve, 2000));
            yield call(getTaskList, { params });
        } else {
            toast.error(response?.data?.message);
        }
        return response;
    } catch (error: any) {
        toast.error(error?.response?.data?.message);
        yield put({ type: ADD_TASK_FAILURE, payload: error })
        return error;
    }
}



function* submitTask({ id, data, caseload_id }: any): Generator<any, AxiosResponse<any>, any> {
    try {
        const response: AxiosResponse<any> = yield service.Task_Management.postById(id, data);
        if (response.status === 201 || response.status === 200) {
            // yield put({ type: SUBMIT_TASK_SUCCESS, payload: response.data })
            toast.success(response?.data?.message);
            let params = {
                page: 1,
                search: '',
                limit: 10,
                caseload_id: caseload_id // Assuming caseload_id is a variable that holds the desired value
            };
            // yield new Promise(resolve => setTimeout(resolve, 2000));
            yield call(getTaskList, { params });
        } else {
            toast.error(response?.data?.message);
        }
        return response;
    } catch (error: any) {
        toast.error(error?.response?.data?.message);
        // yield put({ type: SUBMIT_TASK_FAILURE, payload: error })
        return error;
    }
}

function* submitConversations({ id, data }: any): Generator<any, AxiosResponse<any>, any> {
    try {
        const response: AxiosResponse<any> = yield service.Chat_Management.add(data);
        if (response.status === 201 || response.status === 200) {
            toast.success(response?.data?.message);
            yield put({ type: CREATE_CONVERSATIONS_SUCCESS, payload: response.data });
            yield call(getChatConversation, { id });
        } else {
            toast.error(response?.data?.message);
        }
        return response;
    } catch (error: any) {
        toast.error(error?.response?.data?.message);
        yield put({ type: CREATE_CONVERSATIONS_FAILURE, payload: error })
        return error;
    }
}

function* getResourcesDoc({ params }: any): Generator<any, AxiosResponse<any>, any> {
    try {
        const response: any = yield service.Resources_Management.getDocById(params);

        if (response.status === 201 || response.status === 200) {
            yield put({ type: RES_RESOURCES_DOCUMENT, payload: response?.data })

        } else {
            // toast.error(response?.data?.message);
        }
        return response;
    } catch (error: any) {
        // toast.error(error?.response?.data?.message);
        return error;
    }
}


function* sendMessagefun({ data }: any): Generator<any, AxiosResponse<any>, any> {
    try {
        const response: AxiosResponse<any> = yield service.Chat_Management.sentmsg(data);

        if (response.status === 201 || response.status === 200) {
            // Display success message
            // toast.success(response?.data?.message);

            const conversationID = data?.conversationId;
            if (conversationID) {
                // Use call effect to call another saga
                yield call(getChatMessages, { id: conversationID });
            }
        } else {
            // Display error message
            toast.error(response?.data?.message);
        }

        return response;
    } catch (error: any) {
        // Display error message
        toast.error(error?.response?.data?.message);
        return error;
    }
}






function* getChatMessages({ id, loading }: any): Generator<any, AxiosResponse<any>, any> {
    yield put(setLoading(true));
    try {
        const response: any = yield service.Chat_Management.getmsg(id);
        if (response.status === 201 || response.status === 200) {
            yield put({ type: RESPONSE_CHATMSG, payload: response.data?.data });
            yield put(setLoading(false));
        } else {
            toast.error(response?.data?.message);
        }
        return response;
    } catch (error: any) {
        toast.error(error?.response?.data?.message);
        return error;
    }
}

function* getChatConversation({ id }: any): Generator<any, AxiosResponse<any>, any> {
    try {
        const response: any = yield service.Chat_Management.getmsgConversation(id);
        if (response.status === 201 || response.status === 200) {
            yield put({ type: RESPONSE_CHATCONVERTSATION, payload: response?.data });
            let [firstMessage] = response?.data || [];
            let getmessageId = firstMessage?.id;
            if (getmessageId) {
                yield call(getChatMessages, { id: getmessageId });
            }
           
        } else {
            toast.error(response?.data?.message);
        }
        return response;
    } catch (error: any) {
        toast.error(error?.response?.data?.message);
        return error;
    }
}




function* getTaskList({ params }: any): Generator<any, AxiosResponse<any>, any> {
    try {
        const response: any = yield service.Task_Management.view(params);

        if (response.status === 201 || response.status === 200) {
            yield put({ type: RESPONSE_TASK, payload: response?.data })
            // toast.success(response?.data?.message);
            // navigate("/referral");
            if(params.caseload_id){
                const id = params.caseload_id;
                yield call(getCaseloadDetailById, { id })
            } 
        } else {
            toast.error(response?.data?.message);
        }
        return response;
    } catch (error: any) {
        toast.error(error?.response?.data?.message);
        return error;
    }
}

function* getCaseUserList({ id }: any): Generator<any, AxiosResponse<any>, any> {
    try {
        const response: any = yield service.Task_Management.getById(id);
        if (response.status === 201 || response.status === 200) {
            yield put({ type: RESPONSE_CASHUSER, payload: response.data?.data })
            // toast.success(response?.data?.message);
            // navigate("/referral");

        } else {
            toast.error(response?.data?.message);
        }
        return response;
    } catch (error: any) {
        toast.error(error?.response?.data?.message);
        return error;
    }
}


// function* getUserList({ headers }: any): Generator<any, AxiosResponse<any>, any> {

//     try {
//         const response: any = yield service.User_Management.viewUser(headers);

//         yield put(userResponse(response.data))
//         if (response.status === 201) {
//             // toast.success(response?.data?.message);
//             // navigate("/referral");

//         } else {
//             // toast.error(response?.data?.message);
//         }
//         return response;
//     } catch (error: any) {
//         toast.error(error?.response?.data?.message);
//         return error;
//     }
// }

// function* getDetailById({  id}: any): Generator<any, AxiosResponse<any>, any> {
//     try {
//         const response: any = yield service.User_Management.getUserById(id);

//         yield put(getUserDetail(response.data))
//         if (response.status === 201) {
//             // toast.success(response?.data?.message);
//             // navigate("/referral");

//         } else {
//             // toast.error(response?.data?.message);
//         }
//         return response;
//     } catch (error: any) {
//         toast.error(error?.response?.data?.message);
//         return error;
//     }
// }



function* uploadFileSaga({ data, navigate }: any): Generator<any, AxiosResponse<any>, any> {
    try {
        const response: AxiosResponse<any> = yield service.Upload_Documents.add(data);
        if (response.status === 201 || response.status === 200) {
            yield put(uploadFileSuccess());
            const caseloadId = response?.data?.caseload_id;
            if (caseloadId) {
                yield getUploadList({ id: caseloadId, navigate });
            }
            toast.success(`${response?.data?.document_type} Uploaded Successfully...`);
        } else {
            toast.error(response?.data?.message);
        }
        return response;
    } catch (error: any) {
        yield put(uploadFileFailure(error));
        toast.error(error?.response?.data?.message);
        return error;
    }
}

function* getUploadList({ id, navigate }: any): Generator<any, AxiosResponse<any>, any> {
    try {
        const response: AxiosResponse<any> = yield service.Upload_Documents.getById(id);
        if (response.status === 200) { // Adjusted status check if needed
            yield put({ type: CASE_DOCUMENTS_BY_ID_SUCCESS, payload: response.data });
            // Navigate if needed
            // navigate(`/manage-caseload/create/${id}`);
        } else {
            toast.error(response?.data?.message);
        }
        return response;
    } catch (error: any) {
        yield put({ type: CASE_DOCUMENTS_BY_ID_FAILURE, payload: error });
        toast.error(error?.response?.data?.message);
        return error;
    }
}





function* getTaskDocList({ id }: any): Generator<any, AxiosResponse<any>, any> {
    try {
        const response: any = yield service.Task_Management.getTaskDocById(id);
        if (response.status === 201 || response.status === 200) {
            yield put({ type: RESPONSE_TASKDOC, payload: response.data?.data });
        } else {
            toast.error(response?.data?.message);
        }
        return response;
    } catch (error: any) {
        toast.error(error?.response?.data?.message);
        return error;
    }
}


function* referralSaga() {
    // yield takeLatest(ADD_ADMIN_ACCOUNT, addAdminAccount);
    // yield takeLatest(VIEW_MODULES, getModule);
    yield takeLatest(CASELOAD_LISTING, getCaseloadList);
    yield takeLatest(CREATE_CASELOAD, createCaseload);
    yield takeLatest(SUBMIT_TASK, submitTask);
    yield takeLatest(UPLOAD_FILE, uploadFileSaga);
    yield takeLatest(CASE_DOCUMENTS_BY_ID_REQUEST, getUploadList);
    yield takeLatest(ADD_TASK, createTask);
    yield takeLatest(UPDATE_TASK, updateTask);
    yield takeLatest(TASK_LISTING, getTaskList);
    yield takeLatest(CASEUSER_LISTING, getCaseUserList);
    yield takeLatest(TASK_DOC, getTaskDocList);
    yield takeLatest(CHATMSG_LISTING, getChatMessages);
    yield takeLatest(CHATCONVERTSATION_LISTING, getChatConversation);
    yield takeLatest(GET_CASELOAD_BY_ID, getCaseloadDetailById);
    yield takeLatest(GET_PARENT_REPORT, getParentReportById);
    yield takeLatest(RESOURCES_DOCUMENT, getResourcesDoc)
    yield takeLatest(GET_SCHOOL_REPORT, getSchoolReportById);
    yield takeLatest(CREATE_CONVERSATIONS, submitConversations);
    yield takeLatest(SENT_MESSAGE, sendMessagefun);







    // yield takeLatest(GET_USER_DETAIL, getDetailById);
    // yield takeLatest(UPDATE_USER_ACCOUNT, updateAdminAccount);
}

export default referralSaga;
