export const CHANGE_TAB = "CHANGE_TAB"
export const CURRENT_TAB = "CURRENT_TAB"




export const SET_SELECTED_TAB = 'SET_SELECTED_TAB';
export const SET_DOCUMENT_TAB = 'SET_DOCUMENT_TAB';
export const SET_TASK_TAB = 'SET_TASK_TAB';
export const SET_SELECTED_USER = 'SET_SELECTED_USER';
export const SET_CONVERSATION_ID = 'SET_CONVERSATION_ID';
export const SET_USER_LIST_ACTIVE = 'SET_USER_LIST_ACTIVE';
export const REFFERAL_CLOSED = "REFFERAL_CLOSED"
export const CASELOAD_CURRENT_TAB = "CASELOAD_CURRENT_TAB"

