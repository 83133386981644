import { BASE_URL } from '../../constants/defaultValues'
import Dictionary from '../dictionary'
import axios from 'axios'

const { auth } = Dictionary

const authentication = {
  login: async (data: {}, headers: any) => {
    return axios.post(BASE_URL + auth.login(), data, { headers: headers })
  },
  otp: async (data: {}, headers: any) => {
    return axios.post(BASE_URL + auth.otp(), data, { headers: headers })
  }, 
  verifyLink: async (data: {}, headers: any) => {
    return axios.post(BASE_URL + auth.verifyLink(), data, { headers: headers })
  },
  forgotPasssword: async (data: {}, headers: any) => {
    return axios.post(BASE_URL + auth.forgotPasssword(), data, {
      headers: headers,
    })
  },
  setPassword: async (data: {}, headers: any) => {
    return axios.post(BASE_URL + auth.setPassword(), data, { headers: headers })
  },
  logout: async () => {
    return axios.get(BASE_URL + auth.logout())
  },
  changePassword: async (data: {}) => {
    return axios.post(BASE_URL + auth.changePassword(), data)
  },
}
export default authentication
