import { Card } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { styled, alpha, Box, Button, Grid, Paper, IconButton, MenuItem, Menu, ListItemIcon, Typography, Tooltip } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import InputBase from '@mui/material/InputBase';
import userCss from './index.module.scss';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import Table from '../../../components/atoms/Table'
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
// import { getUserList } from '../../../redux/action';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from "reselect";
import CONSTANT from '../../../constants/constant';
import moment, { min } from 'moment';
import EditIcon from '@mui/icons-material/Edit';
import FilterAltIcon from '@mui/icons-material/FilterAlt';

import Search from "../../../components/atoms/Search"
import PendingOutlinedIcon from '@mui/icons-material/PendingOutlined';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import classes from './index.module.scss'
const Listing = () => {
    const location = useLocation()
    const Navigate = useNavigate()
    const dispatch = useDispatch()
    const pathParts = location.pathname.split('/').filter(Boolean);
    const param = pathParts[1]
    const [page, setPage] = useState(1)
    const [search, setSearch] = useState("")
    const [limit, setLimit] = useState(10)
    const [anchorEl, setAnchorEl] = useState(null);
    const [columns, setColumns] = useState([])
    const [tableColumns, setTableColumns] = useState<any>({
        "clinician": [
            {
                name: '#',
                cell: (item: any, ind: number) => <span>{ind + 1}</span>,
                width: '50px'

            },
            {
                name: 'Name',
                cell: (item: any, ind: number) => <Tooltip title={item.name} className={classes.text_wrap}>{item.name}</Tooltip>,
                selector: (item: any, ind: number) => item.first_name,
                draggable: false,
                sortable: true,
                minWidth: "18%"
            },
            {
                name: 'Email',
                cell: (item: any, ind: number) => <Tooltip title={item.email} className={classes.text_wrap}>{item.email}</Tooltip>,
                selector: (item: any, ind: number) => item.email,
                sortable: true,
                minWidth: "18%"
            },
            {
                name: 'Job Role',
                cell: (item: any, ind: number) => <span>{item?.jobRole}</span>,
                sortable: false
            },
            {
                name: 'Registration',
                cell: (item: any, ind: number) => <span>{moment(item.createdAt).format('DD/MM/YYYY')}</span>,
                sortable: false
            },
            {
                name: 'Account Status',
                cell: (item: any, ind: number) => <span>{item.status}</span>,
                sortable: false,

            },
            {
                name: 'action',
                cell: (item: any, ind: number) => <span>{item.action}</span>,
                sortable: false,
            }
        ],
        "admin": [
            {
                name: '#',
                cell: (item: any, ind: number) => <span>{ind + 1}</span>,
                width: '50px'

            },
            {
                name: 'Name',
                cell: (item: any, ind: number) => <Tooltip title={item.name} className={classes.text_wrap}>{item.name}</Tooltip>,
                selector: (item: any, ind: number) => item.first_name,
                draggable: false,
                sortable: true,
                minWidth: "18%"
            },
            {
                name: 'Email',
                cell: (item: any, ind: number) => <Tooltip title={item.email} className={classes.text_wrap}>{item.email}</Tooltip>,
                selector: (item: any, ind: number) => item.email,
                sortable: true,
                minWidth: "18%"
            },
            {
                name: 'Registration',
                cell: (item: any, ind: number) => <span>{moment(item.createdAt).format('DD/MM/YYYY')}</span>,
                sortable: false
            },
            {
                name: 'Account Status',
                cell: (item: any, ind: number) => <span>{item.status}</span>,
                sortable: false,

            },
            {
                name: 'action',
                cell: (item: any, ind: number) => <span>{item.action}</span>,
                sortable: false,
            }
        ],
        "parent": [
            {
                name: '#',
                cell: (item: any, ind: number) => <span>{ind + 1}</span>,
                width: '50px'
            },
            {
                name: 'Name',
                cell: (item: any, ind: number) => <Tooltip title={item.name} className={classes.text_wrap}>{item.name}</Tooltip>,
                selector: (item: any, ind: number) => item.first_name,
                draggable: false,
                sortable: true,
                minWidth: "14%"
            },
            {
                name: 'Email',
                cell: (item: any, ind: number) => <Tooltip title={item.email} className={classes.text_wrap} >{item.email}</Tooltip>,
                selector: (item: any, ind: number) => item.email,
                sortable: true,
                minWidth: "15%"
            },
            {
                name: 'Role',
                cell: (item: any, ind: number) => item.role,
                selector: (item: any, ind: number) => item.role,
                sortable: true,
                minWidth: "10%"
            },
            {
                name: 'Caseload',
                cell: (item: any, ind: number) => item.active_caseload,
                minWidth: "10%"
            },
            {
                name: 'Caseload ID',
                cell: (item: any, ind: number) => item.caseload_id,
                minWidth: "12%"
            },
            {
                name: 'Registration',
                cell: (item: any, ind: number) => <span>{moment(item.createdAt).format('DD/MM/YYYY')}</span>,
                sortable: false,
                minWidth: "13%"
            },
            {
                name: 'Account Status',
                cell: (item: any, ind: number) => <span>{item.status}</span>,
                sortable: false,
                minWidth: "12%"
            },
            {
                name: 'action',
                cell: (item: any, ind: number) => <span>{item.action}</span>,
                sortable: false,
            }
        ],
        "school": [
            {
                name: '#',
                cell: (item: any, ind: number) => <span>{ind + 1}</span>,
                width: '50px'

            },
            {
                name: 'School Name',
                cell: (item: any, ind: number) => <Tooltip title={item.school_name} className={classes.text_wrap}>{item.school_name}</Tooltip>,
                selector: (item: any, ind: number) => item.school_name,
                draggable: false,
                sortable: true,
                minWidth: "15%"
            },
            {
                name: 'Name',
                cell: (item: any, ind: number) => <Tooltip title={item.name} className={classes.text_wrap}>{item.name}</Tooltip>,
                selector: (item: any, ind: number) => item.first_name,
                draggable: false,
                sortable: true,
                minWidth: "13%"
            },
            {
                name: 'Email',
                cell: (item: any, ind: number) => <Tooltip title={item.email} className={classes.text_wrap}>{item.email}</Tooltip>,
                selector: (item: any, ind: number) => item.email,
                sortable: true,
                minWidth: "13%"
            },
            {
                name: 'phone',
                cell: (item: any, ind: number) => item.mobile,
                selector: (item: any, ind: number) => item.mobile,
                sortable: true,
                minWidth: "10%"
            },
            {
                name: 'Postcode',
                cell: (item: any, ind: number) => item.post_code,
                minWidth: "11%"
            },
            {
                name: 'Registration',
                cell: (item: any, ind: number) => <span>{moment(item.createdAt).format('DD/MM/YYYY')}</span>,
                sortable: false,
                minWidth: "11%"
            },
            {
                name: 'Status',
                cell: (item: any, ind: number) => <span>{item.status}</span>,
                sortable: false,
                minWidth: "11%"

            },
            {
                name: 'action',
                cell: (item: any, ind: number) => <span>{item.action}</span>,
                sortable: false,
                justifyContent: 'end'
            }
        ]
    })
    const scopeMap: any = {
        "admin": 'Add Admin',
        "clinician": 'Add Clinician',
        "school": 'Add School',
        "parent": 'Add Parent'
    };
    const [selectedUserId, setSelectedUserId] = useState<string | null>(null);
    const userReducer = (state: any) => state?.userManagementReducer
    const addUrlMap: any = {
        "admin": "/user-management/admin/add",
        "clinician": "/user-management/clinician/add",
        "parent": "/user-management/parent/add",
        "school": "/user-management/school/add"
    };

    const addUrl = addUrlMap[param] || '';

    const userData = createSelector(
        userReducer,
        (state) => ({
            userList: state.userData,

        })
    );
    const { userList } = useSelector(userData);

    // useEffect(() => {
    //     const scopeMap: any = {
    //         "admin": CONSTANT.SCOPE.CLIENT_SUB_ADMIN,
    //         "clinician": CONSTANT.SCOPE.CLINICIAN,
    //         "school": CONSTANT.SCOPE.SCHOOL,
    //         "parent": CONSTANT.SCOPE.PARENT
    //     };
    //     const scope: any = scopeMap[param];
    //     const addColumn: any = tableColumns[param] || [];
    //     setColumns(addColumn)
    //     if (scope) {
    //         dispatch(getUserList({ scope, page, search, limit }));
    //     }
    // }, [param, page, search, limit])

    const handleClick = (event: any, id: string) => {
        setAnchorEl(event.currentTarget);
        setSelectedUserId(id)
    };


    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleNavigate = () => {
        const urlMap: any = {
            "admin": `/user-management/admin/edit/${selectedUserId}`,
            "clinician": `/user-management/clinician/edit/${selectedUserId}`,
            "parent": `/user-management/parent/edit/${selectedUserId}`,
            "school": `/user-management/school/edit/${selectedUserId}`
        };
        const url: any = urlMap[param] || '';
        Navigate(url);

    }

    return (
        <>
            <Box>
            </Box>
            <Box className={userCss.innerheader}>
                <Grid container direction="row" justifyContent="space-between">
                    <Grid item xs={6} className={userCss.searchInputbx}>
                        <Search searchCallBack={(e: string) => setSearch(e)} />
                    </Grid>
                    <Grid item xs={6} display={"flex"} gap={2} justifyContent={"end"}>
                        <IconButton
                            size="large"
                            color='primary'
                            className={userCss.iconStyle}
                            sx={{ border: `1px solid #6A2382` }}
                        >
                            <FileDownloadOutlinedIcon fontSize="medium" />
                        </IconButton>

                        <IconButton
                            size="large"
                            className={userCss.iconStyle}
                            color='primary'
                            sx={{ border: `1px solid #6A2382` }}
                        >
                            <FilterAltIcon fontSize="medium" />
                        </IconButton>
                        <Button className={userCss.btnStyle}
                            onClick={() => Navigate(addUrl)}
                            size="large" variant="contained" sx={{ borderRadius: '30px' }} startIcon={<AddCircleIcon />}>
                            {scopeMap[param]}
                        </Button>
                    </Grid>
                </Grid>
            </Box>

            <Grid className={userCss.tableWrapper} >
                <Table columns={columns}
                    data={userList?.user && userList?.user?.map((item: any, index: number) => {
                        return ({
                            ...item,
                            name: item.first_name + ' ' + item.last_name,
                            status: item.status === CONSTANT.STATUS.ACTIVE ? (
                                <Typography className={userCss.active}>Active</Typography>
                            ) : (
                                <Typography className={userCss.deactive}>Deactive</Typography>
                            ),
                            action: <div key={index}>

                                <IconButton
                                    aria-controls={`item-menu-${item.id}`}
                                    aria-haspopup="true"
                                    onClick={(e) => handleClick(e, item.id)}
                                    size="large"
                                    className={userCss.iconStyleAction}
                                    color='primary'
                                    sx={{ border: `1px solid #6A2382` }}
                                >
                                    <MoreHorizIcon fontSize="medium" />
                                </IconButton>
                                <Menu
                                    id={`item-menu-${item.id}`}
                                    anchorEl={anchorEl}
                                    keepMounted
                                    open={Boolean(anchorEl)}
                                    onClose={handleClose}
                                    anchorOrigin={{
                                        vertical: 'top',
                                        horizontal: 'left',
                                    }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}

                                >
                                    <MenuItem
                                        onClick={() => handleNavigate()}>

                                        Edit
                                    </MenuItem>
                                    <MenuItem className={item.status === CONSTANT.STATUS.ACTIVE ? userCss.active : userCss.deactive}
                                    >

                                        {item.status === CONSTANT.STATUS.ACTIVE ? 'Active' : 'Deactive'}
                                    </MenuItem>
                                    <MenuItem
                                    >

                                        Resend Password
                                    </MenuItem>
                                </Menu>
                            </div>
                        })

                    }
                    )}
                    paginationTotalRows={userList.totalPages}
                    pagination={true}
                    setCurrentPage={(e: any) => setPage(e)} />
            </Grid>
        </>
    )
}

export default Listing


