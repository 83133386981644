import React, { useEffect, useState } from 'react';
import {
    Card,
    Grid,
    TextField,
    Typography,
    Radio,
    RadioGroup,
    FormControlLabel,
    FormControl,
    FormLabel,
    FormGroup,
    MenuItem,
    InputLabel,
    Select
} from '@mui/material';
import classes from "./index.module.scss"
import CommonField from './CommonField';


function OtherInformation({ patientName, questions, setQuestionData, isError, setIsError, setIsDisableContinue }: any) {

    let QuestionNumber = questions.personalDetail.length + questions.communication.length +
        questions.socialInteraction.length + questions.creativityImagination.length
        + questions.behaviour.length + questions.sensory.length + questions.attentionActivityLevels.length

    const handleAnswerChange = (index: number, value: any, key: string, updatedQuestion: string, updatedAdditionalInfo: string, updatedAdditionalQuestion: string, updatedIsHeading: string) => {
        let tempQuestions = { ...questions };
        let tempOtherInformation = [...tempQuestions.otherRelevantInformation];
        tempOtherInformation[index].answer = value;

        if (tempOtherInformation[index]) {
            if (tempOtherInformation[index].question) {
                tempOtherInformation[index].question = updatedQuestion
            }
            if (tempOtherInformation[index].additional_info) {
                tempOtherInformation[index].additional_info = updatedAdditionalInfo
            }
            if (tempOtherInformation[index].isAdditionalStructure && tempOtherInformation[index].isAdditionalStructure.question) {
                tempOtherInformation[index].isAdditionalStructure.question = updatedAdditionalQuestion
            }
        }

        const question_id = tempOtherInformation[index].question_id;

        if ([ "ques71","ques72", "ques73"].includes(question_id) && value === 'No') {
            tempOtherInformation[index].isAdditionalStructure.isRequired = false;
            tempOtherInformation[index].isAdditionalStructure.answer = '';
        } else if (["ques71","ques72", "ques73"].includes(question_id) && value === 'Yes') {
            tempOtherInformation[index].isAdditionalStructure.isRequired = true;
            tempOtherInformation[index].isAdditionalStructure.answer = '';
        }


        setQuestionData({ ...tempQuestions, otherRelevantInformation: tempOtherInformation });
    };

    const handleAdditionalChange = (index: number, value: any) => {
        let tempQuestions = { ...questions };
        let tempOtherInformation = [...tempQuestions.otherRelevantInformation];
        tempOtherInformation[index].isAdditionalStructure.answer = value;
        setQuestionData({ ...tempQuestions, otherRelevantInformation: tempOtherInformation });
    }



    useEffect(() => {
        setIsError(false)
    }, [])

    return (
        <>
            <Grid container direction="column" spacing={2} className={classes.Report}>
                {questions?.otherRelevantInformation && questions?.otherRelevantInformation?.map((ques: any, index: number) => (
                    <>
                        {/* {handleRenderComponent(ques.type, index, ques.question, ques.answer, ques.option, "parent_carers_detail", ques.isRequired)} */}
                        {<CommonField
                            type={ques.type}
                            index={index}
                            question={ques.question}
                            answer={ques.answer}
                            options={ques.option}
                            fileType={"otherRelevantInformation"}
                            required={ques.isRequired}
                            question_number={QuestionNumber}
                            questions={questions?.otherRelevantInformation}
                            isError={isError}
                            handleAnswerChange={handleAnswerChange}
                            ques={ques}
                            setIsDisableContinue={setIsDisableContinue}
                            totalQuestion={questions?.otherRelevantInformation?.length}
                            isAdditional={ques?.isAdditional}
                            isAdditionalQuestion={ques?.isAdditionalStructure}
                            handleAdditionalChange={handleAdditionalChange}
                            patientName={patientName}
                        />}

                        {/* {isError && ques.answer === "" && ques.isRequired && <p className={classes.errorColor}>This field is required</p>} */}
                    </>
                ))}
            </Grid>
        </>
    );
}

export default OtherInformation;
