import { put, takeLatest } from 'redux-saga/effects';
import { ADD_REFERRAL, VIEW_REFERRAL, REFERRAL_RESPONSE,GET_GP_LIST ,GP_LIST_RESPONSE,GET_REFERRAL_CASELOAD_DETAIL, REFERRAL_CASELOAD_RESPONSE,GET_REFERRAL_ALL_DETAIL, GET_REFERRAL_ALL_RESPONSE, EDIT_REFERRAL } from './actionType';
import { AxiosResponse } from 'axios';
import service from '../../api/services';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function* addReferral({ payload, navigate }: any): Generator<any, AxiosResponse<any>, any> {
    try {
        const response: AxiosResponse<any> = yield service.referral.add(payload);
        if (response.status === 201) {
            toast.success(response?.data?.message);
            if(!payload?.isFinalSubmit){
                navigate(`/manage-caseload/create?id=${payload.caseload_id}`);
            }

        } else {
            toast.error(response?.data?.message);
        }
        return response;
    } catch (error: any) {
        toast.error(error?.response?.data?.message);
        return error;
    }
}

function* editReferral({ id , payload,caseloadID, navigate }: any): Generator<any, AxiosResponse<any>, any> {
    const { caseload_id, ...updatedParentDetail } = payload;
    try {
        const response: AxiosResponse<any> = yield service.referral.edit(id, updatedParentDetail);
        if (response.status === 201 || response.status === 200) {
            toast.success(response?.data?.message);
            if(!payload?.isFinalSubmit){
                navigate(`/manage-caseload/create?id=${payload.caseload_id}`);
            }

        } else {
            toast.error(response?.data?.message);
        }
        return response;
    } catch (error: any) {
        toast.error(error?.response?.data?.message);
        return error;
    }
}

function* getReferral({ params }: any): Generator<any, AxiosResponse<any>, any> {
    try {
        const response: any = yield service.referral.view(params);

        yield put({ type: REFERRAL_RESPONSE, payload: response?.data })
        if (response.status === 201) {
            // toast.success(response?.data?.message);
            // navigate("/referral");

        } else {
            // toast.error(response?.data?.message);
        }
        return response;
    } catch (error: any) {
        toast.error(error?.response?.data?.message);
        return error;
    }
}

function* getGPDetail({ params }: any): Generator<any, AxiosResponse<any>, any> {
    try {
        const response: any = yield service.referral.getGPList(params);

        yield put({ type: GP_LIST_RESPONSE, payload: response?.data })
        if (response.status === 201) {
            // toast.success(response?.data?.message);
            // navigate("/referral");

        } else {
            // toast.error(response?.data?.message);
        }
        return response;
    } catch (error: any) {
        toast.error(error?.response?.data?.message);
        return error;
    }
}

function* getAllReferralDetail({ params }: any): Generator<any, AxiosResponse<any>, any> {
    try {
        const response: any = yield service.referral.viewAllDetail(params);

        yield put({ type: GET_REFERRAL_ALL_RESPONSE, payload: response?.data })
        if (response.status === 201 || response.status === 200) {
            // toast.success(response?.data?.message);
            // navigate("/referral");

        } else {
            toast.error(response?.data?.message);
        }
        return response;
    } catch (error: any) {
        toast.error(error?.response?.data?.message);
        return error;
    }
}

function* getDetailById({ paramsObj }: any): Generator<any, AxiosResponse<any>, any> {
    try {
        const response: any = yield service.referral.getReferralById(paramsObj);

        yield put({ type: REFERRAL_CASELOAD_RESPONSE, payload: response.data })
        if (response.status === 201 || response.status === 200) {
            // toast.success(response?.data?.message);
            // navigate("/referral");

        } else {
            // toast.error(response?.data?.message);
        }
        return response;
    } catch (error: any) {
        // toast.error(error?.response?.data?.message);
        return error;
    }
}


function* referralSaga() {
    yield takeLatest(ADD_REFERRAL, addReferral);
    yield takeLatest(EDIT_REFERRAL, editReferral);
    yield takeLatest(VIEW_REFERRAL, getReferral);
    yield takeLatest(GET_REFERRAL_CASELOAD_DETAIL, getDetailById);
    yield takeLatest(GET_REFERRAL_ALL_DETAIL, getAllReferralDetail);
    yield takeLatest(GET_GP_LIST, getGPDetail);
}

export default referralSaga;
