import { combineReducers } from "redux";
import { Login_Response } from "./auth/reducers";
import { referralReducer } from "./refferal/reducers";
import { userManagementReducer } from "./userManagement/reducers";
import { LayoutReducer, conversationIdReducer, selectedDocTabReducer,refClosedReducer, selectedTabReducer, selectedTaskTabReducer, selectedUserReducer, userListActiveReducer } from "./Layout/reducers";
import { CaseloadReducer, UploadDocuments,createConversationsReducer,chatMsgReducer,chatConversationReducer, taskDocReducer, caseDocumentReducer, TaskListReducer, CaseLoadUserReducer,parentReducer,schoolReducer } from "./caseload/reducers";
import { ResourceDocumentReducer } from "./resources/reducers";
import { reportReducer } from "./report/reducers";


const rootReducer: any = combineReducers({
    Login_Response,
    referralReducer,
    reportReducer,
    userManagementReducer,
    CaseloadReducer,
    LayoutReducer,
    selectedTabReducer,
    UploadDocuments,
    caseDocumentReducer,
    TaskListReducer,
    ResourceDocumentReducer,
    CaseLoadUserReducer,
    taskDocReducer,
    parentReducer,
    conversationIdReducer,
    refClosedReducer,
    userListActiveReducer,
    selectedDocTabReducer,
    selectedTaskTabReducer,
    selectedUserReducer,
    schoolReducer,
    chatMsgReducer,
    chatConversationReducer,
    createConversationsReducer

});

export default rootReducer;
