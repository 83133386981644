import React, { useEffect, useState } from 'react'
import { Grid, Box, Card, Typography, CardContent, Divider, TextField, InputLabel, FormGroup, FormControlLabel, Checkbox } from '@mui/material'
import AddCircleIcon from '@mui/icons-material/AddCircle';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import WarningIcon from '@mui/icons-material/Warning';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import classes from "./index.module.scss"
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from "reselect";
import { getReportCaseloadByID, addCaseload } from '../../../../../redux/action';
import { useNavigate, useParams } from 'react-router-dom';
import moment from 'moment';
import atoms from '../../../../../components/atoms'
import { useFormik } from 'formik';
import * as Yup from "yup";
import { style } from './style'
import constant from '../../../../../constants/constant';
import { formatNHSNumber, getUniqueObj } from '../../../../../constants/utils'
import { ReportCaseLoad } from './interface'
import ConfirmationModal from '../../../../../components/atoms/ConfirmationModal';
const { Button, Modal, Select, Table, LightBox } = atoms

const CreateCaseload = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const params: any = useParams()
    const [isAddClinicianModel, setIsAddClinicianModel] = useState(false);
    const [isNewSchool, setIsNewSchool] = useState(false)
    const [isNewParent, setIsNewParent] = useState(false)
    const [isSchoolDetailFilled, setIsSchoolDetailFilled] = useState(false)
    const [clinicianIds, setClinicianIds] = useState([]);
    const [isSeletedClinician, setIsSeletedClinician] = useState<any>([])
    const [selectClinicianList, setSelectClinicianList] = useState([])
    const [isCreateCaseload, setIsCreateCaseload] = useState(false)
    const [isEditDetail, setIsEditDetail] = useState(false)
    const [isEditParentDetail, setIsEditParentDetail] = useState(false)
    const [isNewParentDetail, setIsNewParentDetail] = useState<any>()
    const [isNewSchoolDetail, setIsNewSchoolDetail] = useState<any>()
    const [columns, setColumns] = useState([
        {
            name: '#',
            cell: (item: any, ind: number) => <span>{ind + 1}</span>,
            width: '50px'

        },
        {
            name: 'Clinicians Name',
            cell: (item: any, ind: number) => <span>{item.label}</span>,
            sortable: false,
            minWidth: "25%"
        },
        {
            name: 'Email Address',
            cell: (item: any, ind: number) => <span>{item.email}</span>,
            sortable: false,
            minWidth: "26%"
        },
        {
            name: 'Job Role',
            cell: (item: any, ind: number) => <span>{item.jobRole}</span>,
            sortable: false,
            minWidth: "34%"
        },

        {
            name: 'Action',
            cell: (item: any, ind: number) => <span>{item.action}</span>,
            sortable: false
        }
    ]);
    let schoolInitialValues = {
        first_name: '',
        last_name: '',
        email: '',
        postCode: '',
        mobile: '',
        address: '',
        school_name: '',
        role: constant.ROLE.ALL_ACCESS,
        scope: constant.SCOPE.CLINICIAN
    }
    const referralCaseloadReducer = (state: any) => state?.referralReducer
    const userReducer = (state: any) => state?.userManagementReducer
    const getCustomDropdown = (response: any, name: string, last: string) => {
        let dropdown: any = []
        response && response?.map((value: any) => {
            if (isEditDetail) {
                dropdown.push({
                    "value": value.id,
                    "label": last ? value[name] + ' ' + value[last] : value[name],
                    'first_name': value[last],
                    "last_name": value[name],
                    "email": value.email,
                    "address": value.address,
                    "jobRole": value.jobRole,
                    "school_name": value.school_name,
                })
            } else {
                dropdown.push({
                    "value": value.id,
                    "label": last ? value[name] + ' ' + value[last] : value[name],
                    "email": value.email,
                    "jobRole": value.jobRole,
                })
            }
        })
        return dropdown
    }

    const referralCaseloadData = createSelector(
        referralCaseloadReducer,
        (state) => ({
            referralCaseload: state?.referralCaseload,
        })
    );

    const userData = createSelector(
        userReducer,
        (state) => ({
            userList: getCustomDropdown(state?.userData?.user, "first_name", "last_name"),

        })
    );
    const { userList } = useSelector(userData);
    const { referralCaseload } = useSelector(referralCaseloadData);

    const validation = useFormik<ReportCaseLoad>({
        enableReinitialize: true,
        initialValues: schoolInitialValues,
        validationSchema: Yup.object({
            first_name: Yup.string().required("Please Enter First Name"),
            last_name: Yup.string().required("Please Enter Last Name"),
            address: Yup.string().required("Please Enter Address"),
            school_name: Yup.string().required("Please Enter Education Setting Name"),
            email: Yup.string().required("Please Enter Email"),
            postCode: Yup.string().required("Please Enter Post Code"),
            mobile: Yup.number().required("Please Enter Mobile Number"),

            // modules: Yup.string().required("Please Select"),

        }),
        onSubmit: (values: ReportCaseLoad) => {
            setIsNewSchoolDetail(values)
            setIsSchoolDetailFilled(true)
            setIsEditDetail(false)
        }
    });

    const validationParent = useFormik<ReportCaseLoad>({
        enableReinitialize: true,
        initialValues: {
            first_name: '',
            last_name: '',
            email: '',
            postCode: '',
            role: constant.ROLE.ALL_ACCESS,
            scope: constant.SCOPE.CLINICIAN
        },
        validationSchema: Yup.object({
            first_name: Yup.string().required("Please Enter First Name"),
            last_name: Yup.string().required("Please Enter Last Name"),
            email: Yup.string().required("Please Enter Email"),
        }),
        onSubmit: (values: ReportCaseLoad) => {
            setIsNewParentDetail(values)
            setIsEditParentDetail(false)
        }
    });


    useEffect(() => {
        dispatch(getReportCaseloadByID({ "ReferralId": params.id }))

    }, [])

    useEffect(() => {
        if (!referralCaseload) return;
        const { school_detail, parent_detail, isNewSchool } = referralCaseload;
        const values = {
            first_name: school_detail?.first_name,
            last_name: school_detail?.last_name,
            email: school_detail?.contact_email,
            postCode: school_detail?.post_name,
            mobile: school_detail?.contact_phone,
            address: school_detail?.address,
            school_name: school_detail?.school_name
        };

        const parentValues = {
            first_name: parent_detail?.first_name,
            last_name: parent_detail?.last_name,
            email: parent_detail?.email,

        };

        setIsNewParentDetail(parent_detail)
        setIsNewSchoolDetail(school_detail)

        validation.setValues(values);
        validationParent.setValues(parentValues);
        setIsNewSchool(isNewSchool);
    }, [referralCaseload]);
    const addClinician = () => {
        let tempClinicianList: any = [];
        let tempClinicianIds: any = [];
        if (isSeletedClinician) {
            tempClinicianList = tempClinicianList.concat(isSeletedClinician);
        }

        let Ids = isSeletedClinician?.map((item: any) => item?.value);
        if (Ids) {
            tempClinicianIds = tempClinicianIds.concat(Ids);
        }
        tempClinicianIds.filter((v: any, i: any, a: any) => a.findIndex((v2: string) => (v2 === v)) === i)
        tempClinicianList.filter((v: any, i: any, a: any) => a.findIndex((v2: any) => (v2.value === v.value)) === i)
        setSelectClinicianList(tempClinicianList);
        setClinicianIds(tempClinicianIds);
        setIsAddClinicianModel(false);

    }

    const removeClinician = (index: number) => {
        let tempClinicianList: any = [...selectClinicianList];
        let tempSeletedClinician: any = [...isSeletedClinician];
        let tempClinicianIds: any = [...clinicianIds];

        tempClinicianList.splice(index, 1);
        tempClinicianIds.splice(index, 1);
        tempSeletedClinician.splice(index, 1);

        setSelectClinicianList(tempClinicianList);
        setIsSeletedClinician(tempSeletedClinician);
        setClinicianIds(tempClinicianIds);
        setIsAddClinicianModel(false);
    }


    const createCaseload = () => {
        let payload = {
            school_detail: isNewSchoolDetail,
            parent_detail: isNewParentDetail,
            patient_name: referralCaseload?.patient_detail?.name,
            patient_dob: referralCaseload?.patient_detail?.dob,
            nhs_number: referralCaseload?.patient_detail?.nhs_number,
            referral_id: params.id,
            clinician_ids: clinicianIds
        }

        dispatch(addCaseload(payload, navigate))
    }


    const handleSelect = (e: any) => {
        // if (isEditParentDetail) {
        //     setIsNewParentDetail(e)
        // } else {
        setIsNewSchoolDetail(e)
        // }

    }

    return (
        <Grid className={classes.clWrapper}>
            <Card variant="outlined" sx={{ borderRadius: "10px" }}>
                <Box padding={2} >
                    <Grid mb={"10px"}>
                        <Grid item>
                            <Typography variant="subtitle1"><strong> Details</strong></Typography>
                        </Grid>
                    </Grid>
                    <Grid container spacing={1} alignItems="center" justifyContent="space-between" >
                        <Grid item sx={{ gap: 2, display: 'flex', alignItems: 'center' }}>
                            <Typography variant="subtitle1"><strong>Patient Name :</strong></Typography>
                            <Typography sx={{ color: "#444C55" }}>{referralCaseload?.patient_detail?.name}</Typography>
                        </Grid>
                        <Grid item>
                            <Grid item sx={{ gap: 2, display: 'flex', alignItems: 'center' }}>
                                <Typography variant="subtitle1"><strong>DOB :</strong></Typography>
                                <Typography sx={{ color: "#444C55" }}>{moment(referralCaseload?.patient_detail?.dob).format('DD/MM/YYYY')}</Typography>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <Grid item sx={{ gap: 2, display: 'flex', alignItems: 'center' }}>
                                <Typography variant="subtitle1"><strong>Gender :</strong></Typography>
                                <Typography sx={{ color: "#444C55" }}>{referralCaseload?.patient_detail?.gender}</Typography>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <Grid item sx={{ gap: 2, display: 'flex', alignItems: 'center' }}>
                                <Typography variant="subtitle1"><strong>NHS Number :</strong></Typography>
                                <Typography sx={{ color: "#444C55" }}>{formatNHSNumber(referralCaseload?.patient_detail?.nhs_number)}</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
            </Card>


            <Box
                sx={{
                    // border: "1px solid #E7E7E7",
                    borderRadius: "10px", mt: 3
                }}
            >


                <Grid
                    container
                    columns={12}
                    // padding={2}
                    sx={{ mt: { md: "10px" }, my: { md: "0" } }}
                >
                    <Grid item xs={12} md={6}
                        sx={{ pr: { md: "10px" } }}
                    >
                        {/* Parent/Carer Details Card */}
                        <Card variant="outlined" sx={style.card_custom}>
                            <CardContent>
                                <Box display="flex" justifyContent="space-between" alignItems="center" className={classes.clTitle}>
                                    <Typography variant="h6">1. Parent/Carer Details</Typography>
                                    {/* <ModeEditIcon
                                        onClick={() => {
                                            setIsEditParentDetail(true)
                                            dispatch(getUserList({ scope: constant.SCOPE.PARENT }))
                                        }}
                                        sx={{ cursor: 'pointer', color: '#6A2382' }} /> */}
                                </Box>
                                {/* <Divider /> */}
                                <Box display="flex" justifyContent="space-between" alignItems="center" my={"5px"}>
                                    <Typography variant="subtitle1">Name: </Typography>
                                    <Typography sx={style.font_custom_style} >{(isNewParentDetail?.first_name ? isNewParentDetail?.first_name : '') + ' ' + (isNewParentDetail?.last_name ? isNewParentDetail?.last_name : '')}</Typography>

                                </Box>
                                <Divider />
                                <Box display="flex" justifyContent="space-between" alignItems="center" my={"5px"}>
                                    <Typography variant="subtitle1">Email: </Typography>
                                    <Typography sx={style.font_custom_style} >{isNewParentDetail?.email}</Typography>
                                </Box>
                                <Divider />
                                <Box display="flex" justifyContent="space-between" alignItems="center" my={"5px"}>
                                    <Typography variant="subtitle1">Contact Number: </Typography>
                                    <Typography sx={style.font_custom_style} >{isNewParentDetail?.mobile}</Typography>
                                </Box>
                                <Divider />
                                <Box display="flex" justifyContent="space-between" alignItems="center" my={"5px"}>
                                    <Typography variant="subtitle1">Role: </Typography>
                                    <Typography sx={style.font_custom_style} >{referralCaseload?.parent_detail?.role}</Typography>
                                </Box>
                                <Box display="flex" justifyContent="space-between" alignItems="center" my={"23px"}>
                                </Box>

                            </CardContent>
                        </Card>
                    </Grid>

                    <Grid item xs={12} md={6}
                        sx={{ pl: { md: "10px" } }}
                    >
                        {/* Parent/Carer Details Card */}
                        <Card variant="outlined" sx={style.card_custom}>
                            <CardContent>
                                <Box display="flex" justifyContent="space-between" alignItems="center" className={classes.clTitle}>
                                    <Grid sx={{ display: 'flex', gap: '5px' }}><Typography variant="h6">2. Education Setting Details</Typography><WarningIcon sx={{ mt: '3px', color: '#ff4949' }} /></Grid>
                                    {/* <ModeEditIcon onClick={() => {
                                        setIsEditParentDetail(false)

                                        dispatch(getUserList({ scope: constant.SCOPE.SCHOOL }))
                                        setIsEditDetail(true)
                                    }} sx={{ cursor: 'pointer', color: '#6A2382' }} /> */}

                                </Box>
                                {/* <Divider /> */}
                                <Box display="flex" justifyContent="space-between" alignItems="center" my={"5px"}>
                                    <Typography variant="subtitle1">Education Setting Name: </Typography>
                                    <Typography sx={style.font_custom_style} >{isNewSchoolDetail?.school_name}</Typography>
                                </Box>
                                <Divider />
                                <Box display="flex" justifyContent="space-between" alignItems="center" my={"5px"}>
                                    <Typography variant="subtitle1">Contact Person: </Typography>
                                    <Typography sx={style.font_custom_style} > {isNewSchoolDetail?.contact_person_name || isNewSchoolDetail?.first_name ? isNewSchoolDetail?.first_name + ' ' + isNewSchoolDetail?.last_name : ''}</Typography>
                                </Box>
                                <Divider />
                                <Box display="flex" justifyContent="space-between" alignItems="center" my={"5px"}>
                                    <Typography variant="subtitle1">Contact Email: </Typography>
                                    <Typography sx={style.font_custom_style} > {isNewSchoolDetail?.email}</Typography>
                                </Box>
                                <Divider />
                                <Box display="flex" justifyContent="space-between" alignItems="center" my={"5px"}>
                                    <Typography variant="subtitle1">Contact Number: </Typography>
                                    <Typography sx={style.font_custom_style} >{isNewSchoolDetail?.contact_phone || isNewSchoolDetail?.mobile}</Typography>
                                </Box>
                                <Divider />
                                <Box display="flex" justifyContent="space-between" alignItems="center" my={"5px"}>
                                    <Typography variant="subtitle1">Address: </Typography>
                                    <Typography sx={style.font_custom_style}>{isNewSchoolDetail?.address}</Typography>
                                </Box>

                                {/* <Box display="flex" justifyContent="space-between" alignItems="center" mt={"20px"} className={classes.markBtn}>
                                    <Button variant={'outlined'} fullWidth={true} sx={schoolVerified && style.non_verified_button} onClick={() => setSchoolVerified(!schoolVerified)} >
                                        Mark As {!schoolVerified ? "Verified" : "Unverified"}
                                    </Button>
                                </Box> */}
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
                <Grid sx={{ py: { md: "20px" } }}>
                    <Card variant="outlined" sx={style.card_custom}>
                        <CardContent>
                            <Grid sx={{ display: 'flex', justifyContent: 'space-between', mb: '15px' }}>
                                <Grid >
                                    <Typography variant="h6">Assign Clinicians</Typography>
                                </Grid>
                                {/* <Grid >
                                    <Button variant="outlined" disabled={(isNewSchool && !isSchoolDetailFilled)} startIcon={<AddCircleIcon />} sx={{ borderRadius: "60px" }} onClick={() => {
                                        dispatch(getUserList({ scope: constant.SCOPE.CLINICIAN }))
                                        setIsAddClinicianModel(true)
                                    }}>Add Clinician</Button>
                                </Grid> */}
                            </Grid>
                            <Divider />
                            <Grid >
                                <Table
                                    className={classes.customFixeHeight}
                                    data={selectClinicianList && selectClinicianList?.map((item: any, index: number) => {
                                        return {
                                            ...item,
                                            action: <HighlightOffIcon onClick={() => removeClinician(index)} sx={{ color: '#F15046', cursor: 'pointer' }} />
                                        };
                                    })}
                                    columns={columns}
                                />
                            </Grid>
                        </CardContent>
                    </Card>

                </Grid>

                <Grid container spacing={3} sx={{ p: '10px' }}>
                    <Grid item md={6} lg={6} className={classes.cancelBtn} sx={{}}>
                        <Button variant={'outlined'} fullWidth={true} sx={style.non_verified_button} >
                            Close
                        </Button>

                    </Grid>
                    <Grid item md={6} lg={6}>
                        <Button variant={'outlined'} fullWidth={true} disabled={selectClinicianList?.length === 0} onClick={() => setIsCreateCaseload(true)} >
                            Accept Referral
                        </Button>
                    </Grid>
                </Grid>
            </Box >

            <>
                <Modal open={isAddClinicianModel} handleClose={() => {

                    setIsAddClinicianModel(false)
                    // setIsSeletedClinician('')
                }} title={"Add Clinicians"}>
                    <Box className={classes.clinicModal}>
                        <Grid sx={{ width: '100%' }}>
                            <Select placeholder={"Select Clinicians"} isMulti={true} disableCloseOnSelect={true} label={"Clinicians"} defaultValue={isSeletedClinician} options={userList} onChange={(e: any) => setIsSeletedClinician(e)} />
                        </Grid>
                        <Grid className={classes.btnWrapper} sx={{ display: 'flex', justifyContent: 'center', alignItem: 'center', mt: '15px' }}>
                            <Button variant={"contained"} onClick={() => addClinician()} >Add Clinicians</Button>
                        </Grid>

                    </Box>
                </Modal>
            </>

            <>
                {/* <Modal open={isCreateCaseload} handleClose={() => {
                    setIsCreateCaseload(false)
                }} title={"Confirmation"}>
                    <Box className={classes.clinicModal}>
                    <Grid sx={{ width: '100%', textAlign: 'center',py:2 }}>
                            <Typography variant="body1" fontFamily={'poppins'}>Are you sure you want to Accept Referral ?</Typography>
                        </Grid>
                        <Grid className={classes.btnWrapper} sx={{ display: 'flex', justifyContent: 'center', gap: '10px', alignItem: 'center', mt: '15px' }}>
                            <Button sx={{ width: '30%',borderColor:'gray' }} variant={"outlined"} onClick={() => setIsCreateCaseload(false)} >No</Button>
                            <Button sx={{ width: '30%' }} variant={"contained"} onClick={() => createCaseload()} >Yes</Button>
                        </Grid>

                    </Box>
                </Modal> */}

                <ConfirmationModal
        open={isCreateCaseload}
        handleClose={()=> setIsCreateCaseload(false)}
        title="Confirmation"
        message=" Are you sure you want to Accept Referral"
        onConfirm={() => createCaseload()}
        onCancel={()=> setIsCreateCaseload(false)}
      />
            </>



            <>
                <LightBox title={!isEditParentDetail ? 'Assign Education Setting' : 'Assign Parent'} open={isEditDetail} handleClose={() => { setIsEditDetail(false) }} >
                    <Box className={classes.clinicModal}>
                        <Grid sx={{ width: '100%', textAlign: 'center' }}>
                        </Grid>
                        <Grid className={classes.btnWrapper} sx={{ p: '' }}>
                            <Grid>
                                <form onSubmit={validation.handleSubmit}>
                                    <Grid>
                                        {!isEditParentDetail ?
                                            <FormGroup>
                                                <FormControlLabel onChange={(e: any) => {
                                                    validation.resetForm()
                                                    setIsNewSchoolDetail('')
                                                    setIsNewSchool(e.target.checked)
                                                }}
                                                    control={<Checkbox checked={isNewSchool} />} sx={{ fontWeight: 600 }} label="New Education Setting" />
                                            </FormGroup>
                                            :
                                            <FormGroup>
                                                {/* <FormControlLabel onChange={(e: any) => {
                                                    validation.resetForm()
                                                    setIsNewParentDetail({})
                                                    setIsNewParent(e.target.checked)
                                                }}
                                                    control={<Checkbox checked={isNewParent} />} sx={{ fontWeight: 600 }} label="New Parent" /> */}
                                            </FormGroup>
                                        }
                                    </Grid>
                                    <Grid container spacing={3} className={classes.mt_3}>
                                        <Grid item xs={12} sm={6} md={6} lg={6}>
                                            <Box>
                                                <InputLabel sx={style.label_color} > First Name</InputLabel>
                                                <TextField
                                                    fullWidth
                                                    placeholder="Enter First Name"
                                                    {...validation.getFieldProps('first_name')}
                                                    error={validation.touched.first_name && Boolean(validation.errors.first_name)}
                                                    helperText={validation.touched.first_name ? validation.errors.first_name : ''}
                                                    disabled={!isNewSchool}
                                                />
                                            </Box>

                                        </Grid>
                                        <Grid item xs={12} sm={6} md={6} lg={6}>
                                            <Box>
                                                <InputLabel sx={style.label_color} > Last Name</InputLabel>
                                                <TextField
                                                    fullWidth
                                                    placeholder="Enter Last Name"
                                                    {...validation.getFieldProps('last_name')}
                                                    error={validation.touched.last_name && Boolean(validation.errors.last_name)}
                                                    helperText={validation.touched.last_name && validation.errors.last_name}
                                                    disabled={!isNewSchool}
                                                />
                                            </Box>

                                        </Grid>

                                    </Grid>

                                    <Grid container spacing={3} className={classes.mt_3}>
                                        <Grid item xs={12} sm={6} md={6} lg={6}>
                                            <Box>
                                                <InputLabel sx={style.label_color} >Education Setting Name</InputLabel>
                                                <TextField
                                                    fullWidth
                                                    type={"text"}
                                                    placeholder="Enter Education Setting Name"
                                                    {...validation.getFieldProps('school_name')}
                                                    error={validation.touched.school_name && Boolean(validation.errors.school_name)}
                                                    helperText={validation.touched.school_name && validation.errors.school_name}
                                                    disabled={!isNewSchool}
                                                />
                                            </Box>
                                        </Grid>

                                        <Grid item xs={12} sm={6} md={6} lg={6}>
                                            <Box>
                                                <InputLabel sx={style.label_color}>Email</InputLabel>
                                                <TextField
                                                    fullWidth
                                                    type={"email"}
                                                    placeholder="Enter Email"
                                                    {...validation.getFieldProps('email')}
                                                    error={validation.touched.email && Boolean(validation.errors.email)}
                                                    helperText={validation.touched.email && validation.errors.email}
                                                    disabled={!isNewSchool}

                                                />
                                            </Box>

                                        </Grid>
                                    </Grid>

                                    <Grid className={classes.mt_3}>
                                        <Box>
                                            <InputLabel sx={style.label_color} >Address</InputLabel>
                                            <TextField
                                                fullWidth
                                                type="text"
                                                placeholder="Enter Address"
                                                {...validation.getFieldProps('address')}
                                                error={validation.touched.address && Boolean(validation.errors.address)}
                                                helperText={
                                                    validation.touched.address && validation.errors.address
                                                        ? String(validation.errors.address) // Convert errors to string
                                                        : "" // Empty string if there are no errors
                                                }
                                                rows={3}
                                                disabled={!isNewSchool}
                                                multiline={true}
                                                inputProps={{ maxLength: 500 }}
                                            />
                                        </Box>
                                    </Grid>

                                    <Grid container spacing={3} className={classes.mt_3}>
                                        <Grid item xs={12} sm={6} md={6} lg={6}>
                                            <Box>
                                                <InputLabel sx={style.label_color} >Contact Person Mobile</InputLabel>
                                                <TextField
                                                    fullWidth
                                                    type="number"
                                                    placeholder="Enter mobile"
                                                    {...validation.getFieldProps('mobile')}
                                                    error={validation.touched.mobile && Boolean(validation.errors.mobile)}
                                                    helperText={validation.touched.mobile && validation.errors.mobile}
                                                    disabled={!isNewSchool}
                                                />
                                            </Box>
                                        </Grid>

                                        <Grid item xs={12} sm={6} md={6} lg={6}>
                                            <Box>
                                                <InputLabel sx={style.label_color} >Post Code</InputLabel>
                                                <TextField
                                                    fullWidth
                                                    type="text"
                                                    placeholder="Enter Post Code"
                                                    {...validation.getFieldProps('postCode')}
                                                    error={validation.touched.postCode && Boolean(validation.errors.postCode)}
                                                    helperText={validation.touched.postCode && validation.errors.postCode}
                                                    disabled={!isNewSchool}
                                                />
                                            </Box>

                                        </Grid>

                                    </Grid>
                                    {!isEditParentDetail && <Grid sx={{ mt: '30px', mb: '20px', textAlign: 'center' }}>
                                        <Typography sx={style.label_color}>OR</Typography>
                                    </Grid>}

                                    {!isEditParentDetail && <Grid width={'100%'}>
                                        <InputLabel sx={style.label_color} >Education Setting</InputLabel>
                                        <Select placeholder={"Select..."} isDisabled={(!isEditParentDetail && isNewSchool) || (isEditParentDetail && isNewParent)} disableCloseOnSelect={false}
                                            defaultValue={isNewSchoolDetail ? isNewSchoolDetail : {}} options={userList} onChange={(e: any) =>
                                                handleSelect(e)
                                            } />
                                    </Grid>}
                                    <Grid className={`${classes.btnClr}`} sx={{ mt: '30px', textAlign: 'center' }}>

                                        {isNewSchool ? <Button type={"submit"} fullWidth={false}
                                            variant={"contained"}>
                                            Add & Assign
                                        </Button> :
                                            <Button fullWidth={false} variant={"contained"} onClick={() => setIsEditDetail(false)}>
                                                Assign
                                            </Button>
                                        }
                                    </Grid>
                                </form>
                            </Grid>
                        </Grid>

                    </Box>
                </LightBox>
            </>

            <>{
                <LightBox title={'Assign Parent'} open={isEditParentDetail} handleClose={() => { setIsEditParentDetail(false) }} >
                    <Box className={classes.clinicModal}>
                        <Grid sx={{ width: '100%', textAlign: 'center' }}>
                        </Grid>
                        <Grid className={classes.btnWrapper} sx={{ p: '' }}>
                            <Grid>
                                <form onSubmit={validationParent.handleSubmit}>
                                    <Grid container spacing={3} className={classes.mt_3}>
                                        <Grid item xs={12} sm={6} md={6} lg={6}>
                                            <Box>
                                                <InputLabel sx={style.label_color} > First Name</InputLabel>
                                                <TextField
                                                    fullWidth
                                                    placeholder="Enter First Name"
                                                    {...validationParent.getFieldProps('first_name')}
                                                    error={validationParent.touched.first_name && Boolean(validationParent.errors.first_name)}
                                                    helperText={validationParent.touched.first_name ? validationParent.errors.first_name : ''}
                                                // disabled= !isNewParent)}
                                                />
                                            </Box>

                                        </Grid>
                                        <Grid item xs={12} sm={6} md={6} lg={6}>
                                            <Box>
                                                <InputLabel sx={style.label_color} > Last Name</InputLabel>
                                                <TextField
                                                    fullWidth
                                                    placeholder="Enter Last Name"
                                                    {...validationParent.getFieldProps('last_name')}
                                                    error={validationParent.touched.last_name && Boolean(validationParent.errors.last_name)}
                                                    helperText={validationParent.touched.last_name && validationParent.errors.last_name}
                                                // disabled={(!isEditParentDetail && !isNewSchool) || (isEditParentDetail && !isNewParent)}
                                                />
                                            </Box>

                                        </Grid>

                                    </Grid>

                                    <Grid container spacing={3} className={classes.mt_3}>
                                        <Grid item xs={12} sm={6} md={6} lg={6} >
                                            <Box>
                                                <InputLabel sx={style.label_color}>Email</InputLabel>
                                                <TextField
                                                    fullWidth
                                                    type={"email"}
                                                    placeholder="Enter Email"
                                                    {...validationParent.getFieldProps('email')}
                                                    error={validationParent.touched.email && Boolean(validationParent.errors.email)}
                                                    helperText={validationParent.touched.email && validationParent.errors.email}
                                                // disabled={(!isEditParentDetail && !isNewSchool) || (isEditParentDetail && !isNewParent)}

                                                />
                                            </Box>
                                        </Grid>
                                    </Grid>
                                    <Grid className={`${classes.btnClr}`} sx={{ mt: '30px', textAlign: 'center' }}>
                                        <Button type={"submit"} fullWidth={false} variant={"contained"} >
                                            Assign
                                        </Button>
                                    </Grid>
                                </form>
                            </Grid>
                        </Grid>

                    </Box>
                </LightBox>
            }</>
        </Grid >
    )
}

export default CreateCaseload