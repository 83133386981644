import {
  CASELOAD_CURRENT_TAB,
    CHANGE_TAB,
    CURRENT_TAB,
    REFFERAL_CLOSED,
    SET_CONVERSATION_ID,
    SET_DOCUMENT_TAB,
    SET_SELECTED_TAB,
    SET_SELECTED_USER,
    SET_TASK_TAB,
    SET_USER_LIST_ACTIVE
} from './actionType'

export const changeTab = (tab: string) => {
    return {
        type: CHANGE_TAB,
        tab
    }
}


export const setSelectedTab = (tabIndex: number) => ({
    type: SET_SELECTED_TAB,
    payload: tabIndex
  });

  export const setSelectedDocTab = (tabIndex: number) => ({
    type: SET_DOCUMENT_TAB,
    payload: tabIndex
  });

  export const setSelectedTaskTab = (tabIndex: number) => ({
    type: SET_TASK_TAB,
    payload: tabIndex
  });

  export const setSelectedUser = (userId:any) => ({
    type: SET_SELECTED_USER,
    payload: userId
  });

  export const setConversationId = (conversationId:any) => ({
    type: SET_CONVERSATION_ID,
    payload: conversationId
  });

  export const setUserListActive = (userListActive:object) => ({
    type: SET_USER_LIST_ACTIVE,
    payload: userListActive
  });


  export const setRefClosedStatus = (status:boolean) => ({
    type: REFFERAL_CLOSED,
    payload: status
  });

  export const setCaseloadCurentTab = (status:number) => ({
    type: CASELOAD_CURRENT_TAB,
    payload: status
  });