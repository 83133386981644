import React from 'react'
import Layout from '../components/common/Layout'
import { Navigate } from 'react-router';

const AuthLayout = (props: any) => {
    // if (!localStorage.getItem("access_token")) {
    //      return (
    //          <Navigate to={{ pathname: "/login", }} />
    //      );
    // }



    return (
        <React.Fragment>
            <div id="layout-wrapper">
                <Layout >
                    <div className="main-content">{props.children}</div>
                </Layout>
            </div>
        </React.Fragment>
    )
}

export default AuthLayout