import Button from './Button'
import Modal from './Modal'
import Pagination from './Pagination'
import Search from './Search'
import Select from './Select'
import Table from './Table'
import TableCom from './TableCom'
import TextField from './TextField'
import LightBox from './Lightbox'
import Slider from './Carousel'
const atoms = {
  Button,
  Modal,
  Pagination,
  Search,
  Select,
  Table,
  Slider,
  TableCom,
  TextField,
  LightBox,
}

export default atoms
