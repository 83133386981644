import { Card } from '@mui/material'
import React, { useEffect, useState } from 'react'
// import Card from '../../../layout/Card/Card'
import { styled, alpha, Box, Grid, Paper, IconButton, MenuItem, Menu, ListItemIcon, Typography } from '@mui/material';
import Button from '../../../../components/atoms/Button'
import SearchIcon from '@mui/icons-material/Search';
import InputBase from '@mui/material/InputBase';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { useNavigate } from 'react-router-dom';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import Table from '../../../../components/atoms/Table'
import { viewReferral } from '../../../../redux/action';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from "reselect";
import CONSTANT from '../../../../constants/constant';
import Search from "../../../../components/atoms/Search"
import PendingOutlinedIcon from '@mui/icons-material/PendingOutlined';
import moment from 'moment';
import { calculateAge, formatNHSNumber } from "../../../../constants/utils"

const Index = () => {
    const Navigate = useNavigate()
    const dispatch = useDispatch()
    const [anchorEl, setAnchorEl] = useState(null);
    const [page, setPage] = useState(1)
    const [search, setSearch] = useState("")
    const [limit, setLimit] = useState(10)
    const [selectedUserId, setSelectedUserId] = useState<string | null>(null);
    const [columns, setColumns] = useState([
        {
            name: '#',
            cell: (item: any, ind: number) => <span>{ind + 1}</span>,
            width: '50px'

        },
        {
            name: 'Referral ID',
            cell: (item: any, ind: number) => <span>{item.referral_id}</span>,
            sortable: false,
            minWidth: "15%"
        },
        {
            name: 'Referral Date/Time',
            cell: (item: any, ind: number) => <span>{moment(item.createdAt).format("DD/MM/YYYY")}</span>,
            sortable: false,
            minWidth: "15%"
        },
        {
            name: 'Patient Name',
            cell: (item: any, ind: number) => <span>{item.patient_name}</span>,
            selector: (item: any, ind: number) => item.patient_name,
            sortable: true,
            minWidth: "15%"
        },
        {
            name: 'Patient AGE',
            cell: (item: any, ind: number) => <span>{item.age}</span>,
            sortable: false,
            minWidth: "15%"
        },
        {
            name: 'NHS Number',
            cell: (item: any, ind: number) => <span>{formatNHSNumber(item?.patient_nhs_number)}</span>,
            sortable: false
        },
        {
            name: 'Source',
            cell: (item: any, ind: number) => <span>{item.status}</span>,
            sortable: false
        },
        {
            name: 'action',
            cell: (item: any, ind: number) => <span>{item.action}</span>,
            sortable: false
        }
    ]);
    const referralReducer = (state: any) => state?.referralReducer
    const referralData = createSelector(
        referralReducer,
        (state) => ({
            referralList: state.referralList,

        })
    );
    const { referralList } = useSelector(referralData);
    const handleClick = (event: any, id: string) => {
        setAnchorEl(event.currentTarget);
        setSelectedUserId(id)
    };


    const handleClose = () => {
        setAnchorEl(null);
    };

    useEffect(() => {

        dispatch(viewReferral({
            page,
            search,
            limit
        }))

    }, [page, search, limit])

    const handleNavigate = (type: string) => {
        if (type === 'view') {
            Navigate(`/referral/view/${selectedUserId}`)
        } else if (type === 'create-caseload') {
            Navigate(`/referral/create-caseload/${selectedUserId}`)
        }
    }
    return (
        <>
            <Box className="innerheader">
                <Grid container direction="row" justifyContent="space-between">
                    <Grid item xs={6} className="searchInputbx">
                        <Search searchCallBack={(e: string) => setSearch(e)} />
                    </Grid>
                    <Grid item xs={6} display={"flex"} gap={2} justifyContent={"end"}>
                        <IconButton
                            size="large"
                            className="iconStyle"
                            color='primary'
                            sx={{ border: `1px solid #6A2382` }}
                        >
                            <FileDownloadOutlinedIcon fontSize="medium" />
                        </IconButton>
                        <IconButton
                            size="large"
                            className="iconStyle"
                            color='primary'
                            sx={{ border: `1px solid #6A2382` }}
                        >
                            <FilterAltIcon fontSize="medium" />
                        </IconButton>
                        <Button className="  btnStyle_v2" size="large" variant="outlined" onClick={() => Navigate("/referral/add")} startIcon={<AddCircleIcon />}>
                            Create Referral
                        </Button>

                    </Grid>
                </Grid>
            </Box>
            <Grid className='tableWrapper'>
                <Table columns={columns}
                    data={referralList?.referrals && referralList?.referrals?.map((item: any, index: number) => {
                        return ({
                            ...item,
                            status: item.submitted_by === CONSTANT.SCOPE.SCHOOL ? (
                                <Typography className="activeStatus">School</Typography>
                            ) : item.submitted_by === CONSTANT.SCOPE.CLIENT_ADMIN ? (
                                <Typography className="deactiveStatus">Admin</Typography>
                            ) : '',
                            age: calculateAge(item.patient_dob),
                            action:
                                <div key={index}>
                                    {/* <PendingOutlinedIcon
                                        aria-controls={`item-menu-${item?.id}`}
                                        aria-haspopup="true"
                                        onClick={handleClick}
                                        style={{ cursor: 'pointer', color: '#6A2382', minWidth: '33px', minHeight: '33px' }} >
                                    </PendingOutlinedIcon> */}

                                    <IconButton
                                        aria-controls={`item-menu-${item?.id}`}
                                        aria-haspopup="true"
                                        className="iconStyleAction"
                                        onClick={(e) => handleClick(e, item?.id)}
                                        color='primary'
                                        sx={{ border: `1px solid #6A2382` }} >

                                        <MoreHorizIcon fontSize="medium" />
                                    </IconButton>


                                    <Menu
                                        id={`item-menu-${item?.id}`}
                                        anchorEl={anchorEl}
                                        keepMounted
                                        open={Boolean(anchorEl)}
                                        onClose={handleClose}
                                        anchorOrigin={{
                                            vertical: 'top',
                                            horizontal: 'left',
                                        }}
                                        transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'right',
                                        }}
                                    >
                                        <MenuItem onClick={() => handleNavigate("view")} >

                                            View
                                        </MenuItem>

                                        <MenuItem
                                            onClick={() => handleNavigate("create-caseload")}
                                        >

                                            Accept Referral
                                        </MenuItem>
                                        <MenuItem
                                        >

                                            Reject
                                        </MenuItem>
                                    </Menu>
                                </div>
                        })

                    }
                    )}
                    paginationTotalRows={referralList.totalPages}
                    pagination={true}
                    setCurrentPage={(e: any) => setPage(e)} />
            </Grid>

        </>
    )
}

export default Index


