import React from 'react'
import { Navigate } from 'react-router-dom'
import privatePages from '../pages/App'
import Auth from '../pages/Auth'
import OpenPdf from '../pages/App/ManageCaseLoad/OpenPdf';
const { Dashboard,Resources,ParentReport,EducationReport,AddEducationReport,EducationReportCaseload,EducationReportView,AddParentReport,ParentReportCaseload,ParentReportView, AdminAcounts, Listing, Caseload, CreateCaseload } = privatePages
const { Login, ForgotPassword, OTP, Register,Profile ,PrivacyNotice} = Auth

const privateRoutes = [
    // { path: "/", exact: true, component: <Navigate to="/home" />, },
    // { path: '/home', component: <Home /> },
    { path: '/dashboard', component: <Dashboard /> },
    { path: '/resources', component: <Resources /> },
    { path: '/report', component: <ParentReport /> },
    { path: '/parent-report/add/:id', component: <AddParentReport /> },
    { path: '/parent-report/edit/:id/:id', component: <AddParentReport /> },
    { path: '/report/create-caseload/:id', component: <ParentReportCaseload /> },
    { path: '/report/view/:id', component: <ParentReportView /> },

    { path: '/report', component: <EducationReport /> },
    { path: '/education-report/add/:id', component: <AddEducationReport /> },
    { path: '/education-report/edit/:id/:id', component: <AddEducationReport /> },
    { path: '/report/create-caseload/:id', component: <EducationReportCaseload /> },
    { path: '/report/view/:id', component: <EducationReportView /> },

    { path: '/user-management/admin/add', component: <AdminAcounts /> },
    { path: '/user-management/admin/edit/:id', component: <AdminAcounts /> },
    { path: '/user-management/clinician/edit/:id', component: <AdminAcounts /> },
    { path: '/user-management/clinician/add', component: <AdminAcounts /> },

    { path: '/profile', component: <Profile /> },
    
    { path: '/user-management/parent/edit/:id', component: <AdminAcounts /> },
    { path: '/user-management/parent/add', component: <AdminAcounts /> },
    { path: '/user-management/parent', component: <Listing /> },

    { path: '/user-management/school/edit/:id', component: <AdminAcounts /> },
    { path: '/user-management/school/add', component: <AdminAcounts /> },
    { path: '/user-management/school', component: <Listing /> },

    { path: '/user-management/admin', component: <Listing /> },
    { path: '/user-management/clinician', component: <Listing /> },

    { path: '/manage-caseload/active', component: <Caseload /> },
    { path: '/manage-caseload/closed', component: <Caseload /> },
    { path: '/manage-caseload/create', component: <CreateCaseload /> },

]

const publicRoutes = [
    { path: "/", exact: true, component: <Navigate to="/login" />},
    { path: '/login', component: <Login /> },
    { path: '/forgot-password', component: <ForgotPassword /> },
    { path: '/open-parent-pdf/:id/:id', component: <OpenPdf/> },
    { path: '/open-education-pdf/:id/:id', component: <OpenPdf/> },
    { path: '/otp', component: <OTP /> },
    { path: '/register', component: <Register /> },
    { path: '/privacy-notice', component: <PrivacyNotice /> },
]
export { privateRoutes, publicRoutes }