import React, { useState } from 'react';
// import './styles/main.scss'; // Import your SCSS file here
import './App.css';
import { styled, alpha, Box, Button, Grid, Paper, IconButton, } from '@mui/material';
import InputBase from '@mui/material/InputBase';
import { Routes, Route } from 'react-router-dom';
import { privateRoutes, publicRoutes } from "./routes"
import AuthLayout from './layout/AuthLayout'
import NonAuthLayout from './layout/NonAuthLayout'
import notFound from './assets/Images/404.webp'
import './assets/scss/common.scss'
import Interceptor from './api/interceptor'
import { isLoaderLoading } from './context';
const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    // marginLeft: theme.spacing(3), //default style
    // marginLeft:'25px', //Custome style
    width: 'auto',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
}));
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

// const MyComponent = styled(Typography)({
//   color: 'darkslategray',
//   backgroundColor: 'red',
//   padding: 8,
//   borderRadius: 4,
// });

// const ASDButton = styled(Button)({
//   color: 'green',
//   backgroundColor: 'red',
//   padding: 8,
//   borderRadius: 4,
// });
const NotFound = () => <div>
  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
    <img src={notFound} />
  </div>
</div>;


function App() {
  const [isLoading, setIsLoading] = useState<any>(false);
  return (
    <isLoaderLoading.Provider value={{ isLoading, setIsLoading }}>
      <div className="App">
        <Interceptor />
        <Routes>
          {privateRoutes.map((route, idx) => (
            <Route
              path={route.path}
              element={<AuthLayout>{route.component}</AuthLayout>}
              key={idx}
            />
          ))}
          {publicRoutes.map((route, idx) => (
            <Route
              path={route.path}
              element={<NonAuthLayout>{route.component}</NonAuthLayout>}
              key={idx}
            />
          ))}
          <Route path="*" element={<NotFound />} /> {/* Not Found Route */}
        </Routes>
      </div>
    </isLoaderLoading.Provider>
  );
}


export default App;