export const ADD_ADMIN_ACCOUNT = "ADD_ADMIN_ACCOUNT"
export const ADMIN_ACCOUNT_RESPONSE = "ADMIN_ACCOUNT_RESPONSE"
export const VIEW_MODULES = "VIEW_MODULES"
export const MODULES_RESPONSE = "MODULES_RESPONSE"
export const USER_LISTING = "USER_LISTING"
export const CLINICIAN_LISTING = "CLINICIAN_LISTING"
export const CLINICIAN_RESPONSE = "CLINICIAN_RESPONSE"
export const USER_RESPONSE = "USER_RESPONSE"
export const GET_USER_DETAIL = "GET_USER_DETAIL"
export const USER_DETAIL_RESPONSE = "USER_DETAIL_RESPONSE"
export const UPDATE_USER_ACCOUNT = "UPDATE_USER_ACCOUNT"

