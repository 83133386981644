import React, { useState, useEffect } from "react";
import {
  Typography,
  Container,
  Grid,
  Card,
  CardContent,
  CardActionArea,
  CardMedia,
  List,
  ListItem,
  Divider,
} from "@mui/material";
import Button from "../../../components/atoms/Button";
import { useLocation, useNavigate } from "react-router-dom";
import { createSelector } from "reselect";
import person from "../../../assets/Images/user.svg";
import { getCaseloadList, removeChat } from "../../../redux/caseload/action";
import { useDispatch, useSelector } from "react-redux";
import constant from "../../../constants/constant";
import nodocument from "../../../assets/Images/nodocument.png";
import moment from "moment";
const CaseloadReducer = (state: any) => state?.CaseloadReducer;
const ComingSoonPage = () => {
  const Navigate = useNavigate();
  const location = useLocation();
  const [page, setPage] = useState(1);
  const pathParts = location.pathname.split("/").filter(Boolean);
  const param = pathParts[1];
  const dispatch = useDispatch();
  const [search, setSearch] = useState("");
  const [limit, setLimit] = useState(10);
  const caseloadData = createSelector(CaseloadReducer, (state) => ({
    caseloadByIdData: state.caseloadData,
  }));

  const { caseloadByIdData } = useSelector(caseloadData);

  useEffect(() => {
    // dispatch(getCaseloadList({ page, search, limit }));
    localStorage.setItem("loggedIn", "true");
    dispatch(
      getCaseloadList({
        page,
        search,
        limit,
        status: `${constant.ACTIVE_REFERRAL_STATUS.CLOSED},${constant.ACTIVE_REFERRAL_STATUS.ACCEPTED}`,
      })
    );
  }, [page, search, limit]);

  return (
    <div className="dashboard">
      <Grid container>
        <Typography variant="body2" color={"#999"} mb={3}>
          Please click on your child’s / young persons profile to review and add
          information.
        </Typography>
        <Grid item xl={12} className="layoutDisplay">
          {caseloadByIdData && caseloadByIdData.length > 0 ? (
            caseloadByIdData &&
            caseloadByIdData.map((cl: any, index: number) => (
              <Card key={index} sx={{ minWidth: "100%", borderRadius: "1rem" }}>
                <CardActionArea
                  onClick={() => {
                    dispatch(removeChat());
                    Navigate(`/manage-caseload/create?id=${cl.id}`);
                  }}
                >
                  <CardMedia
                component="img"
                sx={{ objectFit: 'contain', pt: 2 }}
                height="140"
                image={person}
                alt="green iguana"
              />
                  <CardContent sx={{ p: 0 }}>
                    <Typography
                      gutterBottom
                      variant="h5"
                      component="div"
                      textAlign={"center"}
                      textTransform={"capitalize"}
                      paddingTop={"0rem"}
                    >
                      {cl.patient_name}
                    </Typography>
                    <List>
                      <ListItem className="listSbys">
                        <Typography
                          gutterBottom
                          variant="body2"
                          component="span"
                          fontWeight={"600"}
                        >
                          DOB
                        </Typography>
                        <Typography
                          gutterBottom
                          variant="body2"
                          component="span"
                        >
                          {moment(cl.patient_dob).format("DD/MM/YYYY")}
                        </Typography>
                      </ListItem>
                      <Divider />
                      <ListItem className="listSbys">
                        <Typography
                          gutterBottom
                          variant="body2"
                          component="span"
                          fontWeight={"600"}
                        >
                          Gender
                        </Typography>
                        <Typography
                          gutterBottom
                          variant="body2"
                          component="span"
                        >
                          {cl.patient_gender}
                        </Typography>
                      </ListItem>
                      <Divider />
                      <ListItem className="listSbys">
                        <Typography
                          gutterBottom
                          variant="body2"
                          component="span"
                          fontWeight={"600"}
                        >
                          Current Milestone &nbsp; &nbsp;&nbsp;
                        </Typography>
                        <Typography
                          gutterBottom
                          variant="body2"
                          component="span"
                        >
                          {" "}
                          {
                            {
                              [constant.PATHWAY_STATUS.CASELOAD_CREATED]:
                                "Referral Received",
                              [constant.PATHWAY_STATUS.SCHOOL_REPORT_RECEIVED]:
                                "Educational Report Received",
                              [constant.PATHWAY_STATUS.PARENT_REPORT_RECEIVED]:
                                "Parent/Carer\nReport Received",
                              [constant.PATHWAY_STATUS.TASK]: "Task",
                              [constant.PATHWAY_STATUS.READY_FOR_MDT_REVIEW]:
                                "Ready For Clinical Review",
                              [constant.PATHWAY_STATUS.CASELOAD_CLOSED]:
                                "Outcome Agreed",
                            }[cl.pathway_status?.slice(-1)[0]]
                          }
                        </Typography>
                      </ListItem>
                      <Divider />
                      <ListItem className="listSbys">
                        <Typography
                          gutterBottom
                          variant="body2"
                          component="span"
                          fontWeight={"600"}
                        >
                          Status
                        </Typography>
                        <Typography
                          gutterBottom
                          variant="body2"
                          component="span"
                          className={
                            cl.pathway_status?.length > 0
                              ? cl.pathway_status?.slice(-1)[0] !==
                                constant.PATHWAY_STATUS.CASELOAD_CLOSED
                                ? "activeStatus"
                                : "deactiveStatus"
                              : ""
                          }
                        >
                          {
                            {
                              [constant.PATHWAY_STATUS.CASELOAD_CREATED]:
                                "Ongoing",
                              [constant.PATHWAY_STATUS.SCHOOL_REPORT_RECEIVED]:
                                "Ongoing",
                              [constant.PATHWAY_STATUS.PARENT_REPORT_RECEIVED]:
                                "Ongoing",
                              [constant.PATHWAY_STATUS.TASK]: "Ongoing",
                              [constant.PATHWAY_STATUS.READY_FOR_MDT_REVIEW]:
                                "Ongoing",
                              [constant.PATHWAY_STATUS.CASELOAD_CLOSED]:
                                "Closed",
                            }[cl.pathway_status?.slice(-1)[0]]
                          }
                        </Typography>
                      </ListItem>
                    </List>
                  </CardContent>
                </CardActionArea>
              </Card>
            ))
          ) : (
            <Grid className="nodocumentImg" sx={{ gridColumn: "1 / -1" }}>
              <img src={nodocument} alt="No-Document" />
              <Typography variant="h5">No Referral Found</Typography>
            </Grid>
          )}
        </Grid>
      </Grid>
    </div>
  );
};

export default ComingSoonPage;
