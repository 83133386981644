import React, { FC, useEffect, useLayoutEffect, useRef, useState } from 'react';
import {
    Card,
    Grid,
    Typography,
    Radio,
    RadioGroup,
    FormControlLabel,
    FormControl,
    Checkbox,
    FormLabel,
    FormGroup,
    MenuItem,
    InputLabel,
    Select,
    Button,
    Divider
} from '@mui/material';
import CustomSelect from '../../../../components/atoms/Select'
import classes from './index.module.scss';
import atoms from '../../../../components/atoms'
import { OtherHouses } from '@mui/icons-material';
import moment from 'moment';
import { useLocation } from 'react-router-dom';
interface MyComponentProps extends React.HTMLAttributes<HTMLElement> {
    type: any;
    index: number;
    question: any;
    answer: any;
    options: any;
    key: string; // Note: `key` is already part of `IntrinsicAttributes` and should not be redefined.
    required: boolean;
    question_number: any;
    questions: any;
    isError: boolean;
    ques: any;
    setIsDisableContinue: any;
    totalQuestion: number;
    handleAnswerChange: (index: number, value: any, key: string) => void;
}
const { TextField } = atoms

const CommonField: React.FC<MyComponentProps> = ({ type, nesting, nestingObj, index, question, answer, options, key, required, question_number, totalQuestion, questions, isError, handleAnswerChange, ques, setIsDisableContinue }: any) => {
    const inputRef: any = useRef(null);
    const location = useLocation()
    const pathParts = location.pathname.split('/').filter(Boolean);
    const param = pathParts[1]
    const id = pathParts[2]
    const parentReportId = pathParts[3]
    const [checkedItems, setCheckedItems] = useState<string[]>([]);
    const [newMemberCount, setNewMemberCount] = useState(1);
    const [hideCss, setHideClass] = useState(false);
    const [trigger, settrigger] = useState(false);
    const queryParams = new URLSearchParams(window.location.search)
    const patientName: any = queryParams.get('name')
    const currentDate = moment().format('DD-MM-YYYY');
    const addNewMember = () => {
        const newMembers = [
            { cid: 1, answer: '', id: newMemberCount, name: 'Name', type: "text", placeholder: 'Enter Name', fullWidth: true, col: 3 },
            { cid: 1, answer: '', id: newMemberCount, name: 'Age', type: "number", placeholder: 'Age', fullWidth: true, col: 1, maxlength:3},
            { cid: 1, answer: '', id: newMemberCount, name: '*Relationship', type: "text", placeholder: `Relationship to ${patientName} / young person`, fullWidth: true, col: 3 },
            { cid: 1, answer: '', id: newMemberCount, name: 'Gender', type: "radio", placeholder: '', options: [{ id: 1, name: 'Male' }, { id: 2, name: 'Female' }, { id: 3, name: 'Other' }], col: 4 },
            { cid: 1, answer: '', id: newMemberCount, name: 'X', type: "button", col: 1, jsonStyle: 'onlybutton' },
        ];
        ques.nestingObj.onetype.push(...newMembers);
        setNewMemberCount(prevCount => prevCount + 1);
        settrigger(false);
    };

 const removeMember = (index: number) => {
    const updatedMembers = ques.nestingObj.onetype.filter((element: any, i: number) => {
        return element.id !== index;
    });

    // Check if there's at least one record left
    if (updatedMembers.length === 0) {
        // You may handle this scenario based on your requirements.
        // For example, you might throw an error, add a default record, or prevent deletion.
        // Here, I'm assuming you want to keep at least one record by not allowing deletion.
        console.error("Cannot remove the last member.");
        return;
    }

    ques.nestingObj.onetype = updatedMembers;
    settrigger(true);
};


    const label = { inputProps: { 'aria-label': 'Checkbox demo' } };
    useEffect(() => {
        settrigger(false);
    }, [trigger])

    const isEmailCheck = (val: string) => {
        let regEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!regEmail.test(val)) {
            return true
        } else {
            return false
        }
    }

    const handleEmailInput = (index: number, e: string, key: string) => {
        handleAnswerChange(index, e, key)
        if (isEmailCheck(e)) {
            setIsDisableContinue(true)
        }
        else {
            setIsDisableContinue(false)
        }
    }


    const handleChange = (val: any, ind: any) => {
        ques.nestingObj.onetype[ind].answer = val
        ques.nestingObj.onetype[ind].name = ques.nestingObj.onetype[ind].name.replace('[forename]', patientName)


        let temp = ques.nestingObj?.onetype.some((i: any) => i?.answer === 'Yes' || i?.answer === 'Resuscitation needed' || i?.answer === 'Normal' || i?.answer === 'Ventouse/ Forceps' || i?.answer === 'C-Section' || i?.answer === 'Admitted to special care' || i?.answer === 'Admitted to special care' || i?.answer === 'Feeding difficulties')
        if (temp) {
            setHideClass(true)
        } else {
            setHideClass(false)
        }
        handleAnswerChange(index, ques.nestingObj.onetype, key);
        if (val === 'Yes' || val === 'Resuscitation needed' || val === 'Normal' || val === 'Ventouse/ Forceps' || val === 'C-Section' || val === 'Admitted to special care' || val === 'Admitted to special care' || val === 'Feeding difficulties') {
            setHideClass(true)

        }

    };

    useLayoutEffect(() => {
        if (ques.nesting && param === 'edit') {
            const valuesToCheck = [
                'Yes',
                'Resuscitation needed',
                'Normal',
                'Ventouse/ Forceps',
                'C-Section',
                'Admitted to special care',
                'Feeding difficulties'
            ];
    
            const shouldHide = ques.nestingObj.onetype.some((x: any) =>
                x.cid === 17 && valuesToCheck.includes(x.answer)
            );
            
            setHideClass(shouldHide);
        }
    }, [ques, param]);

    
    const handleCheckboxChange = (value: string) => {
        const currentIndex = ques.nestingObj.onetype[0].answer?.indexOf(value);
        const newCheckedItems = [...ques.nestingObj.onetype[0].answer];

        if (currentIndex === -1) {
            newCheckedItems.push(value);

        } else {
            newCheckedItems.splice(currentIndex, 1);
        }
        if (newCheckedItems.length > 0) {
            setHideClass(true)
        } else if (newCheckedItems.length < 0) {
            setHideClass(false)
        } else {
            setHideClass(false)
        }

        const updatedobj = {...ques.nestingObj.onetype[0].answer,...newCheckedItems}
        setCheckedItems(updatedobj);
        ques.nestingObj.onetype[0].answer = newCheckedItems
        handleAnswerChange(index, ques.nestingObj.onetype[0].answer, key);
    };

    return (
        <Card className={classes.card} key={index}>
                           {ques.question_id === 'ques10' ?  <Typography variant='h5' fontFamily={'poppins'}>Family History</Typography> : null}
            <Typography className={classes.questionTitle}>Question {question_number + index + 1} to {totalQuestion + question_number}</Typography>
            <Grid display={'flex'} sx={{ gap: '5px' }}>
                <Typography className={`${classes.questionText} ${!required && classes.isDisabled}`}>

                    Q.{question_number + index + 1}
                </Typography>
                <Typography className={`${classes.questionText} ${!required && classes.isDisabled}`}>

                    {question}  <span style={{ color: '#f00' }}>*</span>
                </Typography>
            </Grid>
            {type === 'radio' && (
                <RadioGroup
                    value={answer === patientName ? '[forename]' : answer}
                    onChange={(e) => handleAnswerChange(index, e.target.value === '[forename]' ? e.target.value.replace('[forename]', patientName) : e.target.value, key)}
                    className={classes.radioGroup}>
                    {options && options.map((option: string, optionIndex: number) => (
                        <FormControlLabel disabled={!required}  key={optionIndex} value={option} control={<Radio ref={inputRef}   className={classes.formControlLabel} />} label={option.replace('[forename]', patientName)} />
                    ))}
                </RadioGroup>
            )}
            {(type === "text" || type === "number" || type === "date" || type === "textArea") && (
                <TextField
                    fullWidth
                    ref={inputRef}
                    value={type === "date" ? new Date().toISOString().split('T')[0] : answer}
                    disabled={type === "date" ? required : !required}
                    autoFocus={index === 0 ? true : false}
                    type={type === "textArea" ? 'text' : type}
                    onChange={(e: any) => handleAnswerChange(index, e.target.value, key)}
                    placeholder={
                        type === "date" ? "DD/MM/YYYY" :
                        type === "email" ? "Please Enter Email" :
                        type === "textArea" && question_number === 32 ? "Please add information such as zoning out, experiencing difficulties with making a choice, finding it difficult to prioritize tasks, forgetting to eat/drink" :
                        question_number === 9 ? 'Please detail if there was any delay or history of a loss of skill such as talking, sitting or walking' :
                        type === "textArea" ? "Please 500 Characters" :
                        "Please Enter"
                    }
                    variant="outlined"
                    multiline={type === "textArea"}
                    rows={type === "textArea" ? 4 : 1}
                    inputProps={{
                        maxLength: type === "textArea" ? 500 : undefined,
                        min: type === "date" ? currentDate : undefined,
                        max: type === "date" ? new Date().toISOString().split('T')[0] : undefined
                    }}
       
                />
           
            )}
            {type === "email" &&
                <TextField
                    fullWidth
                    type={"email"}
                    ref={inputRef}
                    value={answer}
                    autoFocus={index === 0 ? true : false}
                    disabled={!required}
                    variant="outlined"
                    placeholder="Enter Email"
                    onChange={(e: any) => handleEmailInput(index, e.target.value, key)}
                    inputProps={{
                        pattern: '[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+[.]{1}[a-zA-Z]{2,}$',
                    }}
                    error={(answer && isEmailCheck(answer)) && 'Please enter a valid email address'}
                />

            }
            {(type === "dropdown" && ques.question_id === 'ques22') &&
                <CustomSelect
                    fullWidth
                    ref={inputRef}
                    placeholder={"Select ..."}
                    isMulti={false}
                    autoFocus={index === 0 ? true : false}
                    disableCloseOnSelect={false}
                    defaultValue={answer}
                    options={options}
                    onChange={(e: any) => handleAnswerChange(index, e, key)}
                />
            }
            {(type === "form1" && ques.nesting === true) &&

                <Grid container spacing={2}>
                    {ques.nestingObj.onetype.map((obj: any, index: number) => {
                        return(

                            <Grid item xs={obj.col} key={obj.id + obj.name} className={obj.jsonStyle} sx={{
                                '.MuiFormControl-root' : {
                                    width:'100%'
                                }
                            }}>
                                {obj.type !== 'radio' ? (
                                    <div style={{ display: !hideCss && obj.jsonStyle === 'more' ? 'none' : 'block',width:'100%' }} >
                                        <InputLabel sx={{ fontWeight: '600', color: '#000', fontFamily: 'poppins', fontSize: '0.9rem' }}>{obj.name}</InputLabel>
    
                                        <TextField style={{width:'100%'}} disabled={obj.disabled} placeholder={obj.placeholder} value={obj?.answer} onChange={(event: any) => handleChange(event.target.value, index)} rows={obj.type === "textArea" ? 4 : 1} />
    
                                    </div>
    
                                ) : (
                                    <FormControl>
                                        <FormLabel id={obj.id}>{obj.name.replace('[forename]', patientName)}</FormLabel>
    
                                        <RadioGroup
                                            value={obj.answer}
                                            aria-labelledby={obj.id}
                                            onChange={(event: any) => handleChange(event.target.value, index)}
                                            name={obj.name}
                                        >
                                            {obj.options &&
                                                obj.options.map((option: any, optionIndex: number) => (
                                                    <FormControlLabel
                                                        disabled={option.disabled === true ? true : false}
                                                        key={optionIndex + obj.name}
                                                        checked={option.checked}
                                                        value={option.name}
                                                        control={<Radio 
                                                            ref={inputRef} 
                                                            className={`${classes.formControlLabel} ${option.disabled === true ? classes.disabledColor : ''}`} 
                                                    
                                                         />}
                                                        label={option.name}
                                                    />
                               
    
                                                ))}
    
                                        </RadioGroup>
                                    </FormControl>
                                )}
                                {obj.type === 'button' && (
                                    <Button variant="contained" onClick={() => removeMember(obj.id)}>
                                        {obj.name}
                                    </Button>
                                )}
                            </Grid>
                        )
                    })}
                    {ques.nestingObj.Action && ques.nestingObj.Action.map((obj: any, index: number) => (
                        <Grid item xs={12} key={obj.id + index}>
                            {obj.type === 'button' && (
                                <Button variant="contained" onClick={addNewMember}>
                                    {obj.name}
                                </Button>
                            )}
                        </Grid>
                    ))}
                </Grid>



            }
            {(type === "form2" && ques.nesting === true) &&

                <Grid container spacing={2}>
                    {ques.nestingObj.onetype.map((obj: any, index: number) =>

                        <Grid item xs={obj.col} key={obj.id + obj.name} className={obj.jsonStyle}>
                            {obj.type === 'textArea' && (
                                <div style={{ display: !hideCss && obj.jsonStyle === 'more' ? 'none' : 'block' }}>
                                    <InputLabel sx={{ fontWeight: '600', color: '#000', fontFamily: 'poppins', fontSize: '0.9rem' }}>{obj.name}</InputLabel>
                                    <TextField {...obj} value={obj.answer}  onChange={(event: any) => handleChange(event.target.value, index)} rows={obj.type === "textArea" ? 4 : 1} />

                                </div>
                            )}
                            {obj.type === 'checkbox' && (
                                <FormGroup>
                                    {obj.options && obj.options.map((option: any, optionIndex: number) => {
                                        return (
                                            <FormControlLabel
                                                disabled={!required}
                                                key={optionIndex + obj.name}
                                                value={answer}
                                                control={<Checkbox
                                                    ref={inputRef}
                                                    checked={answer[0]?.answer?.includes(option.name) || answer?.includes(option.name)}
                                                    onChange={() => handleCheckboxChange(option.name)}
                                                    className={classes.formControlLabel}
                                                />}
                                                label={option.name}
                                            />
                                        );
                                    })}
                                </FormGroup>
                            )}
                        </Grid>


                    )}
                </Grid>

            }

            {(type === "dropdown" && ques.question_id !== 'ques22') &&
                <FormControl fullWidth>
                    <Select
                        className={classes.select}
                        ref={inputRef}
                        name="gender"
                        value={answer}
                        disabled={!required}
                        placeholder={"select"}
                        onChange={(e) => handleAnswerChange(index, e.target.value, key)}
                        displayEmpty
                    >
                        <MenuItem value="" disabled>
                            Select
                        </MenuItem>
                        {options && options.map((option: string, optionIndex: number) => (
                            <MenuItem key={optionIndex} value={option}  >{option}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
            }
            {isError && answer === "" && required && <p className={classes.errorColor}>This field is required</p>}
        </Card>
    );
}

export default CommonField