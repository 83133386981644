import { Card } from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  styled,
  alpha,
  Box,
  Button,
  Grid,
  Paper,
  IconButton,
  MenuItem,
  Menu,
  ListItemIcon,
  Typography,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import InputBase from "@mui/material/InputBase";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import Table from "../../../components/atoms/Table";
import { useLocation, useNavigate } from "react-router-dom";
import { changeTab, getCaseloadList } from "../../../redux/action";
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";
import CONSTANT from "../../../constants/constant";
import moment, { min } from "moment";
import EditIcon from "@mui/icons-material/Edit";
import FilterAltIcon from "@mui/icons-material/FilterAlt";

import Search from "../../../components/atoms/Search";
import PendingOutlinedIcon from "@mui/icons-material/PendingOutlined";
import { formatNHSNumber } from "../../../constants/utils";
const Index = () => {
  const location = useLocation();
  const Navigate = useNavigate();
  const dispatch = useDispatch();
  const pathParts = location.pathname.split("/").filter(Boolean);
  const param = pathParts[1];
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [limit, setLimit] = useState(10);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedId, setSelectedId] = useState<string | null>(null);
  const caseloadReducer = (state: any) => state?.CaseloadReducer;
  const [columns, setColumns] = useState([
    {
      name: "#",
      cell: (item: any, ind: number) => <span>{ind + 1}</span>,
      width: "50px",
    },
    {
      name: "Referral ID",
      cell: (item: any, ind: number) => <span>{item.caseload_unique_id}</span>,
      sortable: false,
      minWidth: "15%",
    },
    {
      name: "Patient Name",
      cell: (item: any, ind: number) => <span>{item.patient_name}</span>,
      selector: (item: any, ind: number) => item.patient_name,
      sortable: true,
      minWidth: "15%",
    },
    {
      name: "Patient DOB",
      cell: (item: any, ind: number) => (
        <span>{moment(item.patient_dob).format("DD/MM/YYYY")}</span>
      ),
      sortable: false,
      minWidth: "15%",
    },
    {
      name: "NHS Number",
      cell: (item: any, ind: number) => <span>{formatNHSNumber(item?.nhs_number)}</span>,
      sortable: false,
    },
    {
      name: "Pathway Status",
      cell: (item: any, ind: number) => <span>{getStatusDescription(item, 'pathway')}</span>,
      sortable: false,
    },
    {
      name: "Next Milestone",
      cell: (item: any, ind: number) => <span>{getStatusDescription(item, 'milestone')}</span>,
      sortable: false,
    },
    {
      name: "action",
      cell: (item: any, ind: number) => <span>{item.action}</span>,
      sortable: false,
    },
  ]);
  const caseloadData = createSelector(caseloadReducer, (state) => ({
    caseloadList: state.caseloadData,
  }));
  const { caseloadList } = useSelector(caseloadData);
  const getStatusDescription = (item: any, type: string) => {
    let compareType = type === 'pathway' ? item.pathway_status[0] : type === 'milestone' ? Number(item.pathway_status[0]) + 1 : ''
    switch (compareType.toString()) {
      case CONSTANT.PATHWAY_STATUS.CASELOAD_CREATED:
        return "Referral Received";
      case CONSTANT.PATHWAY_STATUS.CASELOAD_CLOSED:
        return "Referral Closed";
      case CONSTANT.PATHWAY_STATUS.PARENT_REPORT_RECEIVED:
        return "Parent/Carer Report Received";
      case CONSTANT.PATHWAY_STATUS.READY_FOR_MDT_REVIEW:
        return "Ready For Clinical Review";
      case CONSTANT.PATHWAY_STATUS.SCHOOL_REPORT_RECEIVED:
        return "Educational Setting Report Received";
      case CONSTANT.PATHWAY_STATUS.TASK:
        return "Task";
      default:
        return "Unknown";
    }
  }
  useEffect(() => {
    dispatch(changeTab('Active Referral'))
    dispatch(getCaseloadList({ page, search, limit }));
  }, [param, page, search, limit]);

  const handleClick = (event: any, id: string) => {
    setAnchorEl(event.currentTarget);
    setSelectedId(id)
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Box></Box>
      <Box className="innerheader">
        <Grid container direction="row" justifyContent="space-between">
          <Grid item xs={6} className="searchInputbx">
            <Search searchCallBack={(e: string) => setSearch(e)} />
          </Grid>
          <Grid item xs={6} display={"flex"} gap={2} justifyContent={"end"}>
            {/* <IconButton
              size="large"
              className="iconStyle"
              color="primary"
              sx={{ border: `1px solid #6A2382` }}
            >
              <FileDownloadOutlinedIcon fontSize="medium" />
            </IconButton>

            <IconButton
              size="large"
              className="iconStyle"
              color="primary"
              sx={{ border: `1px solid #6A2382` }}
            >
              <FilterAltIcon fontSize="medium" />
            </IconButton> */}
          </Grid>
        </Grid>
      </Box>

      <Grid className="tableWrapper">
        <Table
          columns={columns}
          data={
            caseloadList?.caseloads &&
            caseloadList?.caseloads?.map((item: any, index: number) => {
              return {
                ...item,
                status:
                  item.status === CONSTANT.STATUS.ACTIVE ? (
                    <Typography className="activeStatus">Active</Typography>
                  ) : (
                    <Typography className="deactiveStatus">Deactive</Typography>
                  ),

                action: (
                  <div key={index}>

                    {/* <PendingOutlinedIcon
                                    aria-controls={`item-menu-${item?.id}`}
                                    aria-haspopup="true"
                                    onClick={handleClick}
                                    style={{ cursor: 'pointer', color: '#6A2382', minWidth: '33px', minHeight: '33px' }} >
                                </PendingOutlinedIcon> */}

                    <IconButton
                      size="large"
                      className="iconStyleAction"
                      color="primary"
                      aria-controls={`item-menu-${item?.id}`}
                      aria-haspopup="true"
                      onClick={(e) => handleClick(e, item?.id)}
                      sx={{ border: `1px solid #6A2382` }}
                    >

                      <MoreHorizIcon fontSize="medium" />
                    </IconButton>

                    <Menu
                      id={`item-menu-${item?.id}`}
                      anchorEl={anchorEl}
                      keepMounted
                      open={Boolean(anchorEl)}
                      onClose={handleClose}
                      anchorOrigin={{
                        vertical: "top",
                        horizontal: "left",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                    >

                      {/* <MenuItem
                      onClick={() => Navigate(param === "admin" ? `/user-management/admin/edit/${item?.id}` : `/user-management/clinician/edit/${item?.id}`)}
                      ></MenuItem> */}
                      <MenuItem onClick={() => Navigate(`/manage-caseload/create?id=${selectedId}`)}>
                        View CaseLoad
                      </MenuItem>


                    </Menu>
                  </div>
                ),
              };
            })
          }
          paginationTotalRows={caseloadList.totalPages}
          pagination={true}
          setCurrentPage={(e: any) => setPage(e)}
        />
      </Grid>
    </>
  );
};

export default Index;
