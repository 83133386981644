import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import { Box, Button, capitalize } from '@mui/material';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Badge from '@mui/material/Badge';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import AccountCircle from '@mui/icons-material/AccountCircle';
import MailIcon from '@mui/icons-material/Mail';
import NotificationsIcon from '@mui/icons-material/Notifications';
import MoreIcon from '@mui/icons-material/MoreVert';
import './Header.css';
import css from "./index.module.scss"
import Logo from '../../../assets/Images/logo.svg'
import { LogoutAdmin } from '../../../redux/action';
import { useLocation, useNavigate, Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from "reselect";
import Avatar from '@mui/material/Avatar/Avatar';

export interface CurrentUserData {
  id: string;
  first_name: string,
  last_name: string,
  email:string
}


const Header = ({ handleOpenDrawer }: any) => {
  const dispatch = useDispatch()
  const location = useLocation();
  const navigate = useNavigate()
  const handleDrawerToggle = () => {
    handleOpenDrawer()
  };
  const layoutReducer = (state: any) => state?.LayoutReducer
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] =
    React.useState<null | HTMLElement>(null);

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const layoutData = createSelector(
    layoutReducer,
    (state) => ({
      currentTab: state.currentTab,

    })
  );
  const { currentTab } = useSelector(layoutData);

  const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuOpenProfile = () => {
    navigate('/profile')
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };
  

  const handleMobileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const menuId = 'primary-search-account-menu';
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleMenuOpenProfile}>Profile</MenuItem>
      <MenuItem onClick={handleMenuClose}>My account</MenuItem>
      <MenuItem onClick={() => {
        dispatch(LogoutAdmin(navigate))
        handleMenuClose()
      }}>Logout</MenuItem>
    </Menu>
  );

  const mobileMenuId = 'primary-search-account-menu-mobile';


  const userData: CurrentUserData = JSON.parse(
    localStorage.getItem("loginResponse") || 'null'
  );

  const pages = ['dashboard', 'resources'];


  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem>
        <IconButton size="large" aria-label="show 4 new mails" color="inherit">
          <Badge badgeContent={4} color="error">
            <MailIcon />
          </Badge>
        </IconButton>
        <p>Messages</p>
      </MenuItem>
      <MenuItem>
        <IconButton
          size="large"
          aria-label="show 17 new notifications"
          color="inherit"
        >
          <Badge badgeContent={17} color="error">
            <NotificationsIcon />
          </Badge>
        </IconButton>
        <p>Notifications</p>
      </MenuItem>

      <MenuItem onClick={handleProfileMenuOpen}>
        <IconButton
          size="large"
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <AccountCircle />
        </IconButton>
        <p>Profile</p>
      </MenuItem>

    </Menu>
  );
  const handleNavigate = () => {
    navigate(`/referral/add`);
  };
  const backtoFun = () => {
    navigate('/dashboard')
}

  return (

    <Box className={css.constianer}>
      <Box className="s-layout__content header-bg justify-content-between">
        {/* style={{width:'100%'}} */}
        <Box sx={{ flexGrow: 2 }} >
          <AppBar position="static" sx={{ width: '100%', display: { xs: 'block ', sm: 'block' }, background: 'transparent', color: '#637381', boxShadow: 'none', alignItems: 'center' }} >
          <Toolbar className={css.logoWrapper}>
           
              <Avatar src={Logo} alt="Logo" style={{ marginRight: '2rem', width: '200px', height: 'auto' }} onClick={() => backtoFun()}/>
              {/* <Button sx={{ color: location.pathname === '/referral/add' ? 'primary' : 'inherit', fontWeight: '600' }} component={Link} to="/referral/add">Overview</Button> */}
              { pages.map((page) => (
                <MenuItem key={page} onClick={()=> navigate(`/${page}`)} sx={{mr:2, 
                backgroundColor:location.pathname === `/${page}` ? '#6A2382' : null,
                color:location.pathname === `/${page}` ? '#fff' : '#000',borderRadius:'1rem' }}>
                  <Typography textAlign="center" fontFamily={'poppins'} textTransform={'capitalize'}>{page}</Typography>
                </MenuItem>
              ))} 

              {location.pathname !== '/dashboard' && location.pathname !== '/resources' && <Typography

                sx={{
                  mr:2,
                  color: '#fff',
                  background:'#6A2382',
                  fontSize:'0.9rem',
                  borderRadius:'1rem',
                  fontFamily: 'poppins',
                  padding: '0.2rem 1rem'
                }}
              >
                {currentTab}
              </Typography>}
           

              <Box sx={{ flexGrow: 1 }} />
              <Box sx={{ display: { xs: 'none', md: 'flex' } }} className='userAccountBadge'  component="button"  onClick={handleProfileMenuOpen}>
                
                
                  
                <IconButton
                  size="large"
                  edge="end"
                  aria-label="account of current user"
                  aria-controls={menuId}
                  aria-haspopup="true"

                  color="inherit"
                  className='header_icon'
                  sx={{mr:1}}
                >
                  <AccountCircle />
                </IconButton>
                <Box className="u_details">
                  <Typography variant="h6" textTransform={'capitalize'}>{userData?.first_name} {userData?.last_name}</Typography>
                  <Typography variant="body2">{userData?.email}</Typography>
                  </Box>
              </Box>
              <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
                <IconButton
                  size="large"
                  aria-label="show more"
                  aria-controls={mobileMenuId}
                  aria-haspopup="true"
                  onClick={handleMobileMenuOpen}
                  color="inherit"
                >
                  <MoreIcon />
                </IconButton>
              </Box>
            </Toolbar>
          </AppBar>
          {renderMobileMenu}
          {renderMenu}

        </Box>
      </Box>
    </Box>
  );
}

export default Header