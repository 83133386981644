export const VIEW_REFERRAL = "VIEW_REFERRAL"
export const EDIT_REFERRAL = "EDIT_REFERRAL"
export const ADD_REFERRAL = "ADD_REFERRAL"
export const REFERRAL_RESPONSE = "REFERRAL_RESPONSE"
export const GET_REFERRAL_CASELOAD_DETAIL = "GET_REFERRAL_CASELOAD_DETAIL"
export const REFERRAL_CASELOAD_RESPONSE = "REFERRAL_CASELOAD_RESPONSE"
export const GET_REFERRAL_ALL_DETAIL = "GET_REFERRAL_ALL_DETAIL"
export const GET_REFERRAL_ALL_RESPONSE = "GET_REFERRAL_ALL_RESPONSE"

export const GET_GP_LIST = "GET_GP_LIST"
export const GP_LIST_RESPONSE = "GP_LIST_RESPONSE" 
