import { Login_Admin, Logout_Admin, Change_Password,UPDATE_USER_ACCOUNT_DETAIL, Get_Profile,VERIFY_LINK, Get_logoType, Reset_Password, FORGOT_PASSWORD, SEND_OTP,REGISTER_PASSWORD, SET_BOOLEAN } from './actionType'
export const login = (data: { username: string; password: string; }, navigate: any) => {
    return {
        type: Login_Admin,
        data,
        navigate
    }
}
export const forgotPassword = (payload: any, navigate: any) => {
    return {
        type: FORGOT_PASSWORD,
        payload,
        navigate
    }
}
export const Register = (payload: any, navigate: any) => {
    return {
        type: REGISTER_PASSWORD,
        payload,
        navigate
    }
}
export const VerifyLink = (payload: any) => {
    return {
        type: VERIFY_LINK,
        payload
    }
}
export const sendOtp = (payload: any, navigate: any) => {
    return {
        type: SEND_OTP,
        payload,
        navigate
    }
}
export const LogoutAdmin = (navigate: any) => {

    return {
        type: Logout_Admin,
        navigate
    }
}
export const ChangePassword = (payload: any, navigate: any) => {

    return {
        type: Change_Password,
        payload,
        navigate
    }
}
export const ProfileDisplay = () => {
    return {
        type: Get_Profile,
    }
}
export const ResetPassword = (data: { new_password: string; confirm_password: string; }, reset_token: string, navigate: any) => {
    return {
        type: Reset_Password,
        data,
        reset_token,
        navigate
    }
}
export const homeLogo = (logotype: boolean) => {

    return {
        type: Get_logoType,
        logotype
    }
}

export const setBoolean = (value:boolean) => ({
    type: SET_BOOLEAN,
    payload: value
  });
  

export const updateAccountDetail = (id:string,data: any,navigate:any,logoutBoolean:boolean) => {
    return {
        type: UPDATE_USER_ACCOUNT_DETAIL,
        data,
        navigate,
        id,
        logoutBoolean
    }
}
