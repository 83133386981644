import React from 'react'
 import {useLocation } from 'react-router-dom';
import './Wrapper.css'

const Wrapper = ({ children }: any) => {
    const location = useLocation()
    return (
        <>
        <div className="wrapper-layout " 
        // style={
        //     {backgroundColor: location.pathname === '/referral/add' 
        //     || location.pathname === '/manage-caseload/create' || location.pathname === '/manage-caseload/details'  ? '#fafafa' : 'initial',
        //  }}
        style={{background:'#fafafa'}}
         >
            <main className="wrapper-main" >
                <div className="wrapper-card" >
                    {children} 
                </div>
            </main>
        </div>
        </>
    )
}

export default Wrapper