/* eslint-disable react-hooks/rules-of-hooks */
import React, { useContext, useState } from "react";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import loaderGif from "../assets/Images/loader.svg";
import { Box } from "@mui/material";
import { isLoaderLoading } from "../context";
import { HEADER_TOKEN } from "../constants/defaultValues";

const interceptor = () => {
  const { isLoading, setIsLoading } = useContext<any>(isLoaderLoading);
  const navigate = useNavigate();
  const location = useLocation();
  const pathParts = location.pathname.split("/").filter(Boolean);
  const openPdfurlpath = pathParts[0];
  const pdfAccessToken = pathParts[2];
  axios.interceptors.request.use(
    (config) => {
      setIsLoading(true);
      const AUTH_TOKEN = localStorage.getItem("accessToken");
      let isLogin =
        config &&
        config.url &&
        config.url.split("/")[config.url.split("/").length - 1] === "login";
      let isOtp =
        config &&
        config.url &&
        config.url.split("/")[config.url.split("/").length - 1] ===
          "validate-otp";
      let isSetPassword =
        config &&
        config.url &&
        config.url.split("/")[config.url.split("/").length - 1] ===
          "set-password";
      let isForgotPassword =
        config &&
        config.url &&
        config.url.split("/")[config.url.split("/").length - 1] ===
          "forgot-password";
      let isOpenReferral =
        config &&
        config.url &&
        config.url.split("/")[config.url.split("/").length - 1] ===
          "referral-form";
      let isOpenReferralData =
        config &&
        config.url &&
        config.url.split("/")[config.url.split("/").length - 1] === "data";
      let isOpenVerifyDomain =
        config &&
        config.url &&
        config.url.split("/")[config.url.split("/").length - 1] ===
          "verify-domain";
      let isOpenSubmit =
        config &&
        config.url &&
        config.url.split("/")[config.url.split("/").length - 1] ===
          "submit-open";
      let isVerifyLink =
        config &&
        config.url &&
        config.url.split("/")[config.url.split("/").length - 1] ===
          "verify-link";

      const isPath = (path: any) =>
        window.location.pathname.split("/")[1] === path;

      // Check and set headers
      if (config?.headers) {
        if (!isLogin && !isOtp && !isSetPassword && !isForgotPassword) {
          config.headers.Authorization = `Bearer ${AUTH_TOKEN}`;
        }

        if (
          openPdfurlpath === "open-parent-pdf" ||
          openPdfurlpath === "open-education-pdf"
        ) {
          config.headers.Authorization = `Bearer ${pdfAccessToken}`;
        }
      }

      if (
        isOpenReferralData ||
        isOpenVerifyDomain ||
        isOpenSubmit ||
        isVerifyLink ||
        isLogin ||
        isOtp ||
        isSetPassword ||
        isForgotPassword ||
        isOpenReferral
      ) {
        config.headers.Authorization = `${HEADER_TOKEN.Authorization}`;
      }

      if (isVerifyLink) {
        config.headers.Authorization = `${HEADER_TOKEN.Authorization}`;
      }

      // Navigate to login if conditions are met
      const pathsToExclude = [
        "otp",
        "register",
        "forgot-password",
        "open-parent-pdf",
        "open-education-pdf",
      ];
      const isExcludedPath = pathsToExclude.some(isPath);

      if (!AUTH_TOKEN && !isLogin && !isExcludedPath) {
        navigate("/login");
      }
      // isLoading = true
      return config;
    },
    (error) => {
      setIsLoading(false);
      return Promise.reject(error);
    }
  );

  axios.interceptors.response.use(
    (response) => {
      setIsLoading(false);
      return response;
    },
    (error) => {
      setIsLoading(false);
      if (axios.isAxiosError(error)) {
        if (error.response) {
          if (error.response.status === 401) {
            navigate("/login");
            // window.location.reload()
          } else if (error.response.status === 503) {
            navigate("/login");
            window.location.reload();
          }
        }
      }
      return Promise.reject(error);
    }
  );

  return (
    <>
      {isLoading && (
        <Box className="loadergif" component={"div"}>
          <img alt="loader" src={loaderGif} />{" "}
        </Box>
      )}
    </>
  );
};
export default interceptor;
