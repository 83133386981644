import React from 'react'
import DataTable from 'react-data-table-component';
import Pagination from '../Pagination';

import './style.scss'
const Index = ({
  columns, data,
  selectableRows,
  handleSelectedRow,
  paginationTotalRows,
  setCurrentPage,
  setLimitPerPage,
  pagination, className }: any) => {

  const customStyles = {
    headCells: {
      style: {
        border: 'none',
        marginTop: '3rem',
        fontWeight: '500',
        paddingLeft: '8px', // override the cell padding for head cells
        paddingRight: '8px',
        paddingTop: '15px',
        paddingBottom: '15px',
        // background:'#515862',
        // borderBottom: '1px solid  background-color: rgba(81, 88, 98, 0.5); /* #515862 with 50% opacity */',
        fontSize: '14px',
        fontFamily: 'Inter',
        color: '#212B36',

      },
    },
    rows: {
      style: {
        border: 'none',
        minHeight: '60px', // override the row height
        // border: '1px solid  background-color: rgba(81, 88, 98, 0.5); /* #ffffff with 50% opacity */',
      },
    },
    cells: {
      style: {
        border: 'none',
        borderBottom: 'none',
        paddingLeft: '8px', // override the cell padding for data cells
        paddingRight: '8px',
      },

    },
  };

  return (
    <><DataTable
      className={`tableFixeHeight ${className}`}
      customStyles={customStyles}
      columns={columns}
      data={data}
      pagination={false}
      striped
    // onDragStart={preventDragHandler}
    // onDrop={preventDragHandler}
    // selectableRows={selectableRows}
    // onSelectedRowsChange={handleSelectedRow}
    // selectableRowsHighlight
    // paginationServer
    // paginationTotalRows={paginationTotalRows}
    // onChangeRowsPerPage={(limit) => setLimitPerPage(limit)}
    // onChangePage={(page) => setCurrentPage(page)} 
    />

      {pagination && data?.length > 0 && <Pagination totalPages={paginationTotalRows} onCurrentPage={(page: number) => setCurrentPage(page)} />}
    </>
  )
}

export default Index