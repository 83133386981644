import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // This imports the default styles
import "./index.module.scss"
// CommonCarousel component
const CommonCarousel = ({ children,showStatus,showArrows ,showIndicators}:any) => {
    // Inline styles for active and inactive dots
    const activeDotStyle = {
        backgroundColor: '#888', // Your desired active color
    };

    const dotStyle = {
        backgroundColor: '#ccc', // Your desired non-active color
    };

    // Function to render custom indicators with inline styles
    const renderCustomIndicator = (onClickHandler: any, isSelected: any, index: number, label: string) => {
        const style = isSelected ? activeDotStyle : dotStyle;
        return (
            <li
                style={style}
                onClick={onClickHandler}
                onKeyDown={onClickHandler}
                value={index}
                key={index}
                role="button"
                tabIndex={0}
                title={`${label} ${index + 1}`}
                aria-label={`${label} ${index + 1}`}
            />
        );
    };

    return (
        <Carousel
            showStatus={showStatus} // Hides the page number
            showThumbs={false} // Hides thumbnail images if any
            showIndicators={showIndicators}
            showArrows={showArrows}
            autoPlay={false}
        >
            {children}
        </Carousel>
    );
};

export default CommonCarousel;