import { Grid, Pagination, Stack } from '@mui/material'
import React from 'react'
import { paginationOptions } from './interface'


const Index = ({ totalPages, onCurrentPage }: paginationOptions) => {
  return (
    <div className='paginationStyle'>
      <Grid container justifyContent="center" marginTop='2rem' marginBottom='2rem'>
        <Stack spacing={2}>
          <Pagination
            count={totalPages}
            showFirstButton
            variant="outlined"
            shape="rounded"
            onChange={(e: any, pageNumber: number) => onCurrentPage(pageNumber)}
            showLastButton
            sx={{
              '& .MuiPaginationItem-rounded': {
                backgroundColor: '#ffffff',
                color: '#333333',
              },
              '& .Mui-selected': {
                backgroundColor: '#6A2382',
                color: 'white',
              },
            }}
          />

        </Stack>
      </Grid>
    </div>
  )
}

export default Index