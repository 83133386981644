export const calculateAge = (dateOfBirth: string) => {
  const today = new Date()
  const birthDate = new Date(dateOfBirth)
  let age = today?.getFullYear() - birthDate?.getFullYear()
  const month = today?.getMonth() - birthDate?.getMonth()

  if (month < 0 || (month === 0 && today.getDate() < birthDate.getDate())) {
    age--
  }
  return age
}
export const getDropdownValue = (constant:any, ind:any) => {
  let temp = {
      "value": 0,
      "label": '',
  }
  constant?.map((item:any) => {
      if (item.value === ind) {
          temp.value = item.value
          temp.label = item.label
      }
  })
  return temp
}

export const getUniqueObj = (data:any, id:string, compareId:string) => {
  let tempObj
  data && data?.map((item:any) => {
      if (item[id] === compareId) {
          tempObj = item
      }
  })
  return tempObj
}

export const getDropdown = (response:any, name:string, last:string) => {
  let dropdown:any = []
  response && response?.map((value:any) => {
      dropdown.push({
          "value": value.id,
          "label": last ? value[name] + ' ' + value[last] : value[name],
      }
      )
  })
  return dropdown
}

// export const handleNumber = (e) => {
//   const inputValue = e.target.value;
//   const digitsOnly = inputValue.replace(/\D/g, '');
//   if (digitsOnly?.length <= 10) {
//       return digitsOnly
//   }
// }

export const get_url_extension = (url: any) => {
  const extension = url?.split(/[#?]/)[0]?.split('.')?.pop()?.trim();
  if (extension === 'jpg' || extension === 'png' || extension === 'jpeg') {
    return 'image';
  } else if (extension === 'pdf') {
    return 'pdf';
  } else if (extension === 'mp4' || extension === '3gp' || extension === 'mov') {
    return 'video';
  } else {
    return 'Unknown File Type';
  }
}

export const generateDocObject = (data:any, uploadType:any) => {
  return data?.reduce((acc:any, curr:any) => {
    if (curr?.uploadType === uploadType) {
      acc = curr;
    }
    return acc;
  }, {});
}




export const formatNHSNumber = (nhsNumber:any) => {
  if (!nhsNumber) return "-"; // Return empty string if the NHS number is not provided
  // Format NHS number as XXX-XXX-XXXX
  return `${nhsNumber?.slice(0, 3)} ${nhsNumber.slice(3, 6)} ${nhsNumber.slice(6)}`;
}