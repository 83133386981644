const Referral = {
    add: () => 'parent-report',
    edit: (id:string) => `parent-report/${id}`,
    view: () => 'parent-report',
    viewByID: () => 'referral/form',
    userById: () => `referral/create-caseload`,
    gpDetaillist: () => `gp-surgeons`,

}
export default Referral
