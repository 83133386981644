import auth from './auth'
import dashboard from './dashboard'
import Referral from './referral'
import Report from './report'
import UserManagement from './user-management'
import UploadDocuments from './upload-documents'
import Caseload from './caseload'
import Task from './task'
import Chat from './chat'
import Resources from './resources'

const dictionary = {
    auth,
    Referral,
    UploadDocuments,
    Report,
    dashboard,
    UserManagement,
    Caseload,
    Task,
    Resources,
    Chat
}
export default dictionary