import { Get_Logout_Response, Get_Login_Response, Get_Profile_Response, Get_logoType, VERIFY_LINK_RESPONSE, SET_BOOLEAN } from './actionType'


const initialValues = {
    linkResponse: '',
    loginResponse:'',
    loading: false,
    error: null,
    myBoolean: false
};

export const Login_Response = (state = initialValues, action: any) => {
    
    switch (action.type) {
        case Get_Login_Response:
            state = { ...state, loginResponse: action?.data };
          
            return state;
        case VERIFY_LINK_RESPONSE:
            state = { ...state, linkResponse: action?.payload };
            return state;
            case SET_BOOLEAN:
                return {
                  ...state,
                  myBoolean: action.payload
                };   
            
        default:
            return state;
    }
}
