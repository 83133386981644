import React, { useEffect, useState } from 'react'
import { MenuItem, FormControl, InputLabel, TextField } from '@mui/material';

import { useFormik } from 'formik';
import * as Yup from "yup";
import classes from './index.module.scss'
import { adminAccount, } from './interface'
import { Grid, Box } from '@mui/material'
import { addAdminAccount, getModules, getUserByID, updateUserAccount } from '../../../redux/action';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from "reselect";
import { getDropdown, getDropdownValue, getUniqueObj } from "../../../constants/utils"
import { useNavigate, useParams } from 'react-router-dom';
import constants from '../../../constants/constant'
import { useLocation } from 'react-router-dom';
import atoms from '../../../components/atoms'

const { Button, Modal, Select, Table } = atoms
const AdminAcounts = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const location = useLocation()
    const params: any = useParams()
    const pathParts = location.pathname.split('/').filter(Boolean);
    const param = pathParts[1]
  
    const [moduleOptions, setModuleOptions] = useState([])
    const [selectedModuleId, setSelectedModuleId] = useState([])
    const scopeMap: any = {
        "admin": constants.SCOPE.CLIENT_SUB_ADMIN,
        "clinician": constants.SCOPE.CLINICIAN,
        "school": constants.SCOPE.SCHOOL,
        "parent": constants.SCOPE.PARENT
    };
    const scope: any = scopeMap[param];
    let schoolInitialValues = {
        first_name: '',
        last_name: '',
        email: '',
        postCode: '',
        jobRole: '',
        school_name: '',
        address: '',
        mobile: '',
        role: constants.ROLE.ALL_ACCESS,
        scope: scope
    }
    const modulesReducer = (state: any) => state?.userManagementReducer
    const modulesData = createSelector(
        modulesReducer,
        (state) => ({
            modulesList: state.moduleData,
            detailById: state.detailById
        })
    );
    const { modulesList, detailById } = useSelector(modulesData);
    const validation = useFormik<adminAccount>({
        enableReinitialize: true,
        initialValues: params === 'school' ? schoolInitialValues : {
            first_name: '',
            last_name: '',
            email: '',
            postCode: '',
            jobRole: '',
            role: constants.ROLE.ALL_ACCESS,
            scope: scope
        },
        validationSchema: Yup.object({
            first_name: Yup.string().required("Please Enter First Name"),
            last_name: Yup.string().required("Please Enter Last Name"),
            email: Yup.string().required("Please Enter Email"),
            postCode: Yup.string().required("Please Enter Post Code"),
            jobRole: Yup.string().required("Please Enter Job Roles"),

            school_name: param === "school" ? Yup.string().required("Please Enter School Name") : Yup.string(),
            address: param === "school" ? Yup.string().required("Please Enter Address") : Yup.string(),
            mobile: param === "school" ? Yup.string().required("Please Enter Mobile") : Yup.string(),

            // modules: Yup.string().required("Please Select"),

        }),
        onSubmit: (values: adminAccount) => {
            if (param === "admin") {
                let ids: any = selectedModuleId?.map((a: any) => a.value);
                values.module_id = ids;
            }
            if (param === "school") {
                values.school_name = values.school_name;
                values.address = values.address;
                values.mobile = values.mobile;
            }

            if (params.id) {
                dispatch(updateUserAccount(params.id, values, navigate, param))
            } else {
                dispatch(addAdminAccount(values, navigate, param))
            }

        }
    });

    useEffect(() => {
        if (param === "admin") {
            dispatch(getModules({}))
        }
        if (params.id) {
            dispatch(getUserByID(params.id))
        }
        
    }, [params])

    useEffect(() => {
        let temModuleData = getDropdown(modulesList.modules, "name", '')
        setModuleOptions(temModuleData)
        if (detailById) {
            let storeModuleData: any = []
            validation.setValues({
                first_name: detailById.first_name,
                last_name: detailById.last_name,
                email: detailById.email,
                postCode: detailById.postCode || "aas",
                jobRole: detailById.jobRole || "aas",

                school_name: detailById?.school_name,
                address: detailById?.address,
                mobile: detailById?.mobile,
            })

            detailById.org_module && detailById?.org_module?.map((item: any) => {
                storeModuleData.push(getDropdownValue(temModuleData, item.module.id))
            })

            setSelectedModuleId(storeModuleData)
        }
    }, [modulesList, detailById])
    return (

        <Grid className={classes.addAdminForm}>
            <form onSubmit={validation.handleSubmit}>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6} md={4} lg={4}>
                        <Box>
                            <InputLabel className={classes.label_color} >First Name</InputLabel>
                            <TextField
                                fullWidth
                                placeholder="Enter First Name"
                                {...validation.getFieldProps('first_name')}
                                error={validation.touched.first_name && Boolean(validation.errors.first_name)}
                                helperText={validation.touched.first_name && validation.errors.first_name}
                            />
                        </Box>

                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={4}>
                        <Box>
                            <InputLabel className={classes.label_color} >Last Name</InputLabel>
                            <TextField
                                fullWidth
                                placeholder="Enter Last Name"
                                {...validation.getFieldProps('last_name')}
                                error={validation.touched.last_name && Boolean(validation.errors.last_name)}
                                helperText={validation.touched.last_name && validation.errors.last_name}
                            />
                        </Box>

                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={4}>
                        <Box>
                            <InputLabel className={classes.label_color} >Email</InputLabel>
                            <TextField
                                fullWidth
                                type={"email"}
                                placeholder="Enter Email"
                                {...validation.getFieldProps('email')}
                                error={validation.touched.email && Boolean(validation.errors.email)}
                                helperText={validation.touched.email && validation.errors.email}
                            />
                        </Box>

                    </Grid>
                </Grid>

                {param === "school" && <Grid container spacing={3} className={classes.mt_3}>
                    <Grid item xs={12} sm={6} md={4} lg={4}>
                        <Box>
                            <InputLabel className={classes.label_color} >School Name</InputLabel>
                            <TextField
                                fullWidth
                                placeholder="Enter School Name"
                                {...validation.getFieldProps('school_name')}
                                error={validation.touched.school_name && Boolean(validation.errors.school_name)}
                                helperText={validation.touched.school_name && validation.errors.school_name}
                            />
                        </Box>

                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={4}>
                        <Box>
                            <InputLabel className={classes.label_color} >School Address</InputLabel>
                            <TextField
                                fullWidth
                                placeholder="Enter Address"
                                {...validation.getFieldProps('address')}
                                error={validation.touched.address && Boolean(validation.errors.address)}
                                helperText={validation.touched.address && validation.errors.address}
                            />
                        </Box>

                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={4}>
                        <Box>
                            <InputLabel className={classes.label_color} >Mobile</InputLabel>
                            <TextField
                                fullWidth
                                type={"number"}
                                placeholder="Enter mobile"
                                {...validation.getFieldProps('mobile')}
                                error={validation.touched.mobile && Boolean(validation.errors.mobile)}
                                helperText={validation.touched.mobile && validation.errors.mobile}
                            />
                        </Box>

                    </Grid>
                </Grid>}

                <Grid container spacing={3} className={classes.mt_3}>
                    <Grid item xs={12} sm={6} md={4} lg={4}>
                        <Box>
                            <InputLabel className={classes.label_color} >Post Code</InputLabel>
                            <TextField
                                fullWidth
                                type="text"
                                placeholder="Enter Post Code"
                                {...validation.getFieldProps('postCode')}
                                error={validation.touched.postCode && Boolean(validation.errors.postCode)}
                                helperText={validation.touched.postCode && validation.errors.postCode}
                            />
                        </Box>

                    </Grid>
                    {param === "admin" &&
                        <Grid item xs={12} sm={6} md={4} lg={4}>
                            <Box>
                                <InputLabel className={classes.label_color} >Modules</InputLabel>
                                <Select options={moduleOptions} value={selectedModuleId}
                                    placeholder={"Select Modules"}
                                    isMulti={true}
                                    onChange={(e: any) => setSelectedModuleId(e)}
                                />
                            </Box>
                        </Grid>
                    }
                    <Grid item xs={12} sm={6} md={4} lg={4}>
                        <Box>
                            <InputLabel className={classes.label_color} >Job Roles</InputLabel>
                            <TextField
                                fullWidth
                                type="text"
                                placeholder="Enter Job Roles"
                                {...validation.getFieldProps('jobRole')}
                                error={validation.touched.jobRole && Boolean(validation.errors.jobRole)}
                                helperText={validation.touched.jobRole && validation.errors.jobRole}
                            />
                        </Box>
                    </Grid>
                </Grid>

                <Grid container spacing={3} className={`${classes.mt_3} ${classes.btnClr}`}>
                    <Grid item xs={12} sm={3} md={3} lg={6} sx={{ display: 'flex', justifyContent: 'end' }}>
                        <Box className={classes.cancelBtn}>
                            <Button fullWidth={false}
                                variant={"outlined"} >
                                Cancel
                            </Button>
                        </Box>

                    </Grid>

                    <Grid item xs={12} sm={3} md={3} lg={6} >
                        <Box>
                            <Button type={"submit"} fullWidth={false}
                                // sx={{width:}}
                                variant={"contained"}>
                                {params.id ? "Update" : " Submit"}
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
            </form>
        </Grid>

    )
}

export default AdminAcounts