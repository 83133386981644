import { useTheme, useMediaQuery } from '@mui/material';

// Custom hook to determine screen sizes
export const useResponsive = () => {
  const theme = useTheme();

  // Define media query matches for each breakpoint
  const isExSmallScreen = useMediaQuery(theme.breakpoints.up('xs'));
  const isSmallScreen = useMediaQuery(theme.breakpoints.up('sm'));
  const isMediumScreen = useMediaQuery(theme.breakpoints.up('md'));
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('lg'));
  const isExLargeScreen = useMediaQuery(theme.breakpoints.up('xl'));

  return {
    isExSmallScreen,
    isSmallScreen,
    isMediumScreen,
    isLargeScreen,
    isExLargeScreen,
  };
};

// xs, extra-small: 0px
// sm, small: 600px
// md, medium: 900px
// lg, large: 1200px
// xl, extra-large: 1536px