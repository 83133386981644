export const style: any = {
  error_text: {
    fontFamily: 'poppins',
    fontWeight: '400',
    fontSize: '0.75rem',
    lineHeight: '1.66',
    letterSpacing: '0.03333em',
    textAlign: 'left',
    marginTop: '3px',
    marginRight: '14px',
    marginBottom: '0',
    marginLeft: '14px',
    color: '#d32f2f',
  },
  label_color: {
    color: '#212B36',
    fontFamily: 'poppins',
    marginBottom: '0.5rem',
  },
}
