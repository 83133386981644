import React, { useEffect, useRef } from 'react'
import {
    Box,
    InputLabel,
    TextField, Typography
} from '@mui/material';
import { style } from './style'
const Index = ({ fullWidth, value, disabled, type, onChange, placeholder, variant, multiline,     autoFocus, rows, inputProps, handle, ref, error, label, prop }: any) => {
    const handleKeyPress = (event: any) => {
        // Regular expression to match arithmetic characters
        const arithmeticOps = /[+\-*/]/;
        if (arithmeticOps.test(event.key)) {
            event.preventDefault();
        }
    };

    const handleKeyDown = (event: any) => {
        if (event.key === 'ArrowUp' || event.key === 'ArrowDown') {
            event.preventDefault();
        }
    };

    const handleChange = (event: any) => {
        // Here you can add additional logic before passing the event to the onChange prop
        onChange && onChange(event);
    };

    const handleWheel = (event: any) => {
        if (type === 'number' && event.type === 'wheel') {
            event.preventDefault();
        }
    };

    return (
        <Box>
            {label && <InputLabel sx={style.label_color} >{label}</InputLabel>}
            <TextField
                {...prop}
                fullWidth={fullWidth}
                value={value}
                disabled={disabled}
                type={type}
                ref={ref}
                autoFocus={autoFocus}
                onKeyPress={handleKeyPress}
                onKeyDown={handleKeyDown}
                // onWheel={handleWheel}
                onFocus={(e) => type === 'number' && e.target.addEventListener("wheel", function (e) { e.preventDefault() }, { passive: false })}
                onChange={handleChange} // Use the custom handleChange
                placeholder={placeholder}
                variant={variant}
                multiline={multiline}
                rows={rows}
                inputProps={inputProps}
            />
            {error && <Typography sx={style.error_text}>{error}</Typography>}
        </Box>
    )
}

export default Index