import { BASE_URL } from '../../constants/defaultValues'
import Dictionary from '../dictionary'
import axios from 'axios'

const { UploadDocuments } = Dictionary

const Upload_Documents = {
  add: async (data: {}) => {
    return axios.post(BASE_URL + UploadDocuments.add(), data)
  },
  getById: async (id: string) => {
    return axios.get(BASE_URL + UploadDocuments.getById(id))
  },
}
export default Upload_Documents
