import React from 'react';
import { Card, CardContent, Typography, TextField, Button, Grid } from '@mui/material';
import { Formik, Form, Field } from 'formik';
import "./style.scss"
import { ForgotPasswordFormValues } from '../interface'
import Logo from "../../../assets/Images/logo.svg"
import { forgotPassword } from "../../../redux/action"
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
const ForgotPassword = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const handleSubmit = async (values: ForgotPasswordFormValues) => {
        dispatch(forgotPassword(values, navigate))

    };

    return (
        <Grid container className='forBg' >
            <Grid item lg={3}></Grid>
            <Grid item lg={6}>
                <Card className='formCard'>
                    <CardContent>
                        <Grid textAlign={"center"} marginTop={'25px'} marginBottom={'10px'}>
                            <img src={Logo} width="300px" height={"auto"} />
                        </Grid>
                        <Typography variant="h5" gutterBottom>
                            Forgot Password
                        </Typography>
                        <Formik
                            initialValues={{ email: '' }}
                            onSubmit={handleSubmit}
                        >
                            <Form>
                                <Field
                                    as={TextField}
                                    label="Email"
                                    name="email"
                                    variant="outlined"
                                    fullWidth
                                    required
                                />
                                <Grid textAlign={"center"} marginTop={'10px'}>
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        color="primary"

                                    >
                                        Reset Password
                                    </Button>
                                </Grid>
                            </Form>
                        </Formik>
                    </CardContent>
                </Card>
            </Grid>
            <Grid item lg={3}></Grid>
        </Grid>
    );
}

export default ForgotPassword;
