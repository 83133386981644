import React from 'react';
import { Container, Typography, Paper, Box, Grid, Card } from '@mui/material';
import classes from '../index.module.scss'
const ConsentFormView = ({ question, type, tab }: any) => {

    return (
        <Grid className={classes.Referral} >
            <Card className={classes.card}>
                {question && question.map((item: any, index: number) => {
                    return (
                        <Grid sx={{ ml: '15px' }}>
                            {index === 0 && <Typography sx={{ fontWeight: 600 }} variant="h6" gutterBottom >
                                {tab + 1}. {type}
                            </Typography>}
                            <Box my={2} >
                                <Grid display={'flex'} sx={{ gap: '5px' }} className={classes.viewOutput}>
                                    <Typography sx={{ fontWeight: 600}} >
                                    {item.question_number}
                                    </Typography>
                                    <Typography sx={{ fontWeight: 600 }} >

                                        {item.question}
                                    </Typography>
                                </Grid>
                                <Grid display={'flex'} sx={{ gap: '5px', mt: '5px' }} className={classes.viewOutput}>
                                    <Typography sx={{ fontWeight: 600 }} >
                                        Ans.
                                    </Typography>
                                    <Typography variant="body1">
                                        {item.question_id === 'ques22' ? item?.answer?.label : item.answer}
                                    </Typography>
                                </Grid>

                            </Box>
                        </Grid>
                    )
                })}

            </Card>
        </Grid>
    );
};

export default ConsentFormView;
