import { useState, useEffect } from "react";

export const useOrgnizationLogo = () => {
  const host = window?.location?.hostname;
  let loginDetailString = localStorage.getItem("loginResponse");
  let loginDetail = loginDetailString ? JSON.parse(loginDetailString) : {};
  const orgLogo = loginDetail?.organization?.logo;
  const [imageSrc, setImageSrc] = useState<any>();

  useEffect(() => {
    const loadImage = async () => {
      try {
        const response = await fetch(orgLogo);
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const blob = await response.blob();
        const reader = new FileReader();
        reader.onloadend = () => {
          const base64data = reader.result as string;
          localStorage.setItem("cachedImage", base64data);
          setImageSrc(base64data);
        };
        reader.readAsDataURL(blob);
      } catch (error) {
        console.error(
          "There has been a problem with your fetch operation:",
          error
        );
        const cachedImage = localStorage.getItem("cachedImage");
        if (cachedImage) {
          setImageSrc(cachedImage);
        } else {
          setImageSrc('');
        }
      }
    };

    if (host !== "localhost") {
      loadImage();
    } else {
      setImageSrc(orgLogo);
    }
  }, [orgLogo, host]);

  return {
    imageSrc,
    orgLogo,
    loginDetail,
    host,
  };
};
