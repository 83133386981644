import React from 'react'
import privacy_logo from '../../../assets/Images/privacy_logo.png'
function index() {
    return (
        <div style={{ backgroundColor: 'rgb(245, 250, 255)' }}>
            <body style={{ fontFamily: 'Arial, sans-serif', lineHeight: '1.6', margin: '0', padding: '0', color: "#333" }}>
                <div style={{ maxWidth: '1000px', margin: '0 auto', padding: '20px' }}>
                    <div>
                        <img src={privacy_logo} />
                    </div>
                    <h1 style={{ fontSize: '20px', color: '#000000' }}>Provide Digital Limited: Privacy Notice for the My Care Bridge (MCB) Users</h1>
                    <p><strong>Effective Date:</strong> August 2024</p>
                    <p>Provide Digital Limited (“<span style={{ color: '#000000', fontWeight: 'bold' }}>Provide Digital</span>”, “<span style={{ color: '#000000', fontWeight: 'bold' }}>We</span>”,”<span style={{ color: '#000000', fontWeight: 'bold' }}>Us</span>”) as the developer and owner of My Care Bridge (MCB) System digital platform, including the web portal (the main domain of <a href="https://pd-admin.mycarebridge.co.uk/" style={{ color: '#0000ff' }}>https://pd-admin.mycarebridge.co.uk/</a> and its sub-domains) (collectively called “<span style={{ color: '#000000', fontWeight: 'bold' }}>MCB Application</span>”), understands that your privacy is important to you and that you care about how your information is used and shared. We respect and value the privacy of everyone who uses MCB and will only collect and use information in ways that are useful to you and in a manner consistent with your rights and our obligations under Data Protection law.</p>

                    <p>Provide Digital plays a dual role when it comes to processing. In some situations, we act as a Controller, in others, we are the Processor.</p>

                    <p>When we are a Processor of personal data, we are doing so purely on the instruction of another company (the Controller). This will usually be the data of the <span style={{ color: '#000000', fontWeight: 'bold' }}> Service Users, Client Contacts </span>and <span style={{ color: '#000000', fontWeight: 'bold' }}>Client Admin Users</span> of Client Organisations.</p>

                    <p>Provide Digital, does on occasion, act as a Controller. That will usually be the contact data of the company and/or its representatives where MCB Application has been deployed and then technical data when you use our app.</p>

                    <p>Being a Controller means that we are trusted to look after and deal with your personal information in accordance with this notice. We determine the ways and means of processing your data and must therefore be accountable for it.</p>

                    <p>This Privacy Notice refers to the data we process data as a Controller only and describes what <span style={{ color: '#000000', fontWeight: 'bold' }}>personal information</span> we collect and hold about you, how we <span style={{ color: '#000000', fontWeight: 'bold' }}>use</span> it, who can <span style={{ color: '#000000', fontWeight: 'bold' }}>access</span> it, and <span style={{ color: '#000000', fontWeight: 'bold' }}>your rights </span> with regards to how we process your information.</p>

                    <p>The General Data Protection Regulation (GDPR) also gives you the right to lodge a complaint with a supervisory authority, in a country you work, normally live or if any alleged infringement of data protection laws occurred in the relevant state. The supervisory authority in the UK is the Information Commissioner who may be contacted at <a href="https://ico.org.uk/concerns/" style={{ color: "#0000ff" }}>https://ico.org.uk/concerns/</a> or telephone: 0303 123 1113.</p>

                    <h2 style={{ fontSize: "18px", color: "#000000" }}>Who we are</h2>
                    <p>We are Provide Digital Limited, a company incorporated in England and Wales with registered number 12377709 at Companies House and whose registered office is at 900 The Crescent, Colchester Business Park, Colchester, CO4 9YQ, United Kingdom.</p>

                    <p>If you have any questions about this Privacy Notice, please contact us by post (Postal Address: 900 The Crescent, Colchester Business Park, Colchester, CO4 9YQ, United Kingdom), or email (<a href="mailto:provide.infogov@nhs.net" style={{ color: "#0000ff" }}>provide.infogov@nhs.net</a>). Please ensure your query is clear, particularly if it is a request for information about the personal data we hold about you. We hope that we can resolve any query or concern you raise about our use of your information.</p>

                    <p>Provide Digital is registered with the Information Commissioner’s Office (ICO) to process personal and special categories of information (Registration number: ZB407604).</p>

                    <h2 style={{ fontSize: "18px", color: "#000000" }}>Why we keep personal information about you</h2>
                    <p>We may use the personal information we have collected about you for the following purpose/s:</p>
                    <ul>
                        <li style={{ marginBottom: "10px" }}>To understand what you want to build, what features your product should have, and what purpose it will serve.</li>
                        <li style={{ marginBottom: "10px" }}>To establish a contract and provide you access to the application.</li>
                        <li style={{ marginBottom: "10px" }}>To optimise the performance of our app, monitor it for security purposes and drive improvements for our users.</li>
                        <li style={{ marginBottom: "10px" }}>To provide operation and/or technical support for the tickets raised.</li>
                        <li style={{ marginBottom: "10px" }}>To send you service updates.</li>
                    </ul>

                    <p>Generally, we do not rely on consent as a legal basis for processing your personal data although we will get your consent before sending third party direct marketing communications to you via email or text message. You have the right to withdraw consent to marketing at any time by contacting us.</p>

                    <p>We will only use your personal data for the purposes for which we collected it, unless we reasonably consider that we need to use it for another reason and that reason is compatible with the original purpose. If you wish to get an explanation as to how the processing for the new purpose is compatible with the original purpose, please contact us. If we need to use your personal data for an unrelated purpose, we will notify you and we will explain the legal basis which allows us to do so.</p>

                    <h2 style={{ fontSize: "18px", color: "#000000" }}>What Information do we keep about you?</h2>
                    <p>We may collect and process the following types of personal information:</p>
                    <ul>
                        <li style={{ marginBottom: "10px" }}>Name of the company or representative/s</li>
                        <li style={{ marginBottom: "10px" }}>Email Address of the company or representative/s</li>
                        <li style={{ marginBottom: "10px" }}>Phone Number of the company or representative/s</li>
                        <li style={{ marginBottom: "10px" }}>Address of the company</li>
                    </ul>

                    <p>We may collect and process non-personal data:</p>
                    <ul>
                        <li style={{ marginBottom: "10px" }}>Logo and other branding material of the company</li>
                    </ul>

                    <p>Apart from the above data, we also process data about the way you use our app, including your IP address and browsing time. At Provide Digital, we believe in being transparent about how we process data about you and how we use any third party to process the data.</p>

                    <h2 style={{ fontSize: "18px", color: "#000000" }}>How we collect Information about you</h2>
                    <p>We may collect personal information about you in a number of ways:</p>
                    <ul>
                        <li style={{ marginBottom: "10px" }}>When you make use of our contact form or demo request form to request information from us.</li>
                        <li style={{ marginBottom: "10px" }}>When you provide the data to enter into a contract with us.</li>
                        <li style={{ marginBottom: "10px" }}>If you sign up on one of our MCB Application that we provide and support.</li>
                        <li style={{ marginBottom: "10px" }}>When you contact us by phone.</li>
                    </ul>

                    <h2 style={{ fontSize: "18px", color: "#000000" }}>Our Lawful basis for processing your data</h2>
                    <p>We may rely on one or more of the following lawful bases for processing your personal information:</p>
                    <ul>
                        <li style={{ marginBottom: "10px" }}>Your consent. You are able to remove your consent at any time. You can do this by contacting us through email at <a href="mailto:provide.infogov@nhs.net" style={{ color: "#0000ff" }}>provide.infogov@nhs.net</a></li>
                        <li style={{ marginBottom: "10px" }}>We have a contractual obligation and the processing is necessary for the performance of Services we provide pursuant to a contract we have entered into with you or your representative.</li>
                        <li style={{ marginBottom: "10px" }}>We have a legal obligation.</li>
                        <li style={{ marginBottom: "10px" }}>We have a legitimate interest assessment.</li>
                    </ul>

                    <p>The specific lawful bases will depend on the engagement or services you have with us.</p>

                    <h2 style={{ fontSize: "18px", color: "#000000" }}>How and where we hold your personal information</h2>
                    <p>We create and hold your records electronically and sometimes in hard copy where necessary.</p>

                    <p>Any records we hold about you are held securely and are only accessible to those who have a legitimate need to access the information.</p>

                    <p>Your information will be kept securely and will remain in the UK. We generally store the information provided via MCB Application in Amazon Web Services (AWS) Cloud datacentres located in the UK where data is encrypted to a high standard (AES256). We also use certain third party platforms to share and store information (if required). Please refer to section – ‘<span style={{ fontSize: "18px", color: "#000000", fontWeight: 'bold' }}> Do we share your Information?</span>’ for further details.</p>

                    <p>We do not typically transfer your personal data outside the UK. However, should we need to transfer your data outside the UK, we will ensure that the appropriate legal safeguard is in place to allow the transfer.</p>

                    <p>All of our staff and contractors receive appropriate and on-going training to ensure they are aware of their personal responsibilities and have contractual obligations to uphold confidentiality, enforceable through disciplinary procedures if necessary. Staff only have access to personal information where it is appropriate to their role and is strictly on a need-to-know basis.</p>

                    <h2 style={{ fontSize: "18px", color: "#000000" }}>How we use your Information</h2>
                    <p>We ensure that we process your data lawfully, fairly and transparently in accordance with Data Protection legislation.</p>

                    <p>Personal information collected about you may be used for the following purpose/s:</p>
                    <ul>
                        <li style={{ marginBottom: "10px" }}>Performance of a contract with you.</li>
                        <li style={{ marginBottom: "10px" }}>Responding to communications from you.</li>
                        <li style={{ marginBottom: "10px" }}>Supplying you with any information or newsletter you have subscribed to.</li>
                        <li style={{ marginBottom: "10px" }}>Providing support for our product/s.</li>
                    </ul>

                    <p>If we need to use your personal information for any reasons beyond those stated above, we will discuss this with you and ask for your permission to do so.</p>

                    <h2 style={{ fontSize: "18px", color: "#000000" }}>Do we share your Information?</h2>
                    <p>We may share your personal information with other companies within our group. This includes our holding company, Provide CIC and its subsidiaries.</p>

                    <p>These may include payment processing, delivery of goods, search engine facilities, cloud storage, advertising and marketing. In some cases, the third parties may require access to some or all of your data. Where any of your data is required for such a purpose, we will take all reasonable steps to ensure that your data will be handled safely, securely, and in accordance with your rights, our obligations, and the obligations of the third party under the law.</p>

                    <p>We use third party services for the purposes set out in the table below. A list of our external service providers can be found below:</p>

                    <table style={{ width: "100%", borderCollapse: "collapse", marginBottom: "20px" }}>
                        <thead>
                            <tr>
                                <th style={{ border: "1px solid #ddd", padding: "8px", textAlign: "left", backgroundColor: "#f4f4f4" }}>Service provider</th>
                                <th style={{ border: "1px solid #ddd", padding: "8px", textAlign: "left", backgroundColor: "#f4f4f4" }}>Service</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td style={{ border: "1px solid #ddd", padding: "8px" }}>Google Drive</td>
                                <td style={{ border: "1px solid #ddd", padding: "8px" }}>Document repository</td>
                            </tr>
                            <tr>
                                <td style={{ border: "1px solid #ddd", padding: "8px" }}>Microsoft Email</td>
                                <td style={{ border: "1px solid #ddd", padding: "8px" }}>Client email service</td>
                            </tr>
                            <tr>
                                <td style={{ border: "1px solid #ddd", padding: "8px" }}>Microsoft OneDrive</td>
                                <td style={{ border: "1px solid #ddd", padding: "8px" }}>Document repository</td>
                            </tr>
                            <tr>
                                <td style={{ border: "1px solid #ddd", padding: "8px" }}>SendGrid</td>
                                <td style={{ border: "1px solid #ddd", padding: "8px" }}>Email and Customer service</td>
                            </tr>
                            <tr>
                                <td style={{ border: "1px solid #ddd", padding: "8px" }}>Codezilla</td>
                                <td style={{ border: "1px solid #ddd", padding: "8px" }}>Software development</td>
                            </tr>
                            <tr>
                                <td style={{ border: "1px solid #ddd", padding: "8px" }}>Amazon Web Services (AWS)</td>
                                <td style={{ border: "1px solid #ddd", padding: "8px" }}>Cloud Infrastructure service</td>
                            </tr>
                        </tbody>
                    </table>

                    <p>In certain circumstances, we may be legally required to share certain data held by us, which may include your personal information, for example, where we are involved in legal proceedings, where we are complying with the requirements of legislation, a court order, or a governmental authority. We do not require any further consent from you in order to share your data in such circumstances and will comply as required with any legally binding request that is made of us.</p>

                    <h2 style={{ fontSize: "18px", color: "#000000" }}>How long do we keep your Information?</h2>
                    <p>We will only retain your personal data for as long as reasonably necessary to fulfil the purposes we collected it for, including for the purposes of satisfying any legal, regulatory, tax, accounting, or reporting requirements. Usually that means we will keep your personal data for as long as you have a registered account with Provide Digital. We may need to retain your personal data for a longer period in the event of a complaint, if we reasonably believe there is a prospect of litigation in respect to our relationship with you or where we have to keep data for regulatory purposes.</p>

                    <p>To determine the appropriate retention period for personal data, we consider the amount, nature and sensitivity of the personal data, the potential risk of harm from unauthorised use or disclosure of your personal data, the purposes for which we process your personal data and whether we can achieve those purposes through other means, and the applicable legal, regulatory, tax, accounting or other requirements.</p>

                    <p>You can request to delete all your personal data (where applicable) by contacting us via email at <a href="mailto:provide.infogov@nhs.net" style={{ color: "#0000ff" }}>provide.infogov@nhs.net</a>, and the appropriate personal data will be erased, deleted, destroyed, or otherwise disposed of.</p>

                    <h2 style={{ fontSize: "18px", color: "#000000" }}>Your Rights</h2>
                    <p>You have the following rights, under the Data Protection legislation, in respect of your personal information that we hold about you:</p>
                    <ul>
                        <li style={{ marginBottom: "10px" }}><span style={{ fontWeight: 'bold', color: '#000000' }}>Right to be Informed</span> - to be informed about the collection and use of your personal data.</li>
                        <li style={{ marginBottom: "10px" }}><span style={{ fontWeight: 'bold', color: '#000000' }}>Right of access</span> - To ask for copies of your personal information.</li>
                        <li style={{ marginBottom: "10px" }}><span style={{ fontWeight: 'bold', color: '#000000' }}>Right to rectification</span> - To ask for your personal information to be corrected if it is inaccurate or incomplete.</li>
                        <li style={{ marginBottom: "10px" }}><span style={{ fontWeight: 'bold', color: '#000000' }}>Right to erasure</span> - To ask for your personal information to be deleted or erased, where applicable.</li>
                        <li style={{ marginBottom: "10px" }}><span style={{ fontWeight: 'bold', color: '#000000' }}>Right to restriction of processing</span> - To ask us to restrict the use of your personal information in some circumstances.</li>
                        <li style={{ marginBottom: "10px" }}><span style={{ fontWeight: 'bold', color: '#000000' }}>Right to object to processing</span> - To object to the processing of your personal information in certain circumstances. We may use automated decision making in processing your personal information for some services. You may object to your information being subjected to automated processing and you can request a manual review of the outcome of an automated decision if you are unhappy with it.</li>
                        <li style={{ marginBottom: "10px" }}><span style={{ fontWeight: 'bold', color: '#000000' }}>Right to data portability</span> - To request your personal information to be transferred to other providers on certain occasions.</li>
                        <li style={{ marginBottom: "10px" }}><span style={{ fontWeight: 'bold', color: '#000000' }}>Rights in Relation to Automated Decision Making and Profiling</span> - not to be subject to a decision based solely on automated processing, including profiling, which produces legal effects or similarly significantly affects you.</li>
                    </ul>

                    <p>We do not typically charge a fee for exercising your rights except in certain circumstances where permitted by Data Protection law. We have one month to respond to your request and may extend by another two months in certain circumstances.</p>

                    <p>If you wish to exercise any of your rights please contact us on <a href="mailto:provide.infogov@nhs.net" style={{ color: "#0000ff" }}>provide.infogov@nhs.net</a> or through our data subject request online form/portal by clicking <a href="https://ams-sar.com" style={{ color: "#0000ff" }}>Home Page - Portal (ams-sar.com)</a> or by writing to our <span style={{ fontWeight: 'bold', color: '#000000' }}>Data Protection Officer at</span> <a href="mailto:provide.infogov@nhs.net" style={{ color: "#0000ff" }}>provide.infogov@nhs.net</a>.</p>

                    <p>Should you have a query or wish to lodge a complaint about the use of your personal information, please contact our Information Governance Team in the first instance at <a href="mailto:provide.infogov@nhs.net" style={{ color: "#0000ff" }}>provide.infogov@nhs.net</a> or write to:</p>
                    <p>Information Governance, Provide HQ, 900 The Crescent, Colchester Business Park, Colchester, Essex, CO4 9YQ.</p>

                    <p>If you are still unhappy with the outcome of your enquiry concerning how we process your personal information you can write to:</p>
                    <p>The Information Commissioner, Wycliffe House, Water Lane, Wilmslow, Cheshire SK9 5AF.</p>

                    <p>Telephone: 0303 123 1113.</p>

                    <p>ICO Website: <a href="https://www.ico.org.uk" style={{ color: "#0000ff" }}>https://www.ico.org.uk</a></p>
                </div>
            </body>
        </div>
    )
}

export default index
