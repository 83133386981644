import { ADMIN_ACCOUNT_RESPONSE, ADD_ADMIN_ACCOUNT, MODULES_RESPONSE, CLINICIAN_RESPONSE, USER_RESPONSE, USER_DETAIL_RESPONSE } from './actionType'

let initialValues = {
    moduleData: [],
    clinicianData: [],
    userData: [],
    detailById: '',
}
export const userManagementReducer = (state = initialValues, action: any) => {

    switch (action.type) {
        case ADMIN_ACCOUNT_RESPONSE:
            break
        case ADD_ADMIN_ACCOUNT:
            break
        case MODULES_RESPONSE:
            state = { ...state, moduleData: action?.payload?.data };
            break;
        case CLINICIAN_RESPONSE:
            state = { ...state, clinicianData: action?.payload?.data };
            break;
        case USER_RESPONSE:
            state = { ...state, userData: action?.payload?.data };
            break;
        case USER_DETAIL_RESPONSE:
            state = { ...state, detailById: action?.payload?.data };
            break;
        default:
            state = { ...state };
            break;
    }
    return state;
}
